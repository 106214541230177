import { methods, serviceMaker } from '../service';

const GET_NOTIFICATION = (
  limit,
  skip,
  search = null,
  sort = null,
  scheduled = null,
  published = null,
  startDate = null,
  endDate = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (scheduled) {
    query['params']['isPublished'] = false;
  }
  if (published) {
    query['params']['isPublished'] = true;
  }
  if (startDate) {
    query['params']['startDate'] = startDate;
  }
  if (endDate) {
    query['params']['endDate'] = endDate;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`notifications`, methods.GET, query);
};

const ADD_NOTIFICATION = (payload) =>
  serviceMaker(`notifications`, methods.POST, null, payload);

const DELETE_NOTIFICATION = (id) =>
  serviceMaker(`notifications/${id}`, methods.DELETE);

const GET_NOTIFICATION_BY_ID = (id) =>
  serviceMaker(`notifications/${id}`, methods.GET);

const EDIT_NOTIFICATION = (id, payload) =>
  serviceMaker(`notifications/${id}`, methods.PATCH, null, payload);

export const NOTIFICATION = {
  GET_NOTIFICATION,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  GET_NOTIFICATION_BY_ID,
  EDIT_NOTIFICATION,
};
