import { useEffect, useState } from 'react';

import { Table, Input, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  FilterFilled,
} from '@ant-design/icons';

import DeleteModel from '../../components/DeleteModel';
import PaginationCom from '../../components/PaginationCom';
import InsideHeader from '../../components/InsideHeader/InsideHeader';
import TableImage from '../../components/Image/TableImage';
import ButtonCom from '../../components/Button/ButtonCom';
import SelectDropDown from '../../components/Select';
import { ARTISTS, ARTIST_ROLE } from '../../service/index';
import { tableSortIconToggle } from '../../utility/tableSorting';
import InfoDrawer from './InfoDrawer';

const Artists = ({ permissions }) => {
  const [artistsData, setArtistsData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);

  const [roles, setRoles] = useState({});

  const [infoSliderVisible, setInfoSliderVisible] = useState(false);
  const [infoSliderContent, setInfoSliderContent] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [filterCardShow, setFilterCardShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [filterValue, setFilterValue] = useState(null);

  const [tableDataLength, setTableDataLength] = useState(0);

  const navigate = useNavigate();

  const fetchArtists = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    roleName = null
  ) => {
    setTableLoading(true);
    try {
      const res = await ARTISTS.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        roleName
      );
      if (res.status === 1 || res.status === '1') {
        setArtistsData(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || 'Something went wrong!');
    } finally {
      setTableLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const res = await ARTIST_ROLE.GET();
      if (res.status === 1 || res.status === '1') {
        const option = [{ label: 'All', value: 'all' }];
        res?.data?.forEach((item) => {
          option.push({
            label: item?.name,
            value: item?.id,
          });
        });
        setRoles(option);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const deleteArtists = async () => {
    try {
      const res = await ARTISTS.DELETE(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (artistsData?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchArtists(page, pageSize, searchValue, sort, filterValue?.value);
        }
        toast.success(res?.message);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const handleChangeOfRoles = (e) => {
    if (e) {
      setCurrentPage(1);
      setFilterValue(e);
      if (e?.value === 'all') {
        e = null;
        setFilterValue(null);
      }
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  useEffect(() => {
    fetchArtists(currentPage, pageSize, searchValue, sort, filterValue?.value);
  }, [currentPage, filterValue?.value, pageSize, searchValue, sort]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const columns = [
    {
      title: 'Cover Image',
      dataIndex: 'media_item',
      key: 'media_item',
      align: 'left',
      width: 130,
      render: (media_item) => <TableImage image={media_item?.image} />,
    },
    {
      title: (column) => tableSortIconToggle('Artists Name', 'name', column),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: tableDataLength > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: 'Artists Roles',
      dataIndex: 'artist_added_roles',
      key: 'artist_added_roles',
      align: 'center',
      ellipsis: true,
      render: (value) => {
        const roles = value
          .map((role) => role.artist_role?.name)
          .filter((role) => role);
        return roles.join(', ') || '-';
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      dataIndex: 'Action',
      width: 200,
      render: (item, record) => {
        return (
          <>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Artist'>
                <EditOutlined
                  onClick={() => navigate(`/artists/edit/${record?.id}`)}
                  className='font-size-20 cursor-pointer edit-btn-hover mx-1'
                />
              </Tooltip>
            )}
            <Tooltip title='View Artist'>
              <InfoCircleOutlined
                onClick={() => {
                  setInfoSliderContent(record);
                  setInfoSliderVisible(true);
                }}
                className='font-size-20 info-btn-hover cursor-pointer mx-1'
              />
            </Tooltip>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Artist'>
                <DeleteOutlined
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <InsideHeader className='search-section main-search'>
        <Input
          placeholder='Start Typing Search Artist'
          suffix={
            <Tooltip title='Search Now'>
              <SearchOutlined
                style={{ color: '#8f9bb3' }}
                onClick={() => setSearchValue(tempSearch)}
              />
            </Tooltip>
          }
          className='input-control'
          type='search'
          onChange={(e) => {
            if (!e?.target?.value) {
              setSearchValue(null);
              setTempSearch(null);
            } else {
              setTempSearch(e?.target?.value);
            }
          }}
          onKeyPress={(e) => {
            if (e?.key === 'Enter' && e.target.value) {
              setCurrentPage(1);
              setSearchValue(tempSearch);
            }
          }}
          allowClear
        />
        <div className='search-section__btn-group btnCount-3'>
          {(permissions?.isCreate || permissions?.isCreate === undefined) && (
            <ButtonCom
              type='primary'
              className='mr-1 width-180'
              onClick={() => navigate('/artists/add')}
            >
              Create New Artist
            </ButtonCom>
          )}
          <ButtonCom
            type='primary'
            className='mr-1 width-180'
            onClick={() => navigate('/artists/role')}
          >
            Artists Roles
          </ButtonCom>
          <ButtonCom
            type='primary'
            onClick={() => setFilterCardShow(!filterCardShow)}
          >
            <div className='d-flex align-items-center'>
              Artists Filter
              <FilterFilled className='ml-1' />
            </div>
          </ButtonCom>
        </div>
      </InsideHeader>

      {filterCardShow && (
        <InsideHeader className='mt-3'>
          <SelectDropDown
            placeholder='Select Roles'
            options={roles}
            onChange={(e) => handleChangeOfRoles(e)}
            value={filterValue || { label: 'All', value: 'all' }}
            className='width-250'
          />
        </InsideHeader>
      )}

      <div className='fix-table mt-3 border-radius-5 box-shadow-3'>
        <Table
          columns={columns}
          dataSource={artistsData?.data}
          pagination={false}
          rowKey='id'
          loading={tableLoading}
          onChange={onChange}
        />

        <div className='bg-white p-2 width-1120 d-flex justify-content-between align-items-center'>
          <p className='mb-0'>{artistsData?.total || 0} - Total Artists</p>
          {artistsData?.total > 10 && (
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={artistsData?.total}
            />
          )}
        </div>
      </div>

      <InfoDrawer
        setVisible={setInfoSliderVisible}
        visible={infoSliderVisible}
        infoDeatils={infoSliderContent}
      />

      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteArtists}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </>
  );
};

export default Artists;
