import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';

import DashboardIcon from '../Icon/DashboardIcon';
import AlbumIcon from '../Icon/AlbumIcon';
import SongIcon from '../Icon/SongIcon';
import ArtistIcon from '../Icon/ArtistIcon';
import CategoryIcon from '../Icon/CategoryIcon';
import AppUserIcon from '../Icon/AppUserIcon';
import AdminUserIcon from '../Icon/AdminUserIcon';
import AdvertiseIcon from '../Icon/AdvertiseIcon';
import SectionIcon from '../Icon/SectionIcon';
import ReportsIcon from '../Icon/ReportsIcon';
import NotificationsIcon from '../Icon/NotificationsIcon';

const { Sider } = Layout;

const SideMenu = ({ setIsShowSidebar }) => {
  const { userValue } = useSelector((state) => state.user);
  const location = useLocation();
  const menuList = [];

  const routing = () => {
    if (userValue?.role?.isAdmin) {
      menuList.push(
        {
          key: 'dashboard',
          icon: <DashboardIcon />,
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          key: 'albums',
          icon: <AlbumIcon />,
          name: 'Albums',
          url: '/albums',
        },
        {
          key: 'songs',
          icon: <SongIcon />,
          name: 'Songs',
          url: '/songs',
        },
        {
          key: 'artists',
          icon: <ArtistIcon />,
          name: 'Artists',
          url: '/artists',
        },
        {
          key: 'category',
          icon: <CategoryIcon />,
          name: 'Category',
          url: '/category',
        },
        {
          key: 'app-users',
          icon: <AppUserIcon />,
          name: 'App Users',
          url: '/app-users',
        },
        {
          key: 'admin-users',
          icon: <AdminUserIcon />,
          name: 'Admin Users',
          url: '/admin-users',
        },
        {
          key: 'advertise',
          icon: <AdvertiseIcon />,
          name: 'Advertise',
          url: '/advertise',
        },
        {
          key: 'sections',
          icon: <SectionIcon />,
          name: 'Sections',
          url: '/sections',
        },
        {
          key: 'reports',
          icon: <ReportsIcon />,
          name: 'Reports',
          url: '/reports',
        },
        {
          key: 'notifications',
          icon: <NotificationsIcon />,
          name: 'Notifications',
          url: '/notifications',
        }
      );
    } else {
      userValue?.role?.permissions?.forEach((item) => {
        if (item.page.value === 'dashboard') {
          menuList.push({
            key: 'dashboard',
            icon: <DashboardIcon />,
            name: 'Dashboard',
            url: '/dashboard',
          });
        }
        if (item.page.value === 'albums') {
          menuList.push({
            key: 'albums',
            icon: <AlbumIcon />,
            name: 'Albums',
            url: '/albums',
          });
        }
        if (item.page.value === 'songs') {
          menuList.push({
            key: 'songs',
            icon: <SongIcon />,
            name: 'Songs',
            url: '/songs',
          });
        }
        if (item.page.value === 'artists') {
          menuList.push({
            key: 'artists',
            icon: <ArtistIcon />,
            name: 'Artists',
            url: '/artists',
          });
        }
        if (item.page.value === 'category') {
          menuList.push({
            key: 'category',
            icon: <CategoryIcon />,
            name: 'Category',
            url: '/category',
          });
        }
        if (item.page.value === 'app_users') {
          menuList.push({
            key: 'app-users',
            icon: <AppUserIcon />,
            name: 'App Users',
            url: '/app-users',
          });
        }
        if (item.page.value === 'admin_users') {
          menuList.push({
            key: 'admin-users',
            icon: <AdminUserIcon />,
            name: 'Admin Users',
            url: '/admin-users',
          });
        }
        if (item.page.value === 'advertise') {
          menuList.push({
            key: 'advertise',
            icon: <AdvertiseIcon />,
            name: 'Advertise',
            url: '/advertise',
          });
        }
        if (item.page.value === 'sections') {
          menuList.push({
            key: 'sections',
            icon: <SectionIcon />,
            name: 'Sections',
            url: '/sections',
          });
        }
        if (item.page.value === 'reports') {
          menuList.push({
            key: 'reports',
            icon: <ReportsIcon />,
            name: 'Reports',
            url: '/reports',
          });
        }
        if (item.page.value === 'notifications') {
          menuList.push({
            key: 'notifications',
            icon: <NotificationsIcon />,
            name: 'Notifications',
            url: '/notifications',
          });
        }
      });
    }
  };
  routing();

  const matchPathnameWithKey = () => {
    const splitPathname = location.pathname.split('/');
    const actualPathname = splitPathname[1];
    if (actualPathname === '') {
      return ['dashboard'];
    }
    const returnVal = [actualPathname];
    return returnVal;
  };

  return (
    <Sider
      collapsible
      reverseArrow={true}
      onCollapse={(collapsed) => {
        setIsShowSidebar(collapsed);
      }}
      breakpoint='md'
      theme='light'
      style={{
        overflow: 'auto',
        position: 'fixed',
        left: 0,
        top: '76px',
        bottom: '48px',
      }}
      className='box-shadow-2'
    >
      <Menu theme='light' mode='vertical' selectedKeys={matchPathnameWithKey()}>
        {menuList?.map((item) => {
          return (
            <Menu.Item key={item.key}>
              {item.icon}
              <span className='title'>{item.name}</span>
              <Link to={`${item.url}`}></Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
};

export default SideMenu;
