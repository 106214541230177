import { methods, serviceMaker } from '../service';

const GET_SONG = (
  limit,
  skip,
  search,
  sort,
  songType,
  isdownloadable,
  artistId,
  albumId
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (songType) {
    query['params']['songType'] = songType;
  }
  if (artistId) {
    query['params']['artistId'] = artistId;
  }
  if (albumId) {
    query['params']['albumId'] = albumId;
  }
  if (isdownloadable) {
    query['params']['isdownloadable'] = isdownloadable;
  }
  if (isdownloadable === false) {
    query['params']['isdownloadable'] = isdownloadable;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`song`, methods.GET, query);
};

const REMOVE_SONG = (id) => serviceMaker(`song/${id}`, methods.DELETE);

const SINGLE_SONG = (id) => serviceMaker(`song/${id}`, methods.GET);

const ALL_SONGS = (limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`song`, methods.GET, query);
};

const CREATE_SONGS = (payload) =>
  serviceMaker(`song`, methods.POST, null, payload);

const UPDATE_SONGS = (payload) =>
  serviceMaker(`song`, methods.PATCH, null, payload);

const SEARCH_ARTIST = (limit, skip, search) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`artists`, methods.GET, query);
};

const CREATE_SONG_ARTIST = (payload) =>
  serviceMaker(`song-artist`, methods.POST, null, payload);

const REMOVE_SONG_ARTIST = (payload) =>
  serviceMaker(`song-artist`, methods.PATCH, null, payload);

const CREATE_ALBUMS_SONG = (payload) =>
  serviceMaker(`album-song`, methods.POST, null, payload);

const GET_SELECT_ALBUM_SONG = (limit, skip, search, sort, id) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
      albumId: id,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[positionNo]'] = -1;
  }
  return serviceMaker(`album-song`, methods.GET, query);
};

const GET_ALBUM_SONG_ON_SONG_ID = (id) => {
  const query = {
    params: {
      $limit: '-1',
      songId: id,
    },
  };
  return serviceMaker(`album-song`, methods.GET, query);
};

const GET_ALBUM_SONG_ON_ALBUM_ID = (id) => {
  const query = {
    params: {
      $limit: '-1',
      albumId: id,
      '$sort[positionNo]': -1,
    },
  };
  return serviceMaker(`album-song`, methods.GET, query);
};

const UPDATE_ALBUM_SONG = (id, payload) =>
  serviceMaker(`album-song/${id}`, methods.PATCH, null, payload);

const DELETE_ALBUM_SONG = (id) =>
  serviceMaker(`album-song/${id}`, methods.DELETE);

const UPDATE_MEDIA_SOUND = (id, payload) =>
  serviceMaker(`media-sound/${id}`, methods.PATCH, null, payload);

const GET_SELECT_SONG_ALBUM = (limit, skip, id, sort) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
      songId: id,
    },
  };
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`album-song`, methods.GET, query);
};

const GET_SONG_ALBUM_ON_SONG_ID = (limit, skip, search, id) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
      songId: id,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`album-song`, methods.GET, query);
};

const REMOVE_ALBUM_SONG = (payload) =>
  serviceMaker(`album-song`, methods.PATCH, null, payload);

const REMOVE_SINGLE_ALBUM_SONG = (id) =>
  serviceMaker(`album-song/${id}`, methods.DELETE);

const GET_CHOOSE_INTEREST = () => {
  const query = {
    params: {
      $limit: '-1',
    },
  };
  return serviceMaker(`choose-interests`, methods.GET, query);
};

export const SONGS = {
  GET_SONG,
  REMOVE_SONG,
  ALL_SONGS,
  SEARCH_ARTIST,
  CREATE_SONGS,
  CREATE_SONG_ARTIST,
  CREATE_ALBUMS_SONG,
  UPDATE_SONGS,
  REMOVE_SONG_ARTIST,
  SINGLE_SONG,
  UPDATE_ALBUM_SONG,
  GET_SELECT_ALBUM_SONG,
  DELETE_ALBUM_SONG,
  UPDATE_MEDIA_SOUND,
  GET_SELECT_SONG_ALBUM,
  GET_SONG_ALBUM_ON_SONG_ID,
  REMOVE_ALBUM_SONG,
  GET_CHOOSE_INTEREST,
  GET_ALBUM_SONG_ON_SONG_ID,
  REMOVE_SINGLE_ALBUM_SONG,
  GET_ALBUM_SONG_ON_ALBUM_ID,
};
