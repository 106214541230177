import React from 'react';

import { Row, Col } from 'antd';

import UpArrowIcon from '../Icon/UpArrowIcon';
import DownArrow from '../Icon/DownArrow';

const ThreeBox = ({
  title,
  boxOneTitle,
  boxOneNum,
  boxOnePer,
  boxTwoTitle,
  boxTwoNum,
  boxTwoPer,
  boxThreeTitle,
  boxThreeNum,
  boxThreePer,
}) => {
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={10} xl={10} className=' mt-3 gutter-row'>
        <div className='bg-white box-shadow-3  border-radius-10 p-2 '>
          <h2 className='font-weight-700 mb-0'>{title}</h2>
          <Row justify='space-between' gutter={25}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className=' mt-2 gutter-row'
            >
              <div className='border-gray-1  border-radius-10 p-2 '>
                <h3 className='font-weight-700 mb-0'>{boxOneTitle}</h3>
                <Row align='bottom'>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className=' mt-2 gutter-row'
                  >
                    <h1 className='pl-1 font-size-50 mb-0'>{boxOneNum}</h1>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className=' mt-2 gutter-row'
                  >
                    <div className='d-flex align-items-baseline pl-3'>
                      {boxOnePer >= 0 ? <UpArrowIcon /> : <DownArrow />}
                      <h3
                        className={
                          boxOnePer >= 0
                            ? 'pl-1 mb-0 text-green'
                            : 'pl-1 mb-0 text-red'
                        }
                      >
                        {boxOnePer}%
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row justify='space-between' gutter={25}>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className=' mt-2 gutter-row'
            >
              <div className='border-gray-1  border-radius-10 p-2 '>
                <h3 className='font-weight-700 mb-0'>{boxTwoTitle}</h3>
                <Row align='bottom' className='pl-1'>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='gutter-row'
                  >
                    <h1 className='font-size-50 mb-0'>{boxTwoNum}</h1>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='gutter-row'
                  >
                    <div className='d-flex align-items-baseline'>
                      {boxTwoPer >= 0 ? <UpArrowIcon /> : <DownArrow />}
                      <h3
                        className={
                          boxTwoPer >= 0
                            ? 'pl-1 mb-0 text-green'
                            : 'pl-1 mb-0 text-red'
                        }
                      >
                        {boxTwoPer}%
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className=' mt-2 gutter-row'
            >
              <div className='border-gray-1  border-radius-10 p-2 '>
                <h3 className='font-weight-700 mb-0'>{boxThreeTitle}</h3>
                <Row align='bottom' className='pl-1'>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='gutter-row'
                  >
                    <h1 className='font-size-50 mb-0'>{boxThreeNum}</h1>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='gutter-row'
                  >
                    <div className='d-flex align-items-baseline'>
                      {boxThreePer >= 0 ? <UpArrowIcon /> : <DownArrow />}
                      <h3
                        className={
                          boxThreePer >= 0
                            ? 'pl-1 mb-0 text-green'
                            : 'pl-1 mb-0 text-red'
                        }
                      >
                        {boxThreePer}%
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default ThreeBox;
