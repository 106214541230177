import React from 'react';

const JalsoLogo = ({ height, width }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox='0 0 135 60'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M26.4343 50.7102L27.9436 50.1563L24.5615 44.3959L23.5833 45.5591C23.5553 45.1159 23.1361 44.7836 22.6329 44.7836C22.1019 44.7836 21.6826 45.1436 21.6826 45.6144C21.6826 45.6975 21.7106 45.7806 21.7385 45.8637C21.7665 46.0575 21.8503 46.3068 21.9901 46.5283L24.7852 51.4579C25.0647 51.9841 25.5119 52.2887 25.7914 52.2333C25.8473 52.2333 25.8752 52.2333 25.9311 52.2333C26.4622 52.2333 26.8815 51.8733 26.8815 51.4025C26.8815 51.1256 26.6858 50.8763 26.4343 50.7102ZM30.4592 48.8547L26.8256 42.7896C26.6299 42.4296 26.3225 42.2358 26.1548 42.3465L24.869 43.122C24.7013 43.2327 24.7013 43.5928 24.9249 43.9528L28.5585 50.0178C28.7542 50.3778 29.0616 50.5717 29.2293 50.4609L30.5151 49.6855C30.6828 49.5747 30.6548 49.187 30.4592 48.8547Z'
          fill='#9F52F4'
        />
        <path
          d='M27.0485 26.4205C19.5018 23.568 10.7532 22.4048 4.43627 28.4975C1.27782 31.5993 -0.455129 36.058 0.103888 40.766C0.607003 44.9202 2.75922 48.465 5.86176 50.8467C11.5358 54.8624 19.949 53.2838 23.6385 51.3729V51.4006C23.8062 51.3175 23.9739 51.2344 24.1416 51.1513L21.7937 46.9972C16.2874 49.5728 12.9613 45.0032 13.6321 40.4891C13.8557 39.0213 14.6104 37.9689 15.8961 37.3597C18.8309 35.6149 28.7814 38.8551 32.1635 43.397C31.9678 44.9478 31.6604 46.2495 31.6045 47.1357C31.4927 49.9051 31.8001 52.0929 32.5269 53.7269C34.0362 57.1056 37.3903 60.235 41.3314 59.9858C44.7134 59.9581 47.5365 57.6318 48.375 54.5023C48.4029 54.3915 48.4309 54.2531 48.4588 54.1146C51.2819 41.5969 39.0394 30.9623 27.0485 26.4205ZM40.5767 55.7486C36.0487 56.884 36.4959 47.6342 37.6978 46.8864C44.1544 50.8744 42.7848 55.1947 40.5767 55.7486Z'
          fill='#9F52F4'
        />
        <path
          d='M64.3344 19.3594C64.2226 19.5532 64.1108 19.7471 63.9431 19.9132C62.7412 21.1872 60.0859 21.1872 56.8157 20.1625C55.3902 20.024 54.2162 20.2456 53.2659 20.7995C53.2659 20.7995 53.2659 20.7995 53.238 20.7995H53.21C53.21 20.7995 53.1821 20.7994 53.1821 20.8271C53.1262 20.8548 53.0982 20.8825 53.0703 20.9102C52.623 21.2149 51.4771 22.1288 50.387 23.9566C50.387 23.9566 50.387 23.9566 50.387 23.9843L43.2316 35.2004C41.3309 32.791 38.3681 30.1601 35.8525 28.6923L39.3464 16.8392C40.1011 14.956 40.3247 12.6851 40.3806 11.9373C40.3806 11.9373 40.3806 11.9373 40.4085 11.9373C40.4085 11.8542 40.4085 11.7712 40.4085 11.6881C40.4085 10.4972 40.1849 9.33408 39.6539 8.14323C37.3619 5.09687 36.6072 2.38285 37.9489 0.970449C37.9768 0.942755 38.0048 0.91506 38.0327 0.887366L38.0607 0.85967C38.3961 0.555035 38.8433 0.333482 39.3743 0.167317C39.4023 0.167317 39.4303 0.139625 39.4582 0.139625C40.7998 -0.16501 42.7564 0.0288461 45.0204 0.7212C45.5515 0.887364 46.1105 1.08122 46.6695 1.27508C47.0049 1.38586 47.3124 1.52433 47.6478 1.6628C47.6757 1.6628 47.6757 1.69049 47.7037 1.69049C47.7876 1.71819 47.8714 1.74589 47.9273 1.80127C50.0236 2.71518 52.2317 3.93372 54.4398 5.48459C56.2566 6.75852 57.8778 8.11554 59.2753 9.47255C59.3033 9.50024 59.3592 9.55563 59.3871 9.58332C59.9741 10.1649 60.5052 10.7465 61.0083 11.3004C61.3437 11.6881 61.6791 12.0758 61.9586 12.4635C63.5239 14.5129 64.4742 16.4238 64.586 17.8639C64.586 17.8916 64.586 17.9193 64.586 17.9746C64.614 18.4731 64.5301 18.9439 64.3344 19.3594Z'
          fill='#9F52F4'
        />
        <path
          d='M133.486 54.2256H125.66C125.352 54.2256 125.101 53.9764 125.101 53.6717V23.2082C125.101 22.9035 125.352 22.6543 125.66 22.6543H133.486C133.793 22.6543 134.045 22.9035 134.045 23.2082V53.6717C134.045 53.9764 133.793 54.2256 133.486 54.2256Z'
          fill='#9F52F4'
        />
        <path
          d='M120.125 2.21617L131.585 13.5431C131.808 13.7646 131.808 14.097 131.585 14.3185L126.246 19.6081C125.911 19.9404 125.38 19.9404 125.072 19.6081L116.687 11.2998C116.519 11.1337 116.324 11.0506 116.1 11.0506H109.28C108.805 11.0506 108.441 10.6906 108.441 10.2198V3.0193C108.441 2.5485 108.805 2.18848 109.28 2.18848H120.125'
          fill='#9F52F4'
        />
        <path
          d='M121.579 23.3736V53.4772C121.579 53.8926 121.243 54.1972 120.852 54.1972H113.389C112.97 54.1972 112.662 53.8649 112.662 53.4772V39.0208C112.662 38.9101 112.578 38.8547 112.495 38.8547H107.324C107.268 38.8547 107.212 38.8824 107.184 38.9377C105.842 41.2641 103.774 42.7318 101.454 42.7318H100.42C99.4974 42.7318 98.7986 43.5627 98.9664 44.4766L99.2459 45.9721C99.3856 46.7475 100.084 47.3014 100.867 47.3014H108.358C108.777 47.3014 109.085 47.6337 109.085 48.0214V53.671C109.085 53.9757 108.833 54.2249 108.526 54.2249H93.4041C92.7613 54.2249 92.2023 53.7818 92.0905 53.1448L90.1898 43.4519C90.1059 43.0642 89.7705 42.7595 89.3513 42.7595H86.5841C86.1649 42.7595 85.8574 42.4272 85.8574 42.0395V36.5561C85.8574 36.1406 86.1928 35.836 86.5841 35.836H98.016C99.4974 35.836 100.699 34.6452 100.699 33.1774V32.2635C100.699 30.7957 99.4974 29.6048 98.016 29.6048H89.2395C88.8202 29.6048 88.5128 29.2725 88.5128 28.8848V23.429C88.5128 23.0136 88.8482 22.709 89.2395 22.709H101.426C101.482 22.709 101.566 22.709 101.622 22.709C105.339 22.8475 108.386 26.78 108.721 31.8204C108.721 31.9034 108.805 31.9865 108.889 31.9865H112.467C112.578 31.9865 112.634 31.9034 112.634 31.8204V23.4567C112.634 23.0413 112.97 22.7367 113.361 22.7367H120.824C121.243 22.6536 121.579 22.9859 121.579 23.3736Z'
          fill='#9F52F4'
        />
        <path
          d='M70.9035 48.5483V52.1762C70.9035 52.3978 70.7916 52.5917 70.596 52.6747C68.6115 53.6717 66.4034 54.2256 64.0834 54.2256C55.782 54.2256 49.0459 47.1636 49.0459 38.44C49.0459 29.7163 55.782 22.6543 64.0834 22.6543C66.4034 22.6543 68.6394 23.2082 70.596 24.2052C70.7916 24.2883 70.9035 24.4821 70.9035 24.7037V28.3316C70.9035 28.6916 70.5401 28.9686 70.1767 28.8578C69.3103 28.5808 68.4158 28.4424 67.4934 28.4424C62.2387 28.4424 57.9902 32.9011 57.9902 38.4123C57.9902 43.9234 62.2387 48.3821 67.4934 48.3821C68.4158 48.3821 69.3382 48.2437 70.1767 47.9667C70.5401 47.8837 70.9035 48.1606 70.9035 48.5483Z'
          fill='#9F52F4'
        />
        <path
          d='M83.649 23.208V53.6715C83.649 53.9762 83.3974 54.2254 83.09 54.2254H75.2637C74.9563 54.2254 74.7047 53.9762 74.7047 53.6715V43.0093H63.8598C63.5523 43.0093 63.3008 42.76 63.3008 42.4554V36.6396C63.3008 36.335 63.5523 36.0858 63.8598 36.0858H74.7047V23.2357C74.7047 22.931 74.9563 22.6818 75.2637 22.6818H83.09C83.4254 22.6541 83.649 22.9033 83.649 23.208Z'
          fill='#9F52F4'
        />
      </svg>
    </>
  );
};

export default JalsoLogo;
