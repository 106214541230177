import { useEffect, useState } from 'react';

import { UploadOutlined, LinkOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  Form,
  Input,
  Upload,
  Checkbox,
  Switch,
  Select,
  DatePicker,
  Row,
  Col,
} from 'antd';

import InsideHeader from '../../../components/InsideHeader/InsideHeader';
import ButtonCom from '../../../components/Button/ButtonCom';
import SelectDropDown from '../../../components/Select';
import { ADVERTISE } from '../../../service/index';
import { BASE_URL } from '../../../utility/constant';

const { RangePicker } = DatePicker;
const { Option } = Select;

const AddEditAdvertise = () => {
  const [tabs, setTabs] = useState([]);
  const [subTabs, setSubTabs] = useState([]);
  const [sections, setSections] = useState([]);
  const [advertiseType, setAdvertiseType] = useState('');
  const [advertiseOption, setAdvertiseOption] = useState('');

  const [fileList, setFileList] = useState([]);
  const [mediaItemId, setMediaItemId] = useState(null);

  const navigate = useNavigate();
  const { id = null } = useParams();

  const [form] = Form.useForm();
  const operationName = id ? 'edit' : 'add';

  const getTab = async () => {
    try {
      const res = await ADVERTISE.GET_TAB();
      if (res.status === 1) {
        const data = res?.data?.map((item) => {
          const subTab = item?.sub_tabs?.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          return {
            label: item.name,
            value: item.id,
            subTab,
          };
        });
        setTabs(data);
      }
    } catch (err) {
      toast.error(err?.message || 'something went wrong!');
    }
  };

  const setSubTab = (tabId, isEdit) => {
    if (tabId) {
      if (!isEdit) {
        form.setFieldsValue({ subTabId: null, sectionId: null });
      }
      tabs &&
        tabs.forEach((tab) => {
          if (tab?.value === tabId?.value) {
            setSubTabs([...tab?.subTab]);
          }
        });
    }
  };

  const setSection = async (subTabId, isEdit) => {
    if (subTabId) {
      try {
        const res = await ADVERTISE.GET_SUB_TAB_SECTIONS(subTabId?.value);
        if (res.status === 1 && res?.data?.sections) {
          if (!isEdit) {
            form.setFieldsValue({ sectionId: null });
          }
          const data = res?.data?.sections?.map((item) => {
            return {
              label: item?.name,
              value: item?.id,
            };
          });
          setSections(data);
        }
      } catch (err) {
        toast.error(err?.message || 'something went wrong !');
      }
    }
  };

  const changePriorityValueToNumber = (value) => {
    if (value === 'high') {
      return 3;
    } else if (value === 'medium') {
      return 2;
    } else {
      return value === 'low' ? 1 : null;
    }
  };

  const addEditAdvertise = async (value) => {
    const payload = {
      ...value,
      adCount: parseInt(value.adCount),
      mediaItemId: value.advertisePlatform === 'jalso' ? mediaItemId : null,
      startDate: value?.date?.length > 0 ? new Date(value?.date[0]?._d) : null,
      endDate: value?.date?.length > 0 ? new Date(value?.date[1]?._d) : null,
      platformAndroid: value?.platform?.includes('android'),
      platformIPhone: value?.platform?.includes('ios'),
      platform: '',
      sectionId: advertiseType === 'box_ads' ? value?.sectionId?.value : null,
      subTabId:
        advertiseType === 'half_screen_ads' ||
        advertiseType === 'full_screen_ads' ||
        advertiseType === 'small_banner'
          ? value?.subTabId?.value
          : null,
      adsPriority: changePriorityValueToNumber(value.adsPriority),
    };
    delete payload?.tab;
    delete payload?.adImage;
    delete payload?.date;

    try {
      let res;
      if (operationName === 'edit') {
        res = await ADVERTISE.EDIT_ADVERTISE(id, payload);
      }
      if (operationName === 'add') {
        res = await ADVERTISE.ADD_ADVERTISE(payload);
      }
      if (res.status === 1 || res.status === '1') {
        toast.success(res?.message);
        navigate('/advertise');
      }
    } catch (err) {
      toast.error(err?.message || 'something went wrong!');
    }
  };

  const changePriorityValueToHighMediumAndLow = (value) => {
    if (value === 3) {
      return 'high';
    } else if (value === 2) {
      return 'medium';
    } else {
      return value === 1 ? 'low' : null;
    }
  };

  useEffect(() => {
    const editAdvertise = async () => {
      try {
        const res = await ADVERTISE.GET_ADVERTISE_BY_ID(id);
        if (res.status === 1 || res.status === '1') {
          setAdvertiseType(res?.data?.type);
          setAdvertiseOption(res.data?.advertisePlatform);
          let tabId, subTabId, sectionId;
          if (res?.data?.type === 'box_ads') {
            tabId = {
              label: res?.data?.section?.sub_tab?.tab?.name,
              value: res?.data?.section?.sub_tab?.tab?.id,
            };
            subTabId = {
              label: res?.data?.section?.sub_tab?.name,
              value: res?.data?.section?.sub_tab?.id,
            };
            sectionId = {
              label: res?.data?.section?.name,
              value: res?.data?.section?.id,
            };
          }
          if (
            res?.data?.type === 'half_screen_ads' ||
            res?.data?.type === 'full_screen_ads' ||
            res?.data?.type === 'small_banner'
          ) {
            tabId = {
              label: res?.data.sub_tab?.tab?.name,
              value: res?.data?.sub_tab?.tab?.id,
            };
            subTabId = {
              label: res?.data?.sub_tab?.name,
              value: res?.data?.sub_tab?.id,
            };
          }
          const platform = [];
          if (res?.data?.platformAndroid) {
            platform.push('android');
          }
          if (res?.data?.platformIPhone) {
            platform.push('ios');
          }
          const date = [
            res?.data?.startDate && moment(res?.data?.startDate),
            res?.data?.endDate && moment(res?.data?.endDate),
          ];
          setSubTab(tabId, true);
          setSection(subTabId, true);
          form.setFieldsValue({
            ...res?.data,
            adImage: res?.data?.media_item?.image && [
              {
                uid: '-1',
                name: 'Advertise Image',
                status: 'done',
                url: res?.data?.media_item?.image,
                thumbUrl: res?.data?.media_item?.image,
              },
            ],
            tab: tabId,
            platform,
            date,
            sectionId,
            subTabId,
            adsPriority: changePriorityValueToHighMediumAndLow(
              res?.data?.adsPriority
            ),
          });
          if (res?.data?.media_item?.image) {
            setFileList([
              {
                uid: '-1',
                name: 'Advertise Image',
                status: 'done',
                url: res?.data?.media_item?.image,
                thumbUrl: res?.data?.media_item?.image,
              },
            ]);
            setMediaItemId(res?.data?.media_item?.id);
          }
        }
      } catch (error) {
        toast.error(error.message || 'something went wrong');
      }
    };
    if (id && tabs.length > 0) {
      editAdvertise();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id, tabs.length > 0]);

  useEffect(() => {
    getTab();
  }, []);

  useEffect(() => {
    if (!advertiseType) {
      form.validateFields(['adImage']);
    }
  }, [advertiseType, form]);

  const props = {
    name: 'file',
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL}/uploads-image`,
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload() {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn('File Already Uploaded! Please delete that.');
        return false;
      }
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info?.fileList]);
      }
      if (info?.file?.status === 'done') {
        setMediaItemId(info?.file?.response?.data?.media?.id);
      } else if (info?.file?.status === 'error') {
        toast.error(`${info?.file?.name} file upload failed.`);
      }
    },
    async onRemove() {
      setFileList([]);
      setMediaItemId(null);
    },
  };

  return (
    <>
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} className='gutter-row'>
          <InsideHeader>
            <h1 className='font-size-28'>
              {operationName === 'add'
                ? 'Create New Advertise'
                : 'Edit Advertise'}
            </h1>
            <Form
              form={form}
              onFinish={addEditAdvertise}
              labelCol={{
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 },
                lg: { span: 8 },
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 16 },
                lg: { span: 16 },
              }}
              labelAlign='left'
              name='control-hooks'
              className='form-style'
            >
              <Form.Item
                name='advertisePlatform'
                label='Advertise Platform'
                rules={[
                  {
                    required: true,
                    message: 'Please select Advertise Platform',
                  },
                ]}
              >
                <Select
                  placeholder='Select Advertise Platform'
                  onChange={(e) => {
                    setAdvertiseOption(e);
                  }}
                  allowClear
                >
                  <Option value='jalso'>Jalso</Option>
                  <Option value='google'>Google</Option>
                  <Option value='facebook'>Facebook</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name='adsPriority'
                label='Advertise Priority'
                rules={[
                  {
                    required: true,
                    message: 'Please select advertise priority',
                  },
                ]}
              >
                <Select placeholder='Select Advertise Priority' allowClear>
                  <Option value='high'>High</Option>
                  <Option value='medium'>Medium</Option>
                  <Option value='low'>low</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name='title'
                label='Advertise Title'
                rules={[
                  { required: true, message: 'please fill advertise title' },
                ]}
              >
                <Input
                  placeholder='Advertise Title'
                  className='input-control'
                  allowClear
                />
              </Form.Item>
              {advertiseOption === 'jalso' && (
                <Form.Item
                  name='url'
                  label='Redirect Url'
                  rules={[
                    { required: true, message: 'please fill redirect url' },
                  ]}
                >
                  <Input
                    className='input-control'
                    placeholder='Redirect Url'
                    suffix={
                      <LinkOutlined
                        style={{
                          fontSize: 16,
                          color: '#1890ff',
                        }}
                      />
                    }
                    allowClear
                  />
                </Form.Item>
              )}
              {(advertiseOption === 'google' ||
                advertiseOption === 'facebook') && (
                <Form.Item
                  name='advertiseId'
                  label='Advertise Id'
                  rules={[
                    { required: true, message: 'Advertise id is required !' },
                  ]}
                >
                  <Input
                    className='input-control'
                    placeholder='Advertise Id'
                    allowClear
                  />
                </Form.Item>
              )}
              <Form.Item
                name='type'
                label='Advertisement Type'
                rules={[
                  { required: true, message: 'please select advertise type' },
                ]}
              >
                <Select
                  placeholder='Select Advertisement Type'
                  onChange={(e) => {
                    form.setFieldsValue({
                      tab: null,
                      subTabId: null,
                      sectionId: null,
                    });
                    setAdvertiseType(e);
                  }}
                  allowClear
                >
                  <Option value='box_ads'>Box Advertisement</Option>
                  <Option value='small_banner'>Small Banner</Option>
                  <Option value='half_screen_ads'>
                    Half Screen Advertisement
                  </Option>
                  <Option value='full_screen_ads'>
                    Full Screen Advertisement
                  </Option>
                  <Option value='player_ads'>Player Advertisement</Option>
                </Select>
              </Form.Item>
              {(advertiseType === 'box_ads' ||
                advertiseType === 'small_banner' ||
                advertiseType === 'half_screen_ads' ||
                advertiseType === 'full_screen_ads') && (
                <Form.Item
                  name='tab'
                  label='Select Tab'
                  rules={[{ required: true, message: 'please select tab' }]}
                >
                  <SelectDropDown
                    placeholder='Select Tab'
                    options={tabs}
                    onChange={(e) => {
                      setSubTab(e, false);
                    }}
                    isClearable
                  />
                </Form.Item>
              )}
              {(advertiseType === 'box_ads' ||
                advertiseType === 'small_banner' ||
                advertiseType === 'half_screen_ads' ||
                advertiseType === 'full_screen_ads') && (
                <Form.Item
                  name='subTabId'
                  label='Select Sub-Tab'
                  rules={[{ required: true, message: 'please select sub-tab' }]}
                >
                  <SelectDropDown
                    placeholder='Select Sub-Tab'
                    options={subTabs}
                    onChange={(e) => {
                      setSection(e, false);
                    }}
                    isClearable
                  />
                </Form.Item>
              )}
              {advertiseType === 'box_ads' && (
                <Form.Item
                  name='sectionId'
                  label='Select section'
                  rules={[{ required: true, message: 'please select section' }]}
                >
                  <SelectDropDown
                    placeholder='Select Section'
                    options={sections}
                    isClearable
                  />
                </Form.Item>
              )}
              {advertiseOption === 'jalso' && (
                <Form.Item
                  valuePropName='filelist'
                  name='adImage'
                  label='Ad Image'
                  rules={[
                    {
                      required: !!(
                        advertiseType === 'small_banner' ||
                        advertiseType === 'half_screen_ads' ||
                        advertiseType === 'full_screen_ads' ||
                        advertiseType === 'player_ads'
                      ),
                      message: 'Image is required',
                    },
                  ]}
                >
                  <Upload
                    accept='.png, .jpg, .jpeg'
                    listType='picture'
                    {...props}
                  >
                    <ButtonCom type='primary' icon={<UploadOutlined />}>
                      choose a file
                    </ButtonCom>
                  </Upload>
                </Form.Item>
              )}
              <Form.Item
                name='platform'
                label='Select Platform'
                rules={[{ required: true, message: 'please select platform' }]}
              >
                <Checkbox.Group
                  style={{ width: '100%' }}
                  options={[
                    { value: 'ios', label: 'iOS' },
                    { value: 'android', label: 'Android' },
                  ]}
                ></Checkbox.Group>
              </Form.Item>
              <Form.Item
                label='Active'
                name='isActivate'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
              <h1 className='font-size-28'>Select Budget</h1>
              <Form.Item name='adCount' label='Impression Count'>
                <Input type='number' min={0} className='input-control' />
              </Form.Item>
              <h3 className='text-center mb-2'>And/Or</h3>
              <Form.Item
                label='Select Date'
                name='date'
                rules={[{ required: true, message: 'please select date' }]}
              >
                <RangePicker className='input-control w-100' />
              </Form.Item>
              <div className='form-btn-group'>
                <ButtonCom
                  className='mr-2 width-180'
                  onClick={() => navigate('/advertise')}
                >
                  Back
                </ButtonCom>
                <ButtonCom
                  htmlType='submit'
                  type='primary'
                  className='width-180'
                >
                  {operationName === 'add' ? 'Create' : 'Edit'}
                </ButtonCom>
              </div>
            </Form>
          </InsideHeader>
        </Col>
      </Row>
    </>
  );
};

export default AddEditAdvertise;
