import React from 'react';

const AppUserIcon = () => {
  return (
    <>
      <span className='anticon anticon-dashboard ant-menu-item-icon'>
        <svg
          width='20'
          height='20'
          viewBox='0 0 24 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M17 21.7744V19.7744C17 18.7135 16.5786 17.6961 15.8284 16.946C15.0783 16.1958 14.0609 15.7744 13 15.7744H5C3.93913 15.7744 2.92172 16.1958 2.17157 16.946C1.42143 17.6961 1 18.7135 1 19.7744V21.7744'
            stroke='#656565'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            strokeWidth='2'
            d='M9 11.7744C11.2091 11.7744 13 9.98355 13 7.77441C13 5.56527 11.2091 3.77441 9 3.77441C6.79086 3.77441 5 5.56527 5 7.77441C5 9.98355 6.79086 11.7744 9 11.7744Z'
            stroke='#656565'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M23 21.7743V19.7743C22.9993 18.888 22.7044 18.0271 22.1614 17.3266C21.6184 16.6262 20.8581 16.1259 20 15.9043'
            stroke='#656565'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M16 3.9043C16.8604 4.1246 17.623 4.625 18.1676 5.32661C18.7122 6.02822 19.0078 6.89113 19.0078 7.7793C19.0078 8.66747 18.7122 9.53038 18.1676 10.232C17.623 10.9336 16.8604 11.434 16 11.6543'
            stroke='#656565'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
        </svg>
      </span>
    </>
  );
};

export default AppUserIcon;
