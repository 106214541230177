import React from 'react';

import { Col, Drawer, Row } from 'antd';

import Logo from '../../assets/image/jalso.jpg';

const InfoDrawer = ({ setVisible, visible, infoDeatils }) => {
  return (
    <Drawer
      title='Artist Info'
      placement='right'
      onClose={() => {
        setVisible(false);
      }}
      visible={visible}
    >
      <div className='text-center'>
        {infoDeatils?.media_item?.image ? (
          <img
            src={infoDeatils?.media_item?.image}
            alt=''
            width='150'
            height='150'
            className='border-radius-5'
          />
        ) : (
          <img
            src={Logo}
            alt=''
            width='150'
            height='150'
            className='border-radius-5'
          />
        )}
      </div>
      <Row gutter={0} className='mt-2 mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Artists Name</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>{infoDeatils?.name || '-'}</h2>
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Biography</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>{infoDeatils?.bio || '-'}</h2>
        </Col>
      </Row>
      <Row gutter={0} className='mb-1'>
        <Col xs={24} sm={6} md={6} lg={8} xl={8} className='gutter-row'>
          <h2 className='font-size-15 font-weight-600 mb-0'>Roles</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} className='gutter-row'>
          -
        </Col>
        <Col xs={22} sm={16} md={16} lg={14} xl={14} className='gutter-row'>
          <h2 className='font-size-15 mb-0'>
            {infoDeatils?.artist_added_roles?.map((role, index) => {
              return (
                <span key={index}>
                  {`${(index > 0 ? ' , ' : '') + role?.artist_role?.name}` ||
                    '-'}
                </span>
              );
            })}
          </h2>
        </Col>
      </Row>
    </Drawer>
  );
};

export default InfoDrawer;
