import React, { useEffect, useState } from 'react';

import { Link, useParams, useSearchParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import { toast } from 'react-toastify';

import InsideHeader from '../../../components/InsideHeader/InsideHeader';
import ManageCard from '../../../components/Section/Manage/ManageCard';
import ButtonCom from '../../../components/Button/ButtonCom';
import EmptyScreen from '../../../components/EmptyScreen';
import SelectDropDown from '../../../components/Select';
import { SECTION } from '../../../service';

const Manage = ({ permissions }) => {
  const [singleSubTab, setSingleSubTab] = useState([]);

  const [tab, setTab] = useState([]);
  const [tabValue, setTabValue] = useState(null);

  const [subTab, setSubTab] = useState([]);
  const [subTabValue, setSubTabValue] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams();

  const getManageData = async (id) => {
    try {
      const res = await SECTION.SINGLE_SUB_TAB(id);
      if (res.status === 1) {
        setSingleSubTab(res.data.sections);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const setQueryParams = (obj) => {
    const tempObj = {};
    for (const key in obj) {
      if (obj[key]) {
        tempObj[key] = obj[key];
      }
    }
    setSearchParams(tempObj);
  };

  const handleSearch = () => {
    setSearch(tempSearch);
    setQueryParams({
      search: tempSearch,
      tabId: tabValue?.value,
      tabValue: tabValue?.label,
      subTabId: subTabValue?.value,
      subTabValue: subTabValue?.label,
    });
  };

  const handleChangeOfSearch = (e) => {
    if (!e.target.value) {
      getManageData(subTabValue?.value);
      setSearch(null);
      setQueryParams({
        search: null,
        tabId: tabValue?.value,
        tabValue: tabValue?.label,
        subTabId: subTabValue?.value,
        subTabValue: subTabValue?.label,
      });
    }
    setTempSearch(e.target.value);
  };

  const handleChangeOfTab = (e) => {
    setQueryParams({
      search: search,
      tabId: e?.value,
      tabValue: e?.label,
      subTabId: null,
      subTabValue: null,
    });
    setTabValue(e);
    setSubTabValue(null);
    setSubTab(e?.subTab);
    setSingleSubTab([]);
    setIsLoading(false);
  };

  const handleChangeOfSubTab = (e) => {
    setQueryParams({
      search: search,
      tabId: tabValue?.value,
      tabValue: tabValue?.label,
      subTabId: e?.value,
      subTabValue: e?.label,
    });
    setSubTabValue(e);
  };

  useEffect(() => {
    if (subTabValue?.value) {
      getManageData(subTabValue?.value);
    }
  }, [subTabValue?.value]);

  useEffect(() => {
    if (searchParams.get('search') !== 'null') {
      setTempSearch(searchParams.get('search'));
      setSearch(searchParams.get('search'));
    }
  }, [searchParams]);

  useEffect(() => {
    if (search && singleSubTab.length > 0) {
      const filter = singleSubTab.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setSingleSubTab(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, singleSubTab.length]);

  useEffect(() => {
    const getTab = async () => {
      try {
        const res = await SECTION.TAB();
        if (res.status === 1) {
          const data = res?.data?.map((item) => {
            const subTab = item?.sub_tabs?.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            });
            return {
              label: item.name,
              value: item.id,
              subTab,
            };
          });
          setTab(data);

          const filterTab = data.filter(
            (item) => item.value === Number(searchParams.get('tabId'))
          )[0];
          setTabValue(filterTab);

          setSubTab(filterTab.subTab);

          if (Number(searchParams.get('subTabId'))) {
            const tempSubTab = data
              ?.reduce((pre, item) => [...pre, ...item.subTab], [])
              .filter(
                (item) => item.value === Number(searchParams.get('subTabId'))
              )[0];
            setSubTabValue(tempSubTab);
          } else if (isLoading) {
            const tempSubTab = data
              ?.reduce((pre, item) => [...pre, ...item.subTab], [])
              .filter((item) => item.value === Number(id))[0];
            setSubTabValue(tempSubTab);
            console.log(tempSubTab);
            setQueryParams({
            search: search,
            tabId: filterTab?.value,
            tabValue: filterTab?.label,
            subTabId: tempSubTab?.value,
            subTabValue: tempSubTab?.label,
             });
          } else {
            setSubTabValue(null);
          }
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong!');
      }
    };
    getTab();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isLoading, searchParams]);

  return (
    <>
      <InsideHeader className='mb-3 pb-1'>
        <Row gutter={20} className='align-items-center'>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            className='gutter-row mb-1'
          >
            <Input
              placeholder='Start Typing to Find Section'
              suffix={
                <SearchOutlined
                  style={{ color: '#8f9bb3' }}
                  onClick={handleSearch}
                />
              }
              onChange={(e) => handleChangeOfSearch(e)}
              onKeyPress={(e) => {
                if (e?.key === 'Enter' && e.target.value) {
                  handleSearch();
                }
              }}
              className='input-control'
              allowClear
              value={tempSearch}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            className='gutter-row mb-1'
          >
            {(permissions?.isCreate || permissions?.isCreate === undefined) && (
              <div className='d-flex flex-wrap align-items-center  justify-content-md-left justify-content-end'>
                <Link to={`/sections/add?tabId=${searchParams.get('tabId')}&subTabId=${searchParams.get('subTabId')}&newTab=true`} target="_blank">
                <ButtonCom
                  type='primary'
                >
                  Create New Section
                </ButtonCom>
                  </Link>
              </div>
            )}
          </Col>
        </Row>
      </InsideHeader>

      <InsideHeader className='pt-2 pb-0'>
        <Row gutter={20} className='align-items-center'>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} className='gutter-row'>
            <SelectDropDown
              placeholder='Select Tab'
              options={tab}
              value={tabValue}
              onChange={(e) => handleChangeOfTab(e)}
              className='mb-2 w-100'
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} className='gutter-row'>
            <SelectDropDown
              placeholder='Select Sub-Tab'
              options={subTab}
              value={subTabValue}
              onChange={(e) => handleChangeOfSubTab(e)}
              className='mb-2 w-100'
            />
          </Col>
        </Row>
      </InsideHeader>

      {singleSubTab?.length > 0 && (
        <ManageCard
          getManageData={getManageData}
          singleSubTab={singleSubTab}
          tabId={Number(searchParams.get('tabId'))}
          subTabId={Number(id)}
          search={search}
          handleSearch={handleSearch}
          permissions={permissions}
          subTabValue={subTabValue}
        />
      )}

      {singleSubTab?.length <= 0 && (
        <div className='section-empty-screen'>
          <EmptyScreen />
        </div>
      )}
    </>
  );
};

export default Manage;
