import React from 'react';

import { Col, Row } from 'antd';

import DownArrow from '../Icon/DownArrow';
import UpArrowIcon from '../Icon/UpArrowIcon';

const UserPlayLists = ({ dashboard }) => {
  const increasePercentageOfUserPlayList = () => {
    if (
      dashboard?.user_playlists?.user_playlist_previous[0]?.user_playlist ===
        '0' &&
      dashboard?.user_playlists?.user_playlist[0]?.user_playlist === '0'
    ) {
      return 0;
    }
    if (
      dashboard?.user_playlists?.user_playlist_previous[0]?.user_playlist ||
      dashboard?.user_playlists?.user_playlist[0]?.user_playlist
    ) {
      const increase =
        Number(dashboard?.user_playlists?.user_playlist[0]?.user_playlist) -
        Number(
          dashboard?.user_playlists?.user_playlist_previous[0]?.user_playlist
        );
      const increasePercentage =
        (Number(increase) /
          Number(dashboard?.user_playlists?.user_playlist[0]?.user_playlist)) *
        100;
      return Math.round(increasePercentage);
    }
    return 0;
  };
  return (
    <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
      <h2 className='font-weight-700'>User Playlists</h2>
      <div className='border-gray-1 border-radius-10 p-2 '>
        <h3 className='font-weight-700 mb-0'>Total Playlist Created</h3>
        <Row align='bottom'>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className='gutter-row'>
            <h1 className='pl-1 font-size-50 mb-0'>
              {dashboard?.user_playlists?.user_playlist[0]?.user_playlist}
            </h1>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className=' mt-2 gutter-row'
          >
            <div className='d-flex align-items-baseline pl-3'>
              {increasePercentageOfUserPlayList() >= 0 ? (
                <UpArrowIcon />
              ) : (
                <DownArrow />
              )}
              <h3
                className={
                  increasePercentageOfUserPlayList() >= 0
                    ? 'pl-1 mb-0 text-green'
                    : 'pl-1 mb-0 text-red'
                }
              >
                {dashboard?.user_playlists &&
                  increasePercentageOfUserPlayList()}
                %
              </h3>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UserPlayLists;
