import React from 'react';

const UpArrowIcon = () => {
  return (
    <>
      <svg
        width='23'
        height='23'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10 1.75L18.25 8.16667H13.6758L13.6667 18.25H6.33333V8.16667H1.75L10 1.75Z'
          stroke='#3DAE63'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </>
  );
};

export default UpArrowIcon;
