import React from 'react';

import Logo from '../../assets/image/jalso.jpg';

const TableImage = ({ image }) => {
  return (
    <>
      {image ? (
        <img src={image} loading='lazy' className='table-cover-img' alt='' />
      ) : (
        <img src={Logo} loading='lazy' className='table-cover-img' alt='' />
      )}
    </>
  );
};

export default TableImage;
