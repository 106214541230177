import React, { useEffect, useRef, useState } from 'react';

import { Row, Col, DatePicker, Checkbox, Input, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';

import { tableSortIconToggle } from '../../../utility/tableSorting';
import PaginationCom from '../../../components/PaginationCom';
import { COUNTRY_LIST } from '../../../utility/CountryList';
import SelectDropDown from '../../../components/Select';
import Spinner from '../../../components/Spinner';
import { REPORTS } from '../../../service';
import {
  ExportExcel,
  ExportPdf,
  handleFileName,
} from '../../../utility/Export/Export';

const { RangePicker } = DatePicker;

const UserTab = () => {
  const [userList, setUserList] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueOfRangePicker, setValueOfRangePicker] = useState(null);

  const [gender, setGender] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  const [csv, setCsv] = useState([]);
  const csvLink = useRef();

  const [spinnerValue, setSpinnerValue] = useState(false);

  const countryList = COUNTRY_LIST;

  const headers = [
    { label: 'User Name', key: 'fullname' },
    { label: 'Phone Number', key: 'phoneNumber' },
    { label: 'IOS', key: 'ios' },
    { label: 'Android', key: 'android' },
    { label: 'Gender', key: 'gender' },
    { label: 'Playlist Count', key: 'playlist_count' },
    { label: 'Favorite Album', key: 'album_count' },
    { label: 'Favorite Song', key: 'songs_count' },
  ];

  const genderOption = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  const getUserReport = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    startDate = null,
    endDate = null,
    gender = null,
    countryCode = null
  ) => {
    setLoading(true);
    try {
      const res = await REPORTS.REPORTS_USER(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        startDate,
        endDate,
        gender,
        countryCode
      );
      if (res.status === 1 || res.status === '1') {
        setUserList(res);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSorting = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort(null);
    }
  };

  const exportData = async () => {
    setSpinnerValue(true);
    try {
      const res = await REPORTS.REPORTS_USER(
        '-1',
        0,
        search,
        sort,
        startDate,
        endDate,
        gender?.value,
        countryCode?.value
      );
      if (res.status === 1 || res.status === '1') {
        return res;
      }
    } catch (error) {
      setSpinnerValue(false);
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setSpinnerValue(false);
    }
  };

  const generatePDF = async () => {
    const res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const pdfTableColumn = [
        'User Name',
        'Phone Number',
        'IOS',
        'Android',
        'Gender',
        'Playlist Count',
        'Favorite Album',
        'Favorite Song',
      ];
      const pdfTableRows = [];
      res?.data.forEach((item) => {
        const userListData = [
          item.fullname,
          item.phoneNumber,
          item?.ios,
          item?.android,
          item.gender,
          item.playlist_count,
          item.album_count,
          item.songs_count,
        ];
        pdfTableRows.push(userListData);
      });
      ExportPdf(pdfTableColumn, pdfTableRows, 'userReport');
    }
  };

  const generateExcel = async () => {
    const res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data.map((item) => {
        return {
          'User Name': item.fullname,
          'Phone Number': item.phoneNumber,
          'IOS': item?.ios,
          'Android':item?.android,
          Gender: item.gender,
          'Playlist Count': item.playlist_count,
          'Favorite Album': item.album_count,
          'Favorite Song': item.songs_count,
        };
      });
      ExportExcel(temp, 'userReport');
    }
  };

  const handleDownloadCsv = async () => {
    const res = await exportData();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data.map((item) => {
        return {
          fullname: item.fullname,
          phoneNumber: item.phoneNumber,
          ios: item?.ios,
          android:item?.android,
          gender: item.gender,
          playlist_count: item.playlist_count,
          album_count: item.album_count,
          songs_count: item.songs_count,
        };
      });
      setCsv([...temp]);
      csvLink.current.link.click();
    }
  };

  const handleChangeOfSearch = (e) => {
    if (!e?.target?.value) {
      setSearch(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const handleChangeOfRangePicker = (e) => {
    setCurrentPage(1);
    setValueOfRangePicker(e);
    if (e) {
      setStartDate(
        new Date(e[0]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
      setEndDate(
        new Date(e[1]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const searchOnEnter = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const clearAllFilter = () => {
    setCurrentPage(1);
    setPageSize(10);
    setSearch(null);
    setTempSearch(null);
    setSort(null);
    setStartDate(null);
    setEndDate(null);
    setGender(null);
    setValueOfRangePicker(null);
    setCountryCode(null);
  };

  useEffect(() => {
    getUserReport(
      currentPage,
      pageSize,
      search,
      sort,
      startDate,
      endDate,
      gender?.value,
      countryCode?.value
    );
  }, [
    countryCode?.value,
    currentPage,
    endDate,
    gender,
    pageSize,
    search,
    sort,
    startDate,
  ]);

  const columns = [
    {
      title: (column) => tableSortIconToggle('User Name', 'fullname', column),
      dataIndex: 'fullname',
      key: 'fullname',
      sortDirections: ['ascend', 'descend'],
      sorter: userList?.data?.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) =>
        tableSortIconToggle('Mobile Number', 'phoneNumber', column),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: userList?.data?.length > 1 ? true : false,
      width: 170,
    },
    {
      title: (column) => tableSortIconToggle('Gender', 'gender', column),
      dataIndex: 'gender',
      key: 'gender',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: userList?.data?.length > 1 ? true : false,
      width: 120,
    },
    {
      title: 'Andriod',
      dataIndex: 'android',
      key: 'android',
      align: 'center',
      render: (record) => {
        return <Checkbox disabled checked={record}></Checkbox>;
      },
      width: 100,
    },
    {
      title: 'iOS',
      dataIndex: 'ios',
      key: 'ios',
      align: 'center',
      render: (record) => {
        return <Checkbox disabled checked={record}></Checkbox>;
      },
      width: 100,
    },
    {
      title: (column) =>
        tableSortIconToggle('Playlists Count', 'playlist_count', column),
      dataIndex: 'playlist_count',
      key: 'playlist_count',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: userList?.data?.length > 1 ? true : false,
      width: 170,
    },
    {
      title: (column) =>
        tableSortIconToggle('Favourite Album', 'album_count', column),
      dataIndex: 'album_count',
      key: 'album_count',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: userList?.data?.length > 1 ? true : false,
      width: 180,
    },
    {
      title: (column) =>
        tableSortIconToggle('Favourite Songs', 'songs_count', column),
      dataIndex: 'songs_count',
      key: 'songs_count',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: userList?.data?.length > 1 ? true : false,
      width: 190,
    },
  ];

  return (
    <>
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <div className='bg-white box-shadow-3 border-radius-5 px-3 pt-3 pb-1'>
            <Row gutter={20} className='align-items-center'>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <Input
                  placeholder='Start Typing to find User'
                  className='input-control'
                  onChange={(e) => handleChangeOfSearch(e)}
                  onKeyPress={(e) => {
                    if (e?.key === 'Enter' && e.target.value) {
                      searchOnEnter();
                    }
                  }}
                  suffix={
                    <SearchOutlined
                      style={{ color: '#8f9bb3' }}
                      onClick={() => searchOnEnter()}
                    />
                  }
                  value={tempSearch}
                  allowClear
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <SelectDropDown
                  placeholder='Select Country'
                  options={countryList}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setCountryCode(e);
                  }}
                  value={countryCode}
                  isClearable
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <SelectDropDown
                  placeholder='Select Gender'
                  options={genderOption}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setGender(e);
                  }}
                  value={gender}
                  isClearable
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <RangePicker
                  className='input-control w-100'
                  onChange={(e) => handleChangeOfRangePicker(e)}
                  value={valueOfRangePicker}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className='gutter-row'
              >
                <div className='d-flex flex-wrap align-items-center'>
                  <div className='mb-1 mr-2 bg-white font-weight-600'>
                    Export
                  </div>
                  <button
                    onClick={handleDownloadCsv}
                    className='bg-light-gray font-weight-600 border-0 border-radius-3 mb-1 py-8px px-2 mr-1 cursor-pointer'
                  >
                    CSV
                  </button>
                  <CSVLink
                    data={csv}
                    headers={headers}
                    filename={handleFileName('userReport', 'csv')}
                    ref={csvLink}
                    className='text-black font-weight-600'
                  ></CSVLink>
                  <button
                    className='bg-light-gray font-weight-600 border-0 border-radius-3 mb-1 py-8px px-2 mr-1 cursor-pointer'
                    onClick={generateExcel}
                  >
                    Excel
                  </button>
                  <button
                    onClick={() => generatePDF()}
                    className='bg-light-gray font-weight-600 border-0 border-radius-3 mb-1 py-8px px-2 mr-1 cursor-pointer'
                  >
                    Pdf
                  </button>
                  <h3 className='clear-all mt-1' onClick={clearAllFilter}>
                    Clear All
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <div className='fix-table mt-3 box-shadow-3 border-radius-5'>
        <Table
          columns={columns}
          dataSource={userList?.data}
          pagination={false}
          loading={loading}
          rowKey='id'
          onChange={handleSorting}
        />

        <div className='bg-white p-2 width-1120 d-flex justify-content-between align-items-center'>
          <p className='mb-0'>{userList?.total || 0} - Total User</p>
          {userList?.total > 10 && (
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={userList?.total}
            />
          )}
        </div>

        {spinnerValue && <Spinner />}
      </div>
    </>
  );
};

export default UserTab;
