import { methods, serviceMaker } from '../service';

const GET_ADMIN_USERS = (limit, skip, search = null, sort = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`users`, methods.GET, query);
};

const GET_ADMIN_USERS_BY_ID = (id) => serviceMaker(`users/${id}`, methods.GET);

const ADD_ADMIN_USERS = (payload) =>
  serviceMaker(`users`, methods.POST, null, payload);

const DELETE_ADMIN_USERS = (id) => serviceMaker(`users/${id}`, methods.DELETE);

const EDIT_ADMIN_USERS = (id, payload) =>
  serviceMaker(`users/${id}`, methods.PATCH, null, payload);

const GET_ADMIN_ROLES = () => {
  const query = {
    params: {
      $limit: '-1',
    },
  };
  return serviceMaker(`roles`, methods.GET, query);
};

const GET_ADMIN_ROLES_BY_ID = (id) => serviceMaker(`roles/${id}`, methods.GET);

const ADD_ADMIN_ROLES = (payload) =>
  serviceMaker(`roles`, methods.POST, null, payload);

const DELETE_ADMIN_ROLES = (id) => serviceMaker(`roles/${id}`, methods.DELETE);

const EDIT_ADMIN_ROLES = (id, payload) =>
  serviceMaker(`roles/${id}`, methods.PATCH, null, payload);

const GET_PAGE_MODULES = () => {
  const query = {
    params: {
      $limit: '-1',
    },
  };
  return serviceMaker(`page-modules`, methods.GET, query);
};

export const ADMIN_USERS = {
  GET: GET_ADMIN_USERS,
  GET_BY_ID: GET_ADMIN_USERS_BY_ID,
  ADD: ADD_ADMIN_USERS,
  DELETE: DELETE_ADMIN_USERS,
  EDIT: EDIT_ADMIN_USERS,
};
export const ADMIN_ROLES = {
  GET: GET_ADMIN_ROLES,
  GET_BY_ID: GET_ADMIN_ROLES_BY_ID,
  ADD: ADD_ADMIN_ROLES,
  DELETE: DELETE_ADMIN_ROLES,
  EDIT: EDIT_ADMIN_ROLES,
};
export const PAGE_MODULES = {
  GET: GET_PAGE_MODULES,
};
