import { useState, useEffect } from 'react';

import { Form, Input, Upload, Checkbox, InputNumber } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import ButtonCom from '../../../../components/Button/ButtonCom';
import { CATEGORY } from '../../../../service';
import { BASE_URL } from '../../../../utility/constant';

const AddEditCategory = () => {
  const [fileList, setFileList] = useState([]);
  const [mediaItemId, setMediaItemId] = useState(null);

  const { id = null } = useParams();
  const navigate = useNavigate();

  const operationName = id ? 'edit' : 'add';

  const [form] = Form.useForm();

  const addEditCategory = async (values) => {
    delete values?.coverImage;

    const payload = {
      ...values,
      positionNo: parseInt(values?.positionNo) || 0,
      mediaItemId: mediaItemId,
      status: values?.status ? 'active' : 'inactive',
    };
    try {
      let res;
      if (operationName === 'edit') {
        res = await CATEGORY.EDIT_CATEGORY(id, payload);
      }
      if (operationName === 'add') {
        res = await CATEGORY.ADD_CATEGORY(payload);
      }
      if (res.status === 1 || res.status === '1') {
        toast.success(res?.message);
        navigate('/category');
      } else {
        throw res;
      }
    } catch (err) {
      toast.error(err?.message || 'something went wrong !');
    }
  };

  useEffect(() => {
    const getEditData = async () => {
      try {
        const res = await CATEGORY.GET_CATEGORY_BY_ID(id);
        if (res.status === 1) {
          form.setFieldsValue({
            isPodcast: false,
            ...res?.data,
            status: res?.data?.status === 'active' ? true : false,
          });
          if (res?.data?.media_item?.image) {
            setFileList([
              {
                uid: '-1',
                name: 'cover image',
                status: 'done',
                url: res?.data?.media_item?.image,
                thubmUrl: res?.data?.media_item?.image,
              },
            ]);
            setMediaItemId(res?.data?.media_item?.id);
          }
        }
      } catch (err) {
        toast.error(err.message || 'something wen wrong');
      }
    };
    if (operationName === 'edit') {
      getEditData();
    }
  }, [form, id, operationName]);

  const props = {
    name: 'file',
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL}/uploads-image`,
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload() {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn('File Already Uploaded! Please delete that.');
        return false;
      }
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === 'done') {
        setMediaItemId(info.file.response.data.media.id);
      } else if (info.file.status === 'error') {
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove(info) {
      setFileList([]);
      setMediaItemId(null);
    },
  };

  return (
    <>
      <InsideHeader className='add-edit-form'>
        <h1 className='font-size-28'>
          {operationName === 'add' ? 'Create New Category' : 'Edit Category'}
        </h1>
        <Form form={form} name='control-hooks' onFinish={addEditCategory}>
          <Form.Item
            name='categoryName'
            label='Category Name'
            rules={[{ required: true, message: 'please fill category name' }]}
          >
            <Input
              placeholder='Category Name'
              className='input-control'
              allowClear
            />
          </Form.Item>
          <Form.Item name='subtitle' label='Subtitle'>
            <Input
              placeholder='Subtitle'
              className='input-control'
              allowClear
            />
          </Form.Item>
          <Form.Item
            name='positionNo'
            label='Position No'
            extra={id ? '' : 'Leave blank for auto increment.'}
          >
            <InputNumber
              min={0}
              placeholder='Position No.'
              className='input-control-number'
            />
          </Form.Item>
          <Form.Item name='isPodcast' label='IsPodcast' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <Form.Item name='status' label='isVisible' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <Form.Item
            valuePropName='filelist'
            name='coverImage'
            label='CoverImage'
          >
            <Upload accept='.png, .jpg, .jpeg' listType='picture' {...props}>
              <ButtonCom type='primary' icon={<UploadOutlined />}>
                Click to Upload
              </ButtonCom>
            </Upload>
          </Form.Item>

          <div className='form-btn-group'>
            <ButtonCom
              className='width-180 mr-2'
              onClick={() => navigate('/category')}
            >
              Back
            </ButtonCom>
            <ButtonCom type='primary' className='width-180' htmlType='submit'>
              {operationName === 'add' ? 'Create' : 'Update'}
            </ButtonCom>
          </div>
        </Form>
      </InsideHeader>
    </>
  );
};

export default AddEditCategory;
