import { methods, serviceMaker } from '../service';

const GET_ADVERTISE = (limit, skip, search = null, sort = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`ads`, methods.GET, query);
};

const GET_ADVERTISE_BY_ID = (id) => serviceMaker(`ads/${id}`, methods.GET);

const ADD_ADVERTISE = (payload) =>
  serviceMaker(`ads`, methods.POST, null, payload);

const EDIT_ADVERTISE = (id, payload) =>
  serviceMaker(`ads/${id}`, methods.PATCH, null, payload);

const DELETE_ADVERTISE = (id) => serviceMaker(`ads/${id}`, methods.DELETE);

const GET_TAB = () => {
  const query = {
    params: {
      $limit: '-1',
    },
  };
  return serviceMaker(`tabs`, methods.GET, query);
};

const GET_SUB_TAB_SECTIONS = (id) =>
  serviceMaker(`sub-tabs/${id}`, methods.GET);

export const ADVERTISE = {
  GET_ADVERTISE,
  GET_ADVERTISE_BY_ID,
  ADD_ADVERTISE,
  EDIT_ADVERTISE,
  DELETE_ADVERTISE,
  GET_TAB,
  GET_SUB_TAB_SECTIONS,
};
