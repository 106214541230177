import React, { useEffect, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Row, Col, Steps, Form } from 'antd';
import { toast } from 'react-toastify';

import CreateSection from './Steps/CreateSection';
import { SECTION } from '../../../service/index';
import Publish from './Steps/Publish';

const { Step } = Steps;

const AddSection = () => {
  const [current, setCurrent] = useState(0);
  const [singleSection, setSingleSection] = useState({});
  const [tab, setTab] = useState([]);
  const [subTab, setSubTab] = useState([]);

  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const sizeType = [
    { label: 'Small', value: 'small' },
    { label: 'Medium', value: 'medium' },
    { label: 'Large', value: 'large' },
    // { label: 'Extra large', value: 'extra-large' },
    // { label: 'Slider', value: 'slider' },
    { label: 'Small banner', value: 'small-banner' },
    { label: 'Banner', value: 'banner' },
  ];

  const getTab = async () => {
    try {
      const res = await SECTION.TAB();
      if (res.status === 1 || res.status === '1') {
        const data = res?.data?.map((item) => {
          const subTab = item?.sub_tabs?.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          return {
            label: item.name,
            value: item.id,
            subTab,
          };
        });
        setTab(data);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong !');
    }
  };

  const handleBack = () => {
    if (searchParams.get('newTab') === 'true') {
      window.close()
    } else { 
      navigate(-1);
    }
  };

  const onFinish = (value) => {
    localStorage.setItem(
      'section-step-1',
      JSON.stringify({ ...value, date: singleSection?.date })
    );
    setCurrent(1);
  };

  useEffect(() => {
    getTab();
  }, []);

  useEffect(() => {
    const stepOne = JSON.parse(localStorage.getItem('section-step-1')) || '';
    const editValue = async () => {
      try {
        const res = await SECTION.SINGLE_SECTION(Number(id));
        if (res.status === 1 || res.status === '1') {
          setSingleSection(res.data);
          const filter = tab.filter(
            (item) => item.value === res?.data?.sub_tab?.tab?.id
          )[0];
          setSubTab(filter?.subTab);
          form.setFieldsValue({
            ...res?.data,
            tabId: {
              label: res?.data?.sub_tab?.tab?.name,
              value: res?.data?.sub_tab?.tab?.id,
            },
            sizeType: {
              label:
                res?.data?.sizeType.charAt(0).toUpperCase() +
                res?.data?.sizeType.slice(1),
              value: res?.data?.sizeType,
            },
            subTabId: {
              label: res?.data?.sub_tab?.name,
              value: res?.data?.sub_tab?.id,
            },
          });
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong !');
      }
    };
    if (id && tab && stepOne === '') {
      editValue();
    }
  }, [form, id, tab]);

  useEffect(() => {
    const stepOne = JSON.parse(localStorage.getItem('section-step-1')) || '';
    const editValue = async () => {
      try {
        const filter = tab.filter((item) => item.value === stepOne?.tabId)[0];
        setSubTab(filter?.sub_tabs);
        form.setFieldsValue({
          ...stepOne,
        });
      } catch (error) {
        toast.error(error.message || 'Something went wrong !');
      }
    };
    if (stepOne) {
      editValue();
    }
  }, [form, id, tab]);

  useEffect(() => {
    const tabId = Number(searchParams.get('tabId'));
    const subTabId = Number(searchParams.get('subTabId'));
    if (subTabId || tabId) {
      const tempTab = tab?.filter((item) => item?.value === tabId)[0];
      const tempSubTab = tempTab?.subTab?.filter(
        (item) => item?.value === subTabId
      )[0];
      setSubTab(tempTab?.subTab);
      form.setFieldsValue({ tabId: tempTab, subTabId: tempSubTab });
    }
  }, [form, searchParams, tab]);

  return (
    <>
      {/* Step */}
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={20} lg={20} xl={20} className='gutter-row'>
          <Steps current={current}>
            <Step title='Create Section' className='cursor-pointer' />
            <Step title='Publish' className='cursor-pointer' />
          </Steps>
        </Col>
      </Row>

      {/* Create Section */}
      {current === 0 && (
        <CreateSection
          form={form}
          onFinish={onFinish}
          sizeType={sizeType}
          tab={tab}
          setSubTab={setSubTab}
          subTab={subTab}
          handleBack={handleBack}
          id={id}
        />
      )}

      {/*Publish  */}
      {current === 1 && <Publish setCurrent={setCurrent} />}
    </>
  );
};

export default AddSection;
