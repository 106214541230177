import React, { useState } from 'react';

import { Row, Col, Steps } from 'antd';

import CreateAlbum from '../NewAlbum/Steps/CreateAlbum';
import Publish from '../NewAlbum/Steps/AlbumPublish';

const { Step } = Steps;

const NewAlbum = () => {
  const [current, setCurrent] = useState(0);

  return (
    <>
      {/* Step */}
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={20} xl={20} className='gutter-row'>
          <Steps current={current}>
            <Step title='Edit Album' />
            <Step title='Publish' />
          </Steps>
        </Col>
      </Row>

      {/* Create Album */}
      {current === 0 && (
        <CreateAlbum setCurrent={setCurrent} scope='edit-album' />
      )}

      {/* Publish  */}
      {current === 1 && <Publish setCurrent={setCurrent} scope='edit-album' />}
    </>
  );
};

export default NewAlbum;
