import { useEffect } from 'react';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import ButtonCom from '../../components/Button/ButtonCom';

import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Input, Form } from 'antd';

import { ARTIST_ROLE } from '../../service/index';

const AddEditRole = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { id = null } = useParams();
  const operationName = id ? 'edit' : 'add';

  const addEditRole = async ({ name }) => {
    let res;
    try {
      if (operationName === 'edit') {
        res = await ARTIST_ROLE.EDIT(id, { status: 'active', name });
      }
      if (operationName === 'add') {
        res = await ARTIST_ROLE.ADD({ name });
      }
      if (res.status === 1 || res.status === '1') {
        toast.success(res?.message);
        navigate('/artists/role');
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    const getEditData = async () => {
      try {
        const res = await ARTIST_ROLE.GET_BY_ID(id);
        if (res.status === 1) {
          form.setFieldsValue({ ...res?.data });
        }
      } catch (err) {
        toast.error(err.message || 'something wen wrong');
      }
    };
    if (operationName === 'edit') {
      getEditData();
    }
  }, [form, id, operationName]);

  return (
    <InsideHeader style={{ width: '500px' }} className='add-edit-form'>
      <h2>{operationName === 'add' ? 'Add New Role' : 'Edit Role'}</h2>
      <Form form={form} name='control-hooks' onFinish={addEditRole}>
        <Form.Item
          name='name'
          label='Role Name'
          rules={[{ required: true, message: 'please fill Role Name ' }]}
        >
          <Input className='input-control' autoFocus />
        </Form.Item>
        <div className='form-btn-group '>
          <ButtonCom
            className='width-180 mr-2'
            onClick={() => navigate('/artists/role')}
          >
            Back
          </ButtonCom>

          <ButtonCom type='primary' htmlType='submit' className='width-180'>
            {operationName === 'add' ? 'Create' : 'Edit'}
          </ButtonCom>
        </div>
      </Form>
    </InsideHeader>
  );
};

export default AddEditRole;
