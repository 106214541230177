import React from 'react';

import { Form, InputNumber, Modal } from 'antd';

import ButtonCom from '../../../components/Button/ButtonCom';

const PositionUpdateModel = ({
  showUpdateModel,
  form,
  setShowUpdateModel,
  UpdateAlbumSongPosition,
}) => {
  return (
    <>
      <Modal
        centered='true'
        title='Update Position No'
        footer={false}
        visible={showUpdateModel}
        onCancel={() => setShowUpdateModel(false)}
      >
        <Form
          form={form}
          onFinish={UpdateAlbumSongPosition}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
            lg: { span: 16 },
          }}
          labelAlign='left'
          name='control-hooks'
          className='form-style'
        >
          <Form.Item
            name='positionNo'
            label='Position No.'
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Position Number is required !',
              },
            ]}
          >
            <InputNumber
              min={0}
              placeholder='Position No.'
              className='input-control-number'
            />
          </Form.Item>
          <div className='d-flex align-items-center justify-content-center'>
            <ButtonCom
              className='mr-2'
              onClick={() => setShowUpdateModel(false)}
            >
              Cancel
            </ButtonCom>
            <ButtonCom htmlType='submit' type='primary'>
              Update
            </ButtonCom>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default PositionUpdateModel;
