import React from 'react';

import Select from 'react-select';

const SelectDropDown = (props) => {
  return (
    <Select
      menuPlacement='auto'
      maxMenuHeight='200px'
      minMenuHeight='200px'
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#9F52F4',
            primary25: '#faf0ff',
            primary50: '#faf0ff',
          },
        };
      }}
      {...props}
    />
  );
};

export default SelectDropDown;
