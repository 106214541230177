import React from 'react';

const SectionIcon = () => {
  return (
    <>
      <span className='anticon anticon-dashboard ant-menu-item-icon'>
        <svg
          width='20'
          height='20'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M15.5 15.5H20C20.2652 15.5 20.5196 15.3946 20.7071 15.2071C20.8946 15.0196 21 14.7652 21 14.5V4C21 3.73478 20.8946 3.48043 20.7071 3.29289C20.5196 3.10536 20.2652 3 20 3H9.5C9.23478 3 8.98043 3.10536 8.79289 3.29289C8.60536 3.48043 8.5 3.73478 8.5 4V8.5'
            stroke='#656565'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M8.5 8.5H4C3.73478 8.5 3.48043 8.60536 3.29289 8.79289C3.10536 8.98043 3 9.23478 3 9.5V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H14.5C14.7652 21 15.0196 20.8946 15.2071 20.7071C15.3946 20.5196 15.5 20.2652 15.5 20V15.5'
            stroke='#656565'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M14.5 8.5H9.5C8.94772 8.5 8.5 8.94772 8.5 9.5V14.5C8.5 15.0523 8.94772 15.5 9.5 15.5H14.5C15.0523 15.5 15.5 15.0523 15.5 14.5V9.5C15.5 8.94772 15.0523 8.5 14.5 8.5Z'
            stroke='#656565'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
        </svg>
      </span>
    </>
  );
};

export default SectionIcon;
