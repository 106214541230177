import React from 'react';

const Album = () => {
  return (
    <>
      <span className='anticon anticon-dashboard ant-menu-item-icon'>
        <svg
          width='20px'
          height='20px'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M19.955 4.04506C17.8452 1.93527 14.9837 0.75 12 0.75C9.01633 0.75 6.15485 1.93527 4.04506 4.04506C1.93527 6.15484 0.75 9.01633 0.75 12C0.75 14.9837 1.93527 17.8452 4.04506 19.955C6.15485 22.0648 9.01633 23.25 12 23.25C14.9837 23.25 17.8452 22.0648 19.955 19.955C22.0648 17.8452 23.25 14.9837 23.25 12C23.25 9.01633 22.0648 6.15484 19.955 4.04506ZM12 21.75C6.62388 21.75 2.25002 17.3762 2.25002 12C2.25002 6.62388 6.62388 2.25002 12 2.25002C17.3762 2.25002 21.75 6.62388 21.75 12C21.75 17.3762 17.3762 21.75 12 21.75Z'
            fill='black'
          ></path>
          <path
            d='M12 7.125C11.0358 7.125 10.0933 7.41091 9.2916 7.94659C8.48991 8.48226 7.86507 9.24363 7.49609 10.1344C7.12711 11.0252 7.03057 12.0054 7.21867 12.9511C7.40678 13.8967 7.87108 14.7654 8.55286 15.4471C9.23464 16.1289 10.1033 16.5932 11.0489 16.7813C11.9946 16.9694 12.9748 16.8729 13.8656 16.5039C14.7564 16.1349 15.5177 15.5101 16.0534 14.7084C16.5891 13.9067 16.875 12.9642 16.875 12C16.8735 10.7075 16.3595 9.46839 15.4455 8.55447C14.5316 7.64055 13.2925 7.12646 12 7.125ZM12 15.375C11.3325 15.375 10.68 15.1771 10.125 14.8062C9.56994 14.4354 9.13735 13.9083 8.88191 13.2916C8.62646 12.6749 8.55963 11.9963 8.68985 11.3416C8.82008 10.6869 9.14152 10.0855 9.61352 9.61352C10.0855 9.14151 10.6869 8.82007 11.3416 8.68985C11.9963 8.55962 12.6749 8.62646 13.2916 8.88191C13.9083 9.13735 14.4354 9.56994 14.8062 10.125C15.1771 10.68 15.375 11.3325 15.375 12C15.374 12.8948 15.0181 13.7527 14.3854 14.3854C13.7527 15.0181 12.8948 15.374 12 15.375Z'
            fill='black'
          ></path>
          <path d='M11.25 11.25H12.75V12.75H11.25V11.25Z' fill='black'></path>
          <path
            d='M11.9999 5.25004V3.75004C10.6966 3.74614 9.41122 4.05439 8.2514 4.64901C7.13165 5.22335 6.15671 6.04411 5.3999 7.04957L6.59925 7.95046C7.22526 7.10904 8.04007 6.42634 8.9781 5.95732C9.91612 5.48829 10.9512 5.24603 11.9999 5.25004Z'
            fill='black'
          ></path>
        </svg>
      </span>
    </>
  );
};

export default Album;
