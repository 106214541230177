import { Routes, Route, Navigate } from 'react-router-dom';

import AdminLayout from '../components/layout/AdminLayout';

import Dashboard from '../views/Dashboard/Dashboard';

import Albums from '../views/Albums/Albums';
import NewAlbum from '../views/Albums/NewAlbum/NewAlbum';
import SingleAlbumSongs from '../views/Albums/SingleAlbumSong/SingleAlbumSongs';
import ReorderAlbumSong from '../views/Albums/SingleAlbumSong/ReorderAlbumSong';
import AddSongToAlbum from '../views/Song/AddSongToAlbum/AddSongToAlbum';
import EditAlbum from '../views/Albums/EditAlbum/EditAlbum.jsx';

import SearchSongs from '../views/Song/SearchSongs/SearchSongs';
import Songs from '../views/Song/Songs';
import AddSongs from '../views/Song/AddSongs/AddSongs';
import EditSong from '../views/Song/EditSong/EditSong';

import Artists from '../views/Artists/Artists';
import AddEditArtists from '../views/Artists/AddEditArtists';
import ArtistRole from '../views/Artists/ArtistRole';
import AddEditRole from '../views/Artists/AddEditRole';

import Category from '../views/Category/Category/Category';
import AddEditCategory from '../views/Category/Category/AddEditCategory/AddEditCategory';
import SubCategory from '../views/Category/SubCategory/SubCategory';
import AddEditSubCategory from '../views/Category/SubCategory/AddEditSubCategory/AddEditSubCategory';
import AlbumCategory from '../views/Category/AlbumCategory/AlbumCategory';
import AddEditAlbumCategory from '../views/Category/AlbumCategory/AddEditAlbumCategory/AddEditAlbumCategory';
import AlbumSubCategory from '../views/Category/AlbumSubCategory/AlbumSubCategory';
import AddEditAlbumSubCategory from '../views/Category/AlbumSubCategory/AddEditAlbumSubCategory/AddEditAlbumSubCategory';

import AppUsers from '../views/AppUsers/AppUsers';

import AdminUsers from '../views/AdminUsers/AdminUsers';
import AddEditAdminUser from '../views/AdminUsers/AddEditAdminUser';
import AdminRoles from '../views/AdminUsers/AdminRoles';
import AddEditAdminRoles from '../views/AdminUsers/AddEditAdminRoles';

import Advertise from '../views/Advertise/Advertise';
import AddEditAdvertise from '../views/Advertise/AddEditAdvertise/AddEditAdvertise';

import Sections from '../views/Sections/Sections';
import AddSection from '../views/Sections/AddEditSection/AddEditSection';
import Manage from '../views/Sections/Manage/Manage';
import AddEditSectionItem from '../views/Sections/SectionItem/AddEditSectionItem/AddEditSectionItem';
import ShowItem from '../views/Sections/SectionItem/SectionItem';

import Reports from '../views/Reports/Reports';

import Notifications from '../views/Notifications/Notifications';
import AddEditNotification from '../views/Notifications/AddEditNotification/AddEditNotification';

import Login from '../views/Login/Login';

import Profile from '../views/Profile/Profile';
import ChangePassword from '../views/Profile/ChangePassword';

import { useSelector } from 'react-redux';

const Routing = () => {
  const { userValue, token } = useSelector((state) => state.user);
  const routing = [];

  const roleRouting = () => {
    if (userValue?.role?.isAdmin) {
      routing.push(
        { url: '/dashboard', components: <Dashboard /> },
        { url: '/albums', components: <Albums /> },
        { url: '/albums/add', components: <NewAlbum /> },
        {
          url: '/albums/single/:id',
          components: <SingleAlbumSongs />,
        },
        {
          url: '/albums/reorder/:id',
          components: <ReorderAlbumSong />,
        },
        {
          url: '/albums/edit/:id',
          components: <EditAlbum />,
        },
        { url: '/songs', components: <Songs /> },
        { url: '/songs/add', components: <AddSongs /> },
        { url: '/songs/search', components: <SearchSongs /> },
        {
          url: '/songs/add-album',
          components: <AddSongToAlbum />,
        },
        {
          url: '/songs/edit/:id',
          components: <EditSong />,
        },
        { url: '/artists', components: <Artists /> },
        { url: '/artists/add', components: <AddEditArtists /> },
        {
          url: '/artists/edit/:id',
          components: <AddEditArtists />,
        },
        {
          url: '/artists/role',
          components: <ArtistRole />,
        },
        {
          url: '/artists/role/add',
          components: <AddEditRole />,
        },
        {
          url: '/artists/role/edit/:id',
          components: <AddEditRole />,
        },
        { url: '/category', components: <Category /> },
        {
          url: '/category/add/',
          components: <AddEditCategory />,
        },
        {
          url: '/category/edit/:id',
          components: <AddEditCategory />,
        },
        {
          url: '/category/album',
          components: <AlbumCategory />,
        },
        {
          url: '/category/album/add',
          components: <AddEditAlbumCategory />,
        },
        {
          url: '/category/album/edit/:id',
          components: <AddEditAlbumCategory />,
        },
        {
          url: '/category/sub-category',
          components: <SubCategory />,
        },
        {
          url: '/category/sub-category/add',
          components: <AddEditSubCategory />,
        },
        {
          url: '/category/sub-category/edit/:id',
          components: <AddEditSubCategory />,
        },
        {
          url: '/category/sub-category/album',
          components: <AlbumSubCategory />,
        },
        {
          url: '/category/sub-category/album/add',
          components: <AddEditAlbumSubCategory />,
        },
        {
          url: '/category/sub-category/album/edit/:id',
          components: <AddEditAlbumSubCategory />,
        },
        {
          url: '/app-users',
          components: <AppUsers />,
        },
        {
          url: '/admin-users',
          components: <AdminUsers />,
        },
        {
          url: '/admin-users/add',
          components: <AddEditAdminUser />,
        },
        {
          url: '/admin-users/edit/:id',
          components: <AddEditAdminUser />,
        },
        {
          url: '/admin-users/roles',
          components: <AdminRoles />,
        },
        {
          url: '/admin-users/roles/add',
          components: <AddEditAdminRoles />,
        },
        {
          url: '/admin-users/roles/edit/:id',
          components: <AddEditAdminRoles />,
        },
        {
          url: '/advertise',
          components: <Advertise />,
        },
        {
          url: '/advertise/add',
          components: <AddEditAdvertise />,
        },
        {
          url: '/advertise/edit/:id',
          components: <AddEditAdvertise />,
        },
        {
          url: '/sections',
          components: <Sections />,
        },
        {
          url: '/sections/add',
          components: <AddSection />,
        },
        {
          url: '/sections/edit/:id',
          components: <AddSection />,
        },
        {
          url: '/sections/manage/:id',
          components: <Manage />,
        },
        {
          url: '/sections/manage/add/:add',
          components: <AddEditSectionItem />,
        },
        {
          url: '/sections/manage/edit/:id',
          components: <AddEditSectionItem />,
        },
        {
          url: '/sections/item/:id',
          components: <ShowItem />,
        },
        {
          url: '/reports',
          components: <Reports />,
        },
        {
          url: '/notifications',
          components: <Notifications />,
        },
        {
          url: '/notifications/add',
          components: <AddEditNotification />,
        },
        {
          url: '/notifications/edit/:id',
          components: <AddEditNotification />,
        },
        {
          url: '/notifications/clone/:id',
          components: <AddEditNotification />,
        },
        {
          url: '/profile',
          components: <Profile />,
        },
        {
          url: '/change-password',
          components: <ChangePassword />,
        }
      );
    } else {
      userValue?.role?.permissions?.forEach((item) => {
        if (item.page.value === 'dashboard') {
          routing.push({
            url: '/dashboard',
            components: <Dashboard permissions={item} />,
          });
        }
        if (item.page.value === 'albums') {
          routing.push(
            { url: '/albums', components: <Albums permissions={item} /> },
            {
              url: '/albums/single/:id',
              components: <SingleAlbumSongs permissions={item} />,
            }
          );
          if (item.isCreate) {
            routing.push({ url: '/albums/add', components: <NewAlbum /> });
          }
          if (item.isUpdate) {
            routing.push(
              {
                url: '/albums/edit/:id',
                components: <EditAlbum />,
              },
              {
                url: '/albums/reorder/:id',
                components: <ReorderAlbumSong permissions={item} />,
              }
            );
          }
        }
        if (item.page.value === 'songs') {
          routing.push(
            { url: '/songs', components: <Songs permissions={item} /> },
            {
              url: '/songs/search',
              components: <SearchSongs permissions={item} />,
            }
          );
          if (item.isCreate) {
            routing.push(
              { url: '/songs/add', components: <AddSongs /> },
              {
                url: '/songs/add-album',
                components: <AddSongToAlbum />,
              }
            );
          }
          if (item.isUpdate) {
            routing.push({
              url: '/songs/edit/:id',
              components: <EditSong />,
            });
          }
        }
        if (item.page.value === 'artists') {
          routing.push(
            { url: '/artists', components: <Artists permissions={item} /> },
            {
              url: '/artists/role',
              components: <ArtistRole permissions={item} />,
            }
          );
          if (item.isCreate) {
            routing.push(
              {
                url: '/artists/add',
                components: <AddEditArtists />,
              },
              {
                url: '/artists/role/add',
                components: <AddEditRole />,
              }
            );
          }

          if (item.isUpdate) {
            routing.push(
              {
                url: '/artists/edit/:id',
                components: <AddEditArtists />,
              },

              {
                url: '/artists/role/edit/:id',
                components: <AddEditRole />,
              }
            );
          }
        }
        if (item.page.value === 'category') {
          routing.push(
            { url: '/category', components: <Category permissions={item} /> },
            {
              url: '/category/sub-category',
              components: <SubCategory permissions={item} />,
            },
            {
              url: '/category/album',
              components: <AlbumCategory permissions={item} />,
            },
            {
              url: '/category/sub-category/album',
              components: <AlbumSubCategory permissions={item} />,
            }
          );
          if (item.isCreate) {
            routing.push(
              {
                url: '/category/add',
                components: <AddEditCategory />,
              },
              {
                url: '/category/album/add',
                components: <AddEditAlbumCategory />,
              },
              {
                url: '/category/sub-category/add',
                components: <AddEditSubCategory />,
              },
              {
                url: '/category/sub-category/album/add',
                components: <AddEditAlbumSubCategory />,
              }
            );
          }

          if (item.isUpdate) {
            routing.push(
              {
                url: '/category/edit/:id',
                components: <AddEditCategory />,
              },
              {
                url: '/category/album/edit/:id',
                components: <AddEditAlbumCategory />,
              },
              {
                url: '/category/sub-category/edit/:id',
                components: <AddEditSubCategory />,
              },
              {
                url: '/category/sub-category/album/edit/:id',
                components: <AddEditAlbumSubCategory />,
              }
            );
          }
        }
        if (item.page.value === 'app_users') {
          routing.push({
            url: '/app-users',
            components: <AppUsers permissions={item} />,
          });
        }
        if (item.page.value === 'admin_users') {
          routing.push(
            {
              url: '/admin-users',
              components: <AdminUsers permissions={item} />,
            },
            {
              url: '/admin-users/roles',
              components: <AdminRoles permissions={item} />,
            }
          );
          if (item.isCreate) {
            routing.push(
              {
                url: '/admin-users/add',
                components: <AddEditAdminUser />,
              },
              {
                url: '/admin-users/roles/add',
                components: <AddEditAdminRoles />,
              }
            );
          }

          if (item.isUpdate) {
            routing.push(
              {
                url: '/admin-users/edit/:id',
                components: <AddEditAdminUser />,
              },
              {
                url: '/admin-users/roles/edit/:id',
                components: <AddEditAdminRoles />,
              }
            );
          }
        }
        if (item.page.value === 'advertise') {
          routing.push({
            url: '/advertise',
            components: <Advertise permissions={item} />,
          });
          if (item.isCreate) {
            routing.push({
              url: '/advertise/add',
              components: <AddEditAdvertise />,
            });
          }

          if (item.isUpdate) {
            routing.push({
              url: '/advertise/edit/:id',
              components: <AddEditAdvertise />,
            });
          }
        }
        if (item.page.value === 'sections') {
          routing.push(
            {
              url: '/sections',
              components: <Sections permissions={item} />,
            },
            {
              url: '/sections/manage/:id',
              components: <Manage permissions={item} />,
            },
            {
              url: '/sections/item/:id',
              components: <ShowItem permissions={item} />,
            }
          );
          if (item.isCreate) {
            routing.push(
              {
                url: '/sections/add',
                components: <AddSection />,
              },
              {
                url: '/sections/manage/add/:add',
                components: <AddEditSectionItem />,
              }
            );
          }

          if (item.isUpdate) {
            routing.push(
              {
                url: '/sections/edit/:id',
                components: <AddSection />,
              },
              {
                url: '/sections/manage/edit/:id',
                components: <AddEditSectionItem />,
              }
            );
          }
        }
        if (item.page.value === 'reports') {
          routing.push({
            url: 'reports',
            components: <Reports permissions={item} />,
          });
        }
        if (item.page.value === 'notifications') {
          routing.push({
            url: '/notifications',
            components: <Notifications permissions={item} />,
          });
          if (item.isCreate) {
            routing.push(
              {
                url: '/notifications/add',
                components: <AddEditNotification />,
              },
              {
                url: '/notifications/clone/:id',
                components: <AddEditNotification />,
              }
            );
          }
          if (item.isUpdate) {
            routing.push({
              url: '/notifications/edit/:id',
              components: <AddEditNotification />,
            });
          }
        }
        routing.push(
          {
            url: '/profile',
            components: <Profile />,
          },
          {
            url: '/change-password',
            components: <ChangePassword />,
          }
        );
      });
    }
  };

  roleRouting();

  return (
    <Routes>
      <Route element={<AdminLayout />}>
        {routing.map((item, i) => {
          return <Route path={item.url} element={item.components} key={i} />;
        })}
      </Route>
      {!token && <Route path='/login' element={<Login />}></Route>}
      <Route
        path='*'
        element={<Navigate to={token ? `${routing[0]?.url}` : '/login'} />}
      ></Route>
    </Routes>
  );
};

export default Routing;
