import React, { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Menu, Dropdown } from 'antd';
import { toast } from 'react-toastify';

import { LOGOUT } from '../../redux/features/userSlice';
import LogOutModal from '../Modal/LogOutModal';
import PersonIcon from '../Icon/PersonIcon';
import LogOutIcon from '../Icon/LogOutIcon';
import JalsoLogo from '../Icon/JalsoLogo';
import KeyIcon from '../Icon/KeyIcon';

const Header = () => {
  const [userDetails, setUserDetails] = useState({
    fullname: '',
    username: '',
  });
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  const { userValue } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogOut = async () => {
    dispatch(LOGOUT());
    toast.success('Logout Successfully !');
    navigate('/login');
  };

  const routingPath = (path) => {
    if (path) {
      if (path === 'app_users') {
        return '/app-users';
      } else if (path === 'admin_users') {
        return '/admin-users';
      } else {
        return `/${path}`;
      }
    } else {
      return '/dashboard';
    }
  };

  const getFirstLetters = (param) => {
    const firstLetters = param
      .split(' ')
      .map((word) => word[0])
      .join('');
    return firstLetters;
  };

  const matchPathnameWithKey = () => {
    const splitPathname = location.pathname.split('/');
    const actualPathname = splitPathname[1];
    if (actualPathname === '') {
      return ['dashboard'];
    }
    const returnVal = [actualPathname];
    return returnVal;
  };

  useEffect(() => {
    if (userValue && userValue !== undefined) {
      setUserDetails(userValue);
    }
  }, [userValue]);

  return (
    <>
      <div className='header d-flex align-items-center justify-content-between px-2'>
        <div className='d-flex align-items-center'>
          <Link to={routingPath(userValue?.role?.permissions[0]?.page?.value)}>
            <JalsoLogo width='90px' height='45px' />
          </Link>
        </div>
        <div className='d-flex align-items-center'>
          <div>
            <Dropdown
              arrow={{
                pointAtCenter: true,
              }}
              placement='bottom'
              trigger={['click']}
              overlay={
                <Menu
                  theme='light'
                  mode='vertical'
                  selectedKeys={matchPathnameWithKey()}
                >
                  <Menu.Item key='profile' onClick={() => navigate('profile')}>
                    <div className='d-flex align-items-center'>
                      <PersonIcon />
                      <span className='ml-1 mb-0 font-size-15 font-weight-500'>
                        Profile
                      </span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    key='change-password'
                    onClick={() => navigate('change-password')}
                  >
                    <div className='d-flex align-items-center'>
                      <KeyIcon />
                      <span className='ml-1 mb-0 font-size-15 font-weight-500'>
                        Change Password
                      </span>
                    </div>
                  </Menu.Item>
                  <Menu.Item key='1' onClick={() => setVisible(true)}>
                    <div className='d-flex align-items-center'>
                      <LogOutIcon />
                      <span className='font-size-15 mb-0 ml-1 font-weight-500'>
                        Logout
                      </span>
                    </div>
                  </Menu.Item>
                </Menu>
              }
            >
              <div
                className='d-flex cursor-pointer'
                onClick={(e) => e.preventDefault()}
              >
                <Avatar
                  className='border-gray-1'
                  size={40}
                  style={{
                    color: '#000',
                    backgroundColor: '#fff',
                    marginRight: '9px',
                  }}
                >
                  {getFirstLetters(userDetails.fullname.toUpperCase())}
                </Avatar>
                <div>
                  <h4 className='mb-0 font-size-15'>{userDetails.fullname}</h4>
                  <p className='mb-0 font-size-13 line-height-6'>
                    {userDetails.username}
                  </p>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* LogOut Modal */}
      <LogOutModal
        visible={visible}
        setVisible={setVisible}
        handleLogOut={handleLogOut}
      />
    </>
  );
};

export default Header;
