import { methods, serviceMaker } from "../service";

const TAB = () => serviceMaker(`tabs`, methods.GET);

const SINGLE_TAB = (id) => serviceMaker(`tabs/${id}`, methods.GET);

const SINGLE_SUB_TAB = (id) => serviceMaker(`sub-tabs/${id}`, methods.GET);

const POST_SECTION = (payload) =>
  serviceMaker(`section`, methods.POST, null, payload);

const GET_SECTION = (limit, skip, search) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query["params"]["search"] = search;
  }
  return serviceMaker(`section`, methods.GET, query);
};

const GET_SINGLE_SECTION = (id) => serviceMaker(`section/${id}`, methods.GET);

const REMOVE_SECTION = (id) => serviceMaker(`section/${id}`, methods.DELETE);

const SINGLE_SECTION = (id) => serviceMaker(`section/${id}`, methods.GET);

const PATCH_SECTION = (id, payload) =>
  serviceMaker(`section/${id}`, methods.PATCH, null, payload);

const POST_SECTION_ITEM = (payload) =>
  serviceMaker(`section-item`, methods.POST, null, payload);

const SYNC_JSON_MAPPING = (payload) =>
  serviceMaker(`json-mapping/sync`, methods.POST, null, payload);

const REMOVE_SECTION_ITEM = (id) =>
  serviceMaker(`section-item/${id}`, methods.DELETE);

const PATCH_SECTION_ITEM = (id, payload) =>
  serviceMaker(`section-item/${id}`, methods.PATCH, null, payload);

const MULTIPLE_PATCH_SECTION_ITEM = (payload) =>
  serviceMaker(`section-item/`, methods.PATCH, null, payload);

const SINGLE_SECTION_ITEM = (id) =>
  serviceMaker(`section-item/${id}`, methods.GET);

const QUERY_SECTION_ITEM = (id, limit, skip, search, sort) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
      sectionId: id,
    },
  };
  if (search) {
    query["params"]["search"] = search;
  }
  if (sort) {
    query["params"][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query["params"]["$sort[positionNo]"] = -1;
  }
  return serviceMaker(`section-item`, methods.GET, query);
};

const GET_ALBUMS = (limit, skip, search) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query["params"]["search"] = search;
  }
  return serviceMaker(`albums`, methods.GET, query);
};

const GET_CATEGORY = (limit, skip, search) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query["params"]["search"] = search;
  }
  return serviceMaker(`category`, methods.GET, query);
};

const GET_SINGLE_CATEGORY = (id) => serviceMaker(`category/${id}`, methods.GET);

const GET_SUB_CATEGORY = (limit, skip, search) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query["params"]["search"] = search;
  }
  return serviceMaker(`subcategory`, methods.GET, query);
};

const GET_SINGLE_SUB_CATEGORY = (id) =>
  serviceMaker(`subcategory/${id}`, methods.GET);

const GET_SONG = (limit, skip, search) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query["params"]["search"] = search;
  }
  return serviceMaker(`song`, methods.GET, query);
};

export const GET_SINGLE_SONG = (id) => serviceMaker(`song/${id}`, methods.GET);

export const SECTION = {
  GET_SECTION,
  GET_ALBUMS,
  GET_CATEGORY,
  GET_SONG,
  GET_SUB_CATEGORY,
  POST_SECTION_ITEM,
  TAB,
  PATCH_SECTION_ITEM,
  MULTIPLE_PATCH_SECTION_ITEM,
  SINGLE_SECTION_ITEM,
  SINGLE_SECTION,
  GET_SINGLE_CATEGORY,
  GET_SINGLE_SONG,
  GET_SINGLE_SUB_CATEGORY,
  GET_SINGLE_SECTION,
  SINGLE_SUB_TAB,
  SINGLE_TAB,
  QUERY_SECTION_ITEM,
  REMOVE_SECTION_ITEM,
  POST_SECTION,
  PATCH_SECTION,
  REMOVE_SECTION,
  SYNC_JSON_MAPPING,
};
