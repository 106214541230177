import { Modal } from 'antd';

const DeleteModel = ({
  isDeleteModalVisible,
  deleteModel,
  setIsDeleteModalVisible,
  recordId,
  info,
}) => {
  return (
    <>
      {/* Delete Model */}
      <Modal
        centered='true'
        okText='Yes'
        cancelText='No'
        style={{ textAlign: 'center' }}
        title='Delete'
        visible={isDeleteModalVisible}
        onOk={() => deleteModel(recordId)}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        <p>{info ? info : 'Are you sure you want to delete this?'}</p>
      </Modal>
    </>
  );
};

export default DeleteModel;
