import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  SwapOutlined,
} from "@ant-design/icons";

const tableSortIconToggle = (title, key, column) => {
  if (column?.sortColumn?.key === key) {
    return (
      <span>
        {title}
        {column.sortOrder === undefined ? (
          <SwapOutlined
            className="ml-1"
            style={{ transform: "rotate(90deg)" }}
          />
        ) : column.sortOrder === "ascend" ? (
          <SortAscendingOutlined className="ml-1" />
        ) : (
          <SortDescendingOutlined className="ml-1" />
        )}
      </span>
    );
  } else {
    return (
      <span>
        {title}
        <SwapOutlined className="ml-1" style={{ transform: "rotate(90deg)" }} />
      </span>
    );
  }
};

const tableSortIconToggleDefault = (title, key, column) => {
  let html;
  for (let i = 0; i <= column?.sortColumns?.length; i++) {
    if (column?.sortColumns[i]?.column?.key === key) {
      html = (
        <span>
          {title}
          {column?.sortColumns[i]?.column.sortOrder === undefined ? (
            <SwapOutlined
              className='ml-1'
              style={{ transform: 'rotate(90deg)' }}
            />
          ) : column?.sortColumns[i]?.column?.sortOrder === 'ascend' ? (
            <SortAscendingOutlined className='ml-1' />
          ) : (
            <SortDescendingOutlined className='ml-1' />
          )}
        </span>
      );
      break;
    } else {
      html = (
        <span>
          {title}
          <SwapOutlined
            className='ml-1'
            style={{ transform: 'rotate(90deg)' }}
          />
        </span>
      );
    }
  }
  return html;
};
export { tableSortIconToggle, tableSortIconToggleDefault };
