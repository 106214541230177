import React from 'react';
import { Spin } from "antd";

const Spinner = () => {
    return (
        <div className="spinner-backdrop">
          <Spin />
        </div>
      );
}

export default Spinner;