import React from "react";
import { Pagination } from "antd";

const PaginationCom = (props) => {
  // pagination
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <h2 className="text-black font-size-15">Previous</h2>;
    }
    if (type === "next") {
      return <h2 className="text-black font-size-15">Next</h2>;
    }
    return originalElement;
  }
  return (
    <>
      <Pagination {...props} itemRender={itemRender} />
    </>
  );
};

export default PaginationCom;
