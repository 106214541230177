import React from 'react';

import Chart from 'react-google-charts';

import EmptyScreen from '../EmptyScreen';

const UserDownload = ({ optionForUserDownload, data, title }) => {
  return (
    <>
      <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
        <h2 className='font-weight-700 mb-3'>{title}</h2>
        <div
          className={
            data?.length > 0
              ? 'user-download'
              : 'user-download d-flex align-items-center justify-content-center'
          }
        >
          {data.length > 0 ? (
            <Chart
              chartType='Line'
              width='100%'
              height='400px'
              data={data}
              options={optionForUserDownload}
            />
          ) : (
            <EmptyScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default UserDownload;
