import { methods, serviceMaker } from '../service';

const GET_ALBUM_CATEGORY = (limit, skip, sort, categoryId, search) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (categoryId) {
    query['params']['categoryId'] = categoryId;
  }
  if (search) {
    query['params']['search'] = search;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`category-albums`, methods.GET, query);
};

const DELETE_ALBUM_CATEGORY = (id) =>
  serviceMaker(`category-albums/${id}`, methods.DELETE);

const CREATE_ALBUM_CATEGORY = (payload) =>
  serviceMaker(`category-albums`, methods.POST, null, payload);

const UPDATE_ALBUM_CATEGORY = (id, payload) =>
  serviceMaker(`category-albums/${id}`, methods.PATCH, null, payload);

const SINGLE_ALBUM_CATEGORY = (id) =>
  serviceMaker(`category-albums/${id}`, methods.GET);

const GET_CATEGORY = (limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`category`, methods.GET, query);
};

const SINGLE_CATEGORY = (id) => serviceMaker(`category/${id}`, methods.GET);

const GET_ALBUMS = (limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`albums`, methods.GET, query);
};

const SINGLE_ALBUM = (id) => serviceMaker(`albums/${id}`, methods.GET);

export const ALBUM_CATEGORY = {
  GET_ALBUM_CATEGORY,
  DELETE_ALBUM_CATEGORY,
  CREATE_ALBUM_CATEGORY,
  SINGLE_ALBUM_CATEGORY,
  GET_CATEGORY,
  GET_ALBUMS,
  SINGLE_CATEGORY,
  SINGLE_ALBUM,
  UPDATE_ALBUM_CATEGORY,
};
