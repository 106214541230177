import React from 'react';

import { Col } from 'antd';

import EmptyScreen from '../EmptyScreen';

const TrendingMonthItem = ({ data, name }) => {
  return (
    <Col xs={24} sm={24} md={8} lg={8} xl={8} className=' mt-2 gutter-row'>
      <div
        className={
          data?.length > 0
            ? 'border-radius-10 border-gray-1 p-2 trending-this-months'
            : 'border-radius-10 border-gray-1 p-2 trending-this-months'
        }
      >
        <h3 className='font-weight-700'>{name}</h3>
        {data?.length > 0 ? (
          <ul className='pl-2'>
            {data?.map((item) => {
              return (
                <li
                  key={item.id}
                  className='list-style-auto font-weight-500 mb-1'
                >
                  {item?.songTitle || item?.albumName || item?.name}
                </li>
              );
            })}
          </ul>
        ) : (
          <div>
            <EmptyScreen />
          </div>
        )}
      </div>
    </Col>
  );
};

export default TrendingMonthItem;
