import React from 'react';

const NotificationsIcon = () => {
  return (
    <>
      <span className='anticon anticon-dashboard ant-menu-item-icon'>
        <svg
          width='20'
          height='20'
          viewBox='0 0 25 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M18.6616 8.17383C18.6616 6.58253 18.0295 5.05641 16.9043 3.93119C15.779 2.80597 14.2529 2.17383 12.6616 2.17383C11.0703 2.17383 9.5442 2.80597 8.41898 3.93119C7.29376 5.05641 6.66162 6.58253 6.66162 8.17383C6.66162 15.1738 3.66162 17.1738 3.66162 17.1738H21.6616C21.6616 17.1738 18.6616 15.1738 18.6616 8.17383Z'
            stroke='#656565'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M14.3916 21.1738C14.2158 21.4769 13.9635 21.7285 13.6599 21.9034C13.3562 22.0782 13.012 22.1703 12.6616 22.1703C12.3113 22.1703 11.967 22.0782 11.6634 21.9034C11.3598 21.7285 11.1075 21.4769 10.9316 21.1738'
            stroke='#656565'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
        </svg>
      </span>
    </>
  );
};

export default NotificationsIcon;
