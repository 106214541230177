import React from 'react';

import { Col, Form, Input, Row } from 'antd';

import ButtonCom from '../../components/Button/ButtonCom';
import InsideHeader from '../../components/InsideHeader/InsideHeader';
import { toast } from 'react-toastify';
import { ADMIN_USERS } from '../../service';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem('user'));

  const onFinish = async (value) => {
    if (value?.newPassword !== value?.conformPassword) {
      return toast.error('Conform password is not match');
    }

    try {
      const res = await ADMIN_USERS.EDIT(userData?.id, {
        oldPassword: value?.oldPassword,
        password: value?.newPassword,
      });
      if (res?.status === 1 || res?.status === '1') {
        toast.success('Password update successfully !');
        navigate('/profile');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong!');
    }
  };

  return (
    <>
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
          md: { span: 8 },
          lg: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
          md: { span: 16 },
          lg: { span: 16 },
        }}
        labelAlign='left'
        name='control-hooks'
        className='form-style'
        form={form}
        onFinish={onFinish}
      >
        <Row justify='center' gutter={0} className='mt-4'>
          <Col xs={24} sm={24} md={20} lg={13} xl={13} className='gutter-row'>
            <InsideHeader>
              <div className='d-flex justify-content-between'>
                <h1 className='font-size-28'>Change Password</h1>
              </div>
              <Form.Item
                name='oldPassword'
                label='Old Password'
                rules={[
                  { required: true, message: 'Old password is required' },
                ]}
              >
                <Input.Password
                  className='input-control'
                  placeholder='Old Password'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name='newPassword'
                label='New Password'
                rules={[
                  { required: true, message: 'New password is required' },
                ]}
              >
                <Input.Password
                  className='input-control'
                  placeholder='New Password'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name='conformPassword'
                label='Conform Password'
                rules={[
                  { required: true, message: 'Conform password is required' },
                ]}
              >
                <Input.Password
                  className='input-control'
                  placeholder='Conform Password'
                  allowClear
                />
              </Form.Item>
              <div className='form-btn-group'>
                <ButtonCom
                  className='mr-2 width-180'
                  onClick={() => navigate('/profile')}
                >
                  Back
                </ButtonCom>
                <ButtonCom
                  htmlType='submit'
                  type='primary'
                  className='width-180'
                >
                  Change
                </ButtonCom>
              </div>
            </InsideHeader>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ChangePassword;
