import { useEffect, useState } from 'react';

import { Input, Upload, Checkbox, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import ButtonCom from '../../../../components/Button/ButtonCom';
import NotificationImg from '../../../../../src/assets/image/notification-mobile.png';
import JalsoLogo from '../../../../../src/assets/image/favicon.png';
import CardTitle from '../../../../../src/assets/image/notification-card-title.svg';
import { BASE_URL } from '../../../../utility/constant';

const EnterData = ({ onNext, onBack }) => {
  const [fileList, setFileList] = useState([]);
  const [mediaItemId, setMediaItemId] = useState(null);
  const [formValue, setFormValue] = useState({});
  const [formAllValue, setFormAllValue] = useState(null);
  const [form] = Form.useForm();

  const addEditNotification = (value) => {
    if (value?.platform) {
      if (value.platform.length === 2) {
        value.platformAndroid = true;
        value.platformIPhone = true;
      } else {
        value.platformAndroid = value.platform[0] === 'android' ? true : false;
        value.platformIPhone = value.platform[0] === 'ios' ? true : false;
      }
      delete value?.platform;
    }
    const payload = {
      ...value,
      mediaItemId,
    };
    localStorage.setItem('notification-step-2', JSON.stringify(payload));
    onNext();
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('notification-step-2'));
    if (data) {
      if (data?.platformAndroid && !data?.platformIPhone) {
        data.platform = ['android'];
      } else if (data?.platformIPhone && !data?.platformAndroid) {
        data.platform = ['ios'];
      } else if (data?.platformAndroid && data.platformIPhone) {
        data.platform = ['android', 'ios'];
      } else {
        data['platform'] = [];
      }
      form.setFieldsValue({ ...data });
      setMediaItemId(data?.mediaItemId || null);
      setFileList(data?.coverImage?.fileList || []);
    }
  }, [form]);

  useEffect(() => {
    setFormValue(form.getFieldsValue());
  }, [form, formAllValue]);

  useEffect(() => {
    const value = JSON.parse(localStorage.getItem('notification-step-1'));
    if (value?.albumId?.image) {
      setMediaItemId(value.albumId.image?.id);
      setFileList([
        {
          uid: '-1',
          name: 'cover image',
          status: 'done',
          url: value.albumId.image.image,
          thubmUrl: value.albumId.image.image,
        },
      ]);
    }
  }, []);

  const props = {
    name: 'file',
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL}/uploads-image`,
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload() {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn('File Already Uploaded! Please delete that.');
        return false;
      }
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === 'done') {
        setMediaItemId(info?.file?.response?.data?.media.id);
      } else if (info.file.status === 'error') {
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove(info) {
      setFileList([]);
      setMediaItemId(null);
    },
  };

  return (
    <>
      <InsideHeader className='add-edit-form' style={{ width: '90%' }}>
        <h1 className='font-size-28'>Enter Title Details & Select Target</h1>
        <div className='img-form'>
          <Form
            style={{ width: '100%' }}
            form={form}
            labelWrap={true}
            name='control-hooks'
            onFinish={addEditNotification}
            onFieldsChange={(_, allValue) => {
              setFormAllValue(allValue);
            }}
          >
            <Form.Item
              name='title'
              label='Notification Title'
              rules={[{ required: true, message: 'Please enter title' }]}
            >
              <Input className='input-control' autoFocus allowClear />
            </Form.Item>
            <Form.Item
              name='text'
              label='Notification Text'
              rules={[{ required: true, message: 'Please enter text' }]}
            >
              <Input.TextArea showCount maxLength={100} allowClear />
            </Form.Item>
            <Form.Item
              valuePropName='filelist'
              name='coverImage'
              label='Notification Image'
            >
              <Upload accept='.png, .jpg, .jpeg' listType='picture' {...props}>
                <ButtonCom type='primary' icon={<UploadOutlined />}>
                  Click to Upload
                </ButtonCom>
              </Upload>
            </Form.Item>
            <Form.Item
              name='platform'
              label='Which platform you want to
              send notifications?'
              rules={[{ required: true, message: 'please select platform' }]}
            >
              <Checkbox.Group>
                <Checkbox value='android'>Android</Checkbox>
                <Checkbox value='ios'>iOS</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <div className='form-btn-group'>
              <ButtonCom className='mr-2 width-180' onClick={() => onBack()}>
                Back
              </ButtonCom>
              <ButtonCom htmlType='submit' type='primary' className='width-180'>
                Next
              </ButtonCom>
            </div>
          </Form>

          <div className='img-wrapper'>
            <div className='img'>
              <img src={NotificationImg} alt='' width='300px' height='300px' />
              {(fileList[0]?.response?.data?.media?.image ||
                fileList[0]?.url ||
                formValue?.title ||
                formValue?.text) && (
                <div className='card-inside-img'>
                  <div>
                    <img src={JalsoLogo} alt='' className='mr-1' />
                    <img src={CardTitle} alt='' />
                  </div>
                  <div className='card-info'>
                    <h5 className='font-size-12 word-break-all'>
                      {formValue?.title}
                    </h5>
                    <p className='font-size-12 word-break-all'>
                      {formValue?.text}
                    </p>
                    {(fileList[0]?.response?.data?.media?.image ||
                      fileList[0]?.url) && (
                      <img
                        src={
                          fileList[0]?.response?.data?.media?.image ||
                          fileList[0]?.url
                        }
                        width='100%'
                        height='155px'
                        alt=''
                        className='mt-1'
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </InsideHeader>
    </>
  );
};

export default EnterData;
