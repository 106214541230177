import React, { useState } from 'react';

import { Row, Col, Steps } from 'antd';

import SelectSong from './Steps/SelectSong';
import SelectAlbum from './Steps/SelectAlbum';
import ReviewData from './Steps/ReviewData';

const { Step } = Steps;

const AddSongToAlbum = () => {
  const [current, setCurrent] = useState(0);

  return (
    <>
      {/* Step */}
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <Steps current={current}>
            <Step title='Select Songs' />
            <Step title='Select Album(s)' />
            <Step title='Review Data' />
          </Steps>
        </Col>
      </Row>

      {/* Select Song */}
      {current === 0 && <SelectSong setCurrent={setCurrent} />}

      {/* Select Album */}
      {current === 1 && <SelectAlbum setCurrent={setCurrent} />}

      {/* Review Data */}
      {current === 2 && <ReviewData setCurrent={setCurrent} />}
    </>
  );
};

export default AddSongToAlbum;
