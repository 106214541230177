import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Table, Input, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import { tableSortIconToggle } from '../../utility/tableSorting';
import PaginationCom from '../../components/PaginationCom';
import ButtonCom from '../../components/Button/ButtonCom';
import DeleteModel from '../../components/DeleteModel';
import { ADMIN_USERS } from '../../service/index';

const AdminUsers = ({ permissions }) => {
  const [adminUsers, setAdminUsers] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);

  const [tableDataLength, setTableDataLength] = useState(0);

  const navigate = useNavigate();

  const fetchAdminUsers = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null
  ) => {
    setTableLoading(true);
    try {
      const res = await ADMIN_USERS.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort
      );
      if (res.status === 1 || res.status === '1') {
        setAdminUsers(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || 'Something went wrong!');
    } finally {
      setTableLoading(false);
    }
  };

  const deleteAdminUser = async () => {
    try {
      const res = await ADMIN_USERS.DELETE(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (adminUsers?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchAdminUsers(page, pageSize, search, sort);
        }
        toast.success(res?.message);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.field === 'fullname') {
      sorter.field = 'firstname';
    }
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  useEffect(() => {
    fetchAdminUsers(currentPage, pageSize, search, sort);
  }, [currentPage, pageSize, search, sort]);

  const columns = [
    {
      title: (column) => tableSortIconToggle('Full Name', 'fullname', column),
      dataIndex: 'fullname',
      key: 'fullname',
      sortDirections: ['ascend', 'descend'],
      sorter: tableDataLength > 1 ? true : false,
    },
    {
      title: (column) => tableSortIconToggle('User Name', 'username', column),
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: tableDataLength > 1 ? true : false,
    },
    {
      title: (column) => tableSortIconToggle('Email Id', 'email', column),
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: tableDataLength > 1 ? true : false,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      align: 'center',
      render: (role) => {
        return role?.name || '-';
      },
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      dataIndex: 'Action',
      width: 160,
      render: (item, record) => {
        return (
          <>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit User'>
                <EditOutlined
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                  onClick={() => navigate(`/admin-users/edit/${record?.id}`)}
                />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete User'>
                <DeleteOutlined
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  if (permissions?.isUpdate === false && permissions?.isDelete === false) {
    delete columns[4];
  }

  return (
    <>
      <InsideHeader className='search-section'>
        <Input
          placeholder='Start Typing Search User'
          className='input-control'
          onChange={(e) => {
            if (!e?.target?.value) {
              setSearch(null);
              setTempSearch(null);
            } else {
              setTempSearch(e?.target?.value);
            }
          }}
          onKeyPress={(e) => {
            if (e?.key === 'Enter' && e.target.value) {
              handleSearch();
            }
          }}
          suffix={
            <SearchOutlined
              onClick={handleSearch}
              style={{ color: '#8f9bb3' }}
            />
          }
          allowClear
        />
        <div className='search-section__btn-group'>
          {(permissions?.isCreate || permissions?.isCreate === undefined) && (
            <ButtonCom
              className='mr-1'
              type='primary'
              onClick={() => navigate('/admin-users/add')}
            >
              Create New User
            </ButtonCom>
          )}
          <ButtonCom
            type='primary'
            onClick={() => navigate('/admin-users/roles')}
          >
            Roles Management
          </ButtonCom>
        </div>
      </InsideHeader>

      {/* Data Table */}
      <div className='fix-table mt-3 border-radius-5 box-shadow-3'>
        <Table
          columns={columns}
          dataSource={adminUsers?.data}
          pagination={false}
          rowKey='id'
          loading={tableLoading}
          onChange={onChange}
        />

        <div className='bg-white p-2 width-1120 d-flex justify-content-between align-items-center'>
          <p className='mb-0'>{adminUsers?.total || 0} - Total Admin User</p>
          {adminUsers?.total > 10 && (
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={adminUsers?.total}
            />
          )}
        </div>
      </div>

      {/* Delete Modal */}
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteAdminUser}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </>
  );
};

export default AdminUsers;
