import { methods, serviceMaker } from '../service';

const GET_ARTISTS = (
  limit,
  skip,
  search = null,
  sort = null,
  artistRoleId = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (artistRoleId) {
    query['params']['artistRoleId'] = artistRoleId;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`artists`, methods.GET, query);
};

const GET_ARTISTS_BY_ID = (id) => serviceMaker(`artists/${id}`, methods.GET);

const DELETE_ARTISTS = (id) => serviceMaker(`artists/${id}`, methods.DELETE);

const ADD_ARTISTS = (payload) =>
  serviceMaker(`artists`, methods.POST, null, payload);

const EDIT_ARTISTS = (id, payload) =>
  serviceMaker(`artists/${id}`, methods.PATCH, null, payload);

const GET_ROLES = () => {
  const query = {
    params: {
      $limit: '-1',
    },
  };
  query['params']['$sort[createdAt]'] = -1;
  return serviceMaker(`artist-role`, methods.GET, query);
};

const GET_ROLES_BY_ID = (id) => serviceMaker(`artist-role/${id}`, methods.GET);

const DELETE_ROLE = (id) => serviceMaker(`artist-role/${id}`, methods.DELETE);

const ADD_ROLE = (payload) =>
  serviceMaker(`artist-role`, methods.POST, null, payload);

const EDIT_ROLE = (id, payload) =>
  serviceMaker(`artist-role/${id}`, methods.PATCH, null, payload);

export const ARTISTS = {
  GET: GET_ARTISTS,
  GET_BY_ID: GET_ARTISTS_BY_ID,
  ADD: ADD_ARTISTS,
  DELETE: DELETE_ARTISTS,
  EDIT: EDIT_ARTISTS,
};
export const ARTIST_ROLE = {
  GET: GET_ROLES,
  GET_BY_ID: GET_ROLES_BY_ID,
  ADD: ADD_ROLE,
  DELETE: DELETE_ROLE,
  EDIT: EDIT_ROLE,
};
