import { useState, useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Radio, Form, DatePicker } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';

import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import ButtonCom from '../../../../components/Button/ButtonCom';
import { NOTIFICATION } from '../../../../service';

const Publish = ({ onBack }) => {
  const [isScheduled, setIsScheduled] = useState(false);
  const [publishDate, setPublishDate] = useState(null);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const addEditNotification = async (value) => {
    if (value?.publish_date) {
      value.publish_date = moment(value?.publish_date).format();
    } else {
      value.publish_date = new Date();
    }
    const stepOne = JSON.parse(localStorage.getItem('notification-step-1'));
    const stepTwo = JSON.parse(localStorage.getItem('notification-step-2'));
    const payload = {
      ...value,
      ...stepOne,
      albumId: stepOne?.albumId ? stepOne?.albumId?.value : null,
      songId: stepOne?.songId ? stepOne?.songId?.value : null,
      ...stepTwo,
    };
    delete payload.coverImage;
    try {
      let res;
      const clone = location.pathname.includes('clone');
      if (id && clone === false) {
        res = await NOTIFICATION.EDIT_NOTIFICATION(id, payload);
      } else {
        res = await NOTIFICATION.ADD_NOTIFICATION(payload);
      }
      if (res.status === 1 || res.status === '1') {
        localStorage.removeItem('notification-step-1');
        localStorage.removeItem('notification-step-2');
        localStorage.removeItem('notification-step-3');
        navigate('/notifications');
        toast.success(res?.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something Went Wrong !');
    }
  };

  const disabledDate = (current) => {
    let customDate = moment().format('YYYY-MM-DD ');
    return current && current < moment(customDate, 'YYYY-MM-DD');
  };

  const handleDisabledHours = () => {
    var hours = [];
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const disabledMinutes = () => {
    var minutes = [];
    for (var i = 0; i < moment().minute(); i++) {
      minutes.push(i);
    }
    return minutes;
  };

  const disabledSecond = () => {
    var second = [];
    for (var i = 0; i < moment().seconds(); i++) {
      second.push(i);
    }
    return second;
  };

  useEffect(() => {
    if (id) {
      const data = JSON.parse(localStorage.getItem('notification-step-3'));
      setIsScheduled(data?.isPublished ? false : true);
      if (data.publish_date) {
        form.setFieldsValue({
          publish_date: moment(data?.publish_date),
        });
      }
    }
  }, [form, id]);

  return (
    <>
      <InsideHeader className='add-edit-form' style={{ width: '650px' }}>
        <h1 className='font-size-28'>Schedule or Publish</h1>
        <Form
          initialValues={{
            is_scheduled: false,
          }}
          form={form}
          labelWrap={true}
          name='control-hooks'
          onFinish={addEditNotification}
        >
          <Form.Item style={{ textAlign: 'center' }}>
            <Radio.Group
              onChange={(e) => {
                setIsScheduled(e.target.value);
                e.target.value === false &&
                  form.setFieldsValue({
                    publish_date: null,
                  });
              }}
              value={isScheduled}
            >
              <Radio value={false}>Publish Now</Radio>
              <Radio value={true}>Schedule</Radio>
            </Radio.Group>
          </Form.Item>

          {isScheduled === true && (
            <>
              <Form.Item
                name='publish_date'
                label='Select Date'
                rules={[{ required: true, message: 'Please select date' }]}
              >
                <DatePicker
                  className='input-control w-100'
                  use12Hours
                  format={'YYYY-MM-DD h:mm:ss'}
                  showTime={{
                    disabledHours: () =>
                      moment(publishDate).date() <= moment().date()
                        ? handleDisabledHours()
                        : null,
                    disabledMinutes: () =>
                      moment(publishDate).date() <= moment().date()
                        ? disabledMinutes()
                        : null,
                    disabledSeconds: () =>
                      moment(publishDate).date() <= moment().date()
                        ? disabledSecond()
                        : null,
                  }}
                  disabledDate={(current) => disabledDate(current)}
                  onSelect={(e) => setPublishDate(e)}
                  allowClear
                />
              </Form.Item>
            </>
          )}

          <div className='form-btn-group'>
            <ButtonCom className='width-180 mr-2' onClick={() => onBack()}>
              Back
            </ButtonCom>
            <ButtonCom htmlType='submit' type='primary' className='width-180'>
              {isScheduled ? 'Scheduled' : 'Publish Now'}
            </ButtonCom>
          </div>
        </Form>
      </InsideHeader>
    </>
  );
};

export default Publish;
