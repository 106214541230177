import React from 'react';

import { Modal } from 'antd';

const LogOutModal = ({ visible, handleLogOut, setVisible }) => {
  return (
    <>
      <Modal
        centered='true'
        okText='Yes'
        cancelText='No'
        style={{ textAlign: 'center' }}
        title='Logout'
        visible={visible}
        onOk={handleLogOut}
        onCancel={() => setVisible(false)}
      >
        <p>Are you sure you want to logout ? </p>
      </Modal>
    </>
  );
};

export default LogOutModal;
