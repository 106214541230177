import React, { useEffect, useState } from 'react';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import {
  Row,
  Col,
  Input,
  Upload,
  Table,
  Checkbox,
  Switch,
  Tooltip,
} from 'antd';

import SelectWithPagination from './SelectWithPagination';
import DownArrowIcon from '../assets/image/down-arrow.svg';
import CloseIcon from '../assets/image/close.svg';
import MusicIcon from '../assets/image/music.svg';
import { ALBUMS, SONGS } from '../service/index';
import PlusIcon from '../assets/image/plus.svg';
import { BASE_URL } from '../utility/constant';
import ButtonCom from './Button/ButtonCom';
import DeleteModel from './DeleteModel';
import SelectDropDown from './Select';

const { Dragger } = Upload;
const { TextArea } = Input;

const AddSongs = ({ setCurrent, scope }) => {
  const [songs, setSongs] = useState([]);
  const [songEditId, setSongEditId] = useState(null);
  const [deleteSongRecord, setDeleteSongRecord] = useState({});

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteModalSongVisible, setDeleteModalSongVisible] = useState(false);
  const [deleteModalArtistVisible, setDeleteModalArtistVisible] =
    useState(false);

  const [deleteArtistRecord, setDeleteArtistRecord] = useState([]);

  const [selectSongs, setSelectSongs] = useState(null);

  const [uploadFile, setUploadFile] = useState([]);

  const [mediaSoundDelete, setMediaSoundDelete] = useState({});

  const albumsData = JSON.parse(localStorage.getItem('step1'));

  const loadOptionsOfSong = async (search, prevOptions, { skip }) => {
    const res = await SONGS.ALL_SONGS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        ...item,
        value: item?.id,
        label: item?.songTitle,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleSongSelect = (e) => {
    setSelectSongs([{ ...e, artistAddedRoleId: [], addSongFromSearch: true }]);
  };

  const optionDisabled = (option) => {
    const temp =
      songs?.find((x) => x.id === option.id) ||
      albumsData?.album_songs?.find((item) => item?.songId === option?.id);
    if (temp) {
      temp.disabled = true;
    }
    return temp;
  };

  const handleAddSongs = () => {
    const tempSongs = [...songs];
    const temp = selectSongs.map((item) => {
      if (!item?.albumId && !item?.album) {
        item.albumId = albumsData?.id;
        item.album = albumsData;
      }
      if (item?.album) {
        item.album = {
          ...item?.album,
          label: item?.album?.albumName,
          value: item?.album?.id,
        };
      }
      item.songType = {
        label: item.songType,
        value: item.songType,
      };
      const obj = [];
      item.song_artists.forEach((artist) => {
        if (artist.artist) {
          const filterArtist = obj.filter((f) => f.id === artist?.artist?.id);
          if (obj.length > 0 && filterArtist.length > 0) {
            filterArtist[0].artist_added_roles.push({
              artist_role: artist.artist_role,
              present: true,
              songArtistId: artist.id,
            });
          } else {
            let artistObj = {
              ...artist.artist,
              artist_added_roles: [
                {
                  artist_role: artist.artist_role,
                  present: true,
                  songArtistId: artist.id,
                },
              ],
            };
            obj.push(artistObj);
          }
        }
      });

      delete item.song_artists;
      return { ...item, song_artists: obj };
    });

    tempSongs.push(...temp);

    setSongs(tempSongs);
    setSelectSongs(null);
  };

  const loadOptionsOfArtist = async (search, prevOptions, { skip }) => {
    const res = await SONGS.SEARCH_ARTIST(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        ...item,
        value: item?.id,
        label: item?.name,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleArtistSelect = (e, index) => {
    const tempSongs = [...songs];
    tempSongs[index].artistSelect = [{ ...e, newArtist: true }];
    tempSongs[index].jsonValue = e;
    setSongs(tempSongs);
  };

  const handleAddArtist = (index) => {
    const temp = [...songs];
    temp[index].song_artists.push(...temp[index].artistSelect);
    temp[index].jsonValue = [];
    temp[index].artistSelect = [];
    setSongs(temp);
  };

  const handleRemoveArtist = async () => {
    const tempSongs = [...songs];
    const recordDeleteId = deleteArtistRecord.item.artist_added_roles.map(
      (item) => {
        return { id: item.songArtistId, deletedAt: new Date() };
      }
    );
    try {
      const res = await SONGS.REMOVE_SONG_ARTIST(recordDeleteId);
      setDeleteModalArtistVisible(false);
      if (res.status === 1 || res.status === '1') {
        tempSongs[deleteArtistRecord.index].song_artists = tempSongs[
          deleteArtistRecord.index
        ].song_artists.filter(
          (item) => item.id !== deleteArtistRecord?.item?.id
        );
        toast.success(res.message);
        setSongs(tempSongs);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const handleCheckbox = (e, index, role, i, indexOfSongsArtist, name) => {
    const temp = [...songs];

    if (e.target.checked) {
      temp[index].song_artists[indexOfSongsArtist].artist_added_roles[
        i
      ].present = true;
    } else {
      temp[index].song_artists[indexOfSongsArtist].artist_added_roles[
        i
      ].present = false;
    }
    setSongs(temp);
  };

  const handleLyrics = (e, index) => {
    const tempSongs = [...songs];
    tempSongs[index].lyrics = e.target.value;
    setSongs(tempSongs);
  };

  const handleChangeDownloadable = (e, index) => {
    const tempSong = [...songs];
    tempSong[index].isdownloadable = e;
    setSongs(tempSong);
  };

  const handleChangeCrm = (e, index) => {
    const tempSong = [...songs];
    tempSong[index].cmsPath = e.target.value;
    setSongs(tempSong);
  };

  const handleChangeChooseInterest = (e, index) => {
    const tempSong = [...songs];
    tempSong[index].songType = e;
    setSongs(tempSong);
  };

  const handleBack = () => {
    if (scope === 'album') {
      setCurrent(1);
    } else {
      setCurrent(0);
    }
  };

  const handleNext = () => {
    if (scope === 'album') {
      setCurrent(3);
    } else {
      setCurrent(2);
    }
    localStorage.setItem('step2', JSON.stringify(songs));
  };

  const DeleteMediaSound = async () => {
    const tempSong = [...songs];
    const payload = {};
    if (mediaSoundDelete.key === 'bitFile32') {
      tempSong[mediaSoundDelete.index].media_sound.bitFile32 = null;
      payload.bitFile32 = null;
    }
    if (mediaSoundDelete.key === 'bitFile64') {
      tempSong[mediaSoundDelete.index].media_sound.bitFile64 = null;
      payload.bitFile64 = null;
    }
    if (mediaSoundDelete.key === 'bitFile128') {
      tempSong[mediaSoundDelete.index].media_sound.bitFile128 = null;
      payload.bitFile128 = null;
    }
    if (mediaSoundDelete.key === 'bitFile256') {
      tempSong[mediaSoundDelete.index].media_sound.bitFile256 = null;
      payload.bitFile256 = null;
    }
    try {
      const res = await ALBUMS.REMOVE_MEDIA_SOUND(
        tempSong[mediaSoundDelete.index].media_sound.id,
        payload
      );
      if (res.status === 1 || res.status === '1') {
        setSongs(tempSong);
        setMediaSoundDelete({});
        setIsDeleteModalVisible(false);
        toast.success('Record deleted successfully.');
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const DeleteSong = async () => {
    let tempSong = [...songs];
    if (
      deleteSongRecord.addSongFromSearch === true ||
      deleteSongRecord.file === true
    ) {
      tempSong = tempSong.filter((item) => item.id !== deleteSongRecord.id);
      localStorage.setItem('step2', JSON.stringify(tempSong));
      setSongs(tempSong);
      setDeleteSongRecord({});
      setDeleteModalSongVisible(false);
      toast.success('Record deleted successfully.');
    } else {
      const findIdOfAlbumSong = deleteSongRecord.album_songs.filter(
        (item) => item.albumId === albumsData.albumId
      )[0];
      try {
        const res = await SONGS.DELETE_ALBUM_SONG(findIdOfAlbumSong.id);
        if (res.status === 1 || res.status === '1') {
          tempSong = tempSong.filter((item) => item.id !== deleteSongRecord.id);
          localStorage.setItem('step2', JSON.stringify(tempSong));
          setSongs(tempSong);
          setDeleteSongRecord({});
          setDeleteModalSongVisible(false);
          toast.success('Record deleted successfully.');
        }
      } catch (err) {
        toast.error(err.message || 'Something went wrong!');
      }
    }
  };

  const handleEdit = (index) => {
    const tempSongs = [...songs];
    let tempSongEditId = songEditId;
    tempSongs[index].tempTitle = tempSongs[index].songTitle;
    if (tempSongs[index].duration === '00:00') {
      tempSongs[index].tempDuration = tempSongs[index].duration;
    }
    tempSongEditId = index;
    setSongs(tempSongs);
    setSongEditId(tempSongEditId);
  };

  const handleEditTitle = (e, index) => {
    const tempSongs = [...songs];
    tempSongs[index].tempTitle = e.target.value;
    setSongs(tempSongs);
  };

  const handleSave = async (index) => {
    const tempSongs = [...songs];
    tempSongs[index].songTitle = tempSongs[index].tempTitle;
    if (tempSongs[index].tempDuration) {
      function convertDurationtoSeconds(duration) {
        const [minutes, seconds] = duration.split(':');
        return Number(minutes) * 60 + Number(seconds);
      }
      const payload = {
        duration: convertDurationtoSeconds(tempSongs[index].tempDuration),
      };
      try {
        const res = await SONGS.UPDATE_MEDIA_SOUND(
          tempSongs[index].media_sound.id,
          payload
        );
        if (res.status === 1 || res.status === '1') {
          tempSongs[index].duration = tempSongs[index].tempDuration;
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong!');
      }
    }
    setSongs(tempSongs);
    setSongEditId(null);
  };

  const handleCancel = (index) => {
    const tempSongs = [...songs];
    tempSongs[index].tempTitle = tempSongs[index].songTitle;
    tempSongs[index].tempDuration = tempSongs[index].duration;
    setSongs(tempSongs);
    setSongEditId(null);
  };

  const handleEditDuration = (e, index) => {
    const tempSongs = [...songs];
    tempSongs[index].tempDuration = e.target.value;
    setSongs(tempSongs);
  };

  const loadOptionsOfSongType = async (search, prevOptions) => {
    const res = await SONGS.GET_CHOOSE_INTEREST();
    const option = res?.data
      ?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      .map((item) => {
        return { value: item?.name, label: item?.name };
      });
    return {
      options: option,
    };
  };

  useEffect(() => {
    const value = JSON.parse(localStorage.getItem('step2')) || '';
    if (value) {
      setSongs([...value]);
    }
  }, []);

  const columns = [
    {
      title: 'Song Title',
      dataIndex: 'songTitle',
      key: 'songTitle',
      width: 240,
      render: (_, record, index) => {
        return songEditId === index ? (
          <Input
            onChange={(e) => handleEditTitle(e, index)}
            value={record.tempTitle}
            className='input-control'
          />
        ) : (
          <p>{record.songTitle}</p>
        );
      },
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      align: 'center',
      width: 100,
      render: (_, record, index) => {
        return songEditId === index && record.duration === '00:00' ? (
          <Input
            onChange={(e) => handleEditDuration(e, index)}
            value={record.tempDuration}
            className='input-control'
          />
        ) : (
          <p>{record.duration}</p>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 100,
      render: (_, record, item) => {
        return (
          <div>
            {songEditId === item ? (
              <>
                <ButtonCom className='mr-2' onClick={() => handleCancel(item)}>
                  Cancel
                </ButtonCom>
                <ButtonCom type='primary' onClick={() => handleSave(item)}>
                  Save
                </ButtonCom>
              </>
            ) : (
              <>
                {songEditId >= 0 && songEditId !== null ? (
                  <>
                    <Tooltip title='Edit'>
                      <EditOutlined className='font-size-20 edit-btn-hover mx-1' />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <DeleteOutlined className='font-size-20 delete-btn-hover mx-1' />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title='Edit'>
                      <EditOutlined
                        className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                        onClick={() => handleEdit(item)}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <DeleteOutlined
                        className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                        onClick={() => {
                          setDeleteModalSongVisible(true);
                          setDeleteSongRecord(record);
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
      width: 100,
      align: 'center',
      render: () => (
        <button className='bg-light-gray font-weight-500 border-0 border-radius-3 py-1 px-3 cursor-pointer'>
          Uploaded
        </button>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <>
      <Row justify='center' gutter={25} className='mt-2'>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className=' mt-3 gutter-row'
        >
          <div className='bg-white box-shadow-3 border-radius-5 p-3 '>
            <h3 className='mb-0 font-size-25'>Add Songs</h3>
            <div className='d-flex align-items-center justify-content-between mt-1'>
              <div className='d-flex'>
                <div>
                  <SelectWithPagination
                    placeholder='Select Song'
                    loadOptions={loadOptionsOfSong}
                    onChange={(e) => handleSongSelect(e)}
                    additional={{
                      skip: 0,
                    }}
                    isOptionDisabled={optionDisabled}
                    value={selectSongs}
                    isClearable
                    className='width-250'
                  />
                </div>
                <div className='ml-2'>
                  <ButtonCom type='primary' onClick={handleAddSongs}>
                    Add
                  </ButtonCom>
                </div>
              </div>
              <div>
                <h4 className='mb-0 font-size-20'>
                  Album Name - {albumsData?.albumName}
                </h4>
              </div>
            </div>
            <div className='mt-2'>
              <Dragger
                accept='.mp4, .mp3'
                multiple={true}
                fileList={uploadFile}
                action={() => `${BASE_URL}/uploads-audio`}
                beforeUpload={(file) => {
                  const audio = file.type === 'audio/mpeg';
                  if (!audio) {
                    toast.error('Only audio files are allowed!');
                  }
                  return audio || Upload.LIST_IGNORE;
                }}
                onChange={(info) => {
                  const { status } = info.file;
                  const tempUploadFile = [...info.fileList];
                  setUploadFile(tempUploadFile);

                  if (status === 'done') {
                    setUploadFile((pre) =>
                      pre.filter((item) => item.uid !== info.file.uid)
                    );
                    const tempSong = [...songs];
                    const toHHMMSS = (secs) => {
                      var sec_num = parseInt(secs, 10);
                      var hours = Math.floor(sec_num / 3600);
                      var minutes = Math.floor(sec_num / 60) % 60;
                      var seconds = sec_num % 60;

                      return [hours, minutes, seconds]
                        .map((v) => (v < 10 ? '0' + v : v))
                        .filter((v, i) => v !== '00' || i > 0)
                        .join(':');
                    };
                    tempSong.push({
                      id: songs.length + 1,
                      songTitle: info?.file?.response?.data?.originalname,
                      duration: toHHMMSS(
                        info?.file?.response?.data?.media?.duration
                      ),
                      media_sound: info?.file?.response?.data?.media,
                      song_artists: [],
                      file: true,
                      albumId: albumsData?.id,
                      album: albumsData,
                      cmsPath: info?.file?.response?.data?.originalname
                        .replaceAll('.mp3', ' ')
                        .replaceAll('.mp4', ' '),
                    });
                    setSongs(tempSong);
                  } else if (status === 'error') {
                    toast.error(`${info.file.name} file upload failed.`);
                  }
                }}
                onRemove={(remove) => {
                  const tempUploadFile = [...uploadFile];
                  const find = tempUploadFile.findIndex(
                    (item) => item.uid === remove.uid
                  );
                  tempUploadFile[find].status = 'remove';
                  setUploadFile(tempUploadFile);
                }}
              >
                <p className='ant-upload-drag-icon'></p>
                <div className='d-flex align-items-center justify-content-center flex-wrap p-2'>
                  <div>
                    <img src={MusicIcon} alt='musicIcon' />
                  </div>
                  <div>
                    <p className='ant-upload-text pl-1'>
                      Drag And Drop Or Select Songs (256bits)
                    </p>
                  </div>
                </div>
              </Dragger>
            </div>
          </div>
          {/* table */}
          <div className='fix-table mt-3 box-shadow-3 border-radius-5'>
            <Table
              pagination={false}
              className='components-table-demo-nested'
              columns={columns}
              expandable={{
                expandedRowRender: (record, index) => (
                  <>
                    {/* file */}
                    <Row gutter={15} className='mb-1 align-items-center'>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className='gutter-row'
                      >
                        <h3 className='font-size-14'>32 bit File</h3>
                      </Col>

                      <Col
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        className='gutter-row text-center'
                      >
                        <h3 className='font-size-14 ml-2'>
                          {record?.media_sound?.bitFile32
                            ? record?.duration
                            : '-'}
                        </h3>
                      </Col>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='pl-4 gutter-row text-center'
                      >
                        {record?.media_sound?.bitFile32 ? (
                          <Tooltip title='Delete'>
                            <DeleteOutlined
                              className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                              onClick={() => {
                                setMediaSoundDelete({
                                  key: 'bitFile32',
                                  index,
                                });
                                setIsDeleteModalVisible(true);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </Col>
                      <Col
                        xs={5}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        className='gutter-row text-center'
                      >
                        {record?.media_sound?.bitFile32 && (
                          <button className='bg-light-gray font-weight-500 border-0 border-radius-3 py-1 px-3 cursor-pointer'>
                            Uploaded
                          </button>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={15} className='mb-1 align-items-center'>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className='gutter-row'
                      >
                        <h3 className='font-size-14'>64 bit File</h3>
                      </Col>
                      <Col
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        className='gutter-row text-center'
                      >
                        <h3 className='font-size-14 ml-2'>
                          {record?.media_sound?.bitFile64
                            ? record?.duration
                            : '-'}
                        </h3>
                      </Col>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='pl-4 gutter-row text-center'
                      >
                        {record?.media_sound?.bitFile64 ? (
                          <Tooltip title='Delete'>
                            <DeleteOutlined
                              className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                              onClick={() => {
                                setMediaSoundDelete({
                                  key: 'bitFile64',
                                  index,
                                });
                                setIsDeleteModalVisible(true);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </Col>
                      <Col
                        xs={5}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        className='gutter-row text-center'
                      >
                        {record?.media_sound?.bitFile64 && (
                          <button className='bg-light-gray font-weight-500 border-0 border-radius-3 py-1 px-3 cursor-pointer'>
                            Uploaded
                          </button>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={15} className='mb-1 align-items-center'>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className='gutter-row'
                      >
                        <h3 className='font-size-14'>128 bit File</h3>
                      </Col>
                      <Col
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        className='gutter-row text-center'
                      >
                        <h3 className='font-size-14 ml-2'>
                          {record?.media_sound?.bitFile128
                            ? record?.duration
                            : '-'}
                        </h3>
                      </Col>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='pl-4 gutter-row text-center'
                      >
                        {record?.media_sound?.bitFile128 ? (
                          <Tooltip title='Delete'>
                            <DeleteOutlined
                              className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                              onClick={() => {
                                setMediaSoundDelete({
                                  key: 'bitFile128',
                                  index,
                                });
                                setIsDeleteModalVisible(true);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </Col>
                      <Col
                        xs={5}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        className='gutter-row text-center'
                      >
                        {record?.media_sound?.bitFile128 && (
                          <button className='bg-light-gray font-weight-500 border-0 border-radius-3 py-1 px-3 cursor-pointer'>
                            Uploaded
                          </button>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={15} className='mb-1 align-items-center'>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className='gutter-row'
                      >
                        <h3 className='font-size-14'>256 bit File</h3>
                      </Col>
                      <Col
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        className='gutter-row text-center'
                      >
                        <h3 className='font-size-14 ml-2'>
                          {record?.media_sound?.bitFile256
                            ? record?.duration
                            : ' - '}
                        </h3>
                      </Col>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='pl-4 gutter-row text-center'
                      >
                        {record?.media_sound?.bitFile256 ? (
                          <Tooltip title='Delete'>
                            <DeleteOutlined
                              className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                              onClick={() => {
                                setMediaSoundDelete({
                                  key: 'bitFile256',
                                  index,
                                });
                                setIsDeleteModalVisible(true);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </Col>
                      <Col
                        xs={5}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        className='gutter-row text-center'
                      >
                        {record?.media_sound?.bitFile256 && (
                          <button className='bg-light-gray font-weight-500 border-0 border-radius-3 py-1 px-3 cursor-pointer'>
                            Uploaded
                          </button>
                        )}
                      </Col>
                    </Row>

                    {/* artist  */}
                    <Row gutter={25} className='align-items-center my-2'>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='gutter-row'
                      >
                        <h3 className='font-size-14 mb-0'>Artists</h3>
                      </Col>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className='gutter-row'
                      >
                        <SelectWithPagination
                          placeholder='Select Artist'
                          loadOptions={loadOptionsOfArtist}
                          onChange={(e) => handleArtistSelect(e, index)}
                          additional={{
                            skip: 0,
                          }}
                          isOptionDisabled={(option) => {
                            const temp = record.song_artists.find(
                              (x) => x.id === option?.id
                            );
                            if (temp) {
                              temp.disabled = true;
                            }
                            return temp;
                          }}
                          value={record?.jsonValue}
                          isClearable
                        />
                      </Col>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='gutter-row '
                      >
                        <div
                          className='d-flex align-items-center'
                          onClick={() => handleAddArtist(index)}
                        >
                          <h3 className='font-size-15 mr-1 mb-0'>Add</h3>
                          <Tooltip title='Add Artist'>
                            <img src={PlusIcon} alt='plusIcon' />
                          </Tooltip>
                        </div>
                      </Col>
                    </Row>

                    {/* artist list */}
                    {record &&
                      record?.song_artists?.length > 0 &&
                      record?.song_artists.map((item, indexOfSongsArtist) => {
                        return (
                          <Row
                            key={indexOfSongsArtist}
                            gutter={25}
                            className='align-items-center my-2'
                          >
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              className='gutter-row'
                            >
                              <h3 className='font-size-14 mb-0'>
                                {item?.name}
                              </h3>
                            </Col>
                            <Col
                              xs={20}
                              sm={20}
                              md={20}
                              lg={20}
                              xl={20}
                              className='gutter-row'
                            >
                              <Row gutter={25} className='align-items-center'>
                                <Col
                                  xs={22}
                                  sm={22}
                                  md={22}
                                  lg={22}
                                  xl={22}
                                  className='gutter-row'
                                >
                                  <Row
                                    gutter={25}
                                    className='align-items-center'
                                  >
                                    {item?.artist_added_roles?.map(
                                      (role, roleIndex) => {
                                        return (
                                          <Col
                                            xs={5}
                                            sm={5}
                                            md={5}
                                            lg={5}
                                            xl={5}
                                            className='gutter-row'
                                            key={roleIndex}
                                          >
                                            <Checkbox
                                              checked={
                                                record.song_artists[
                                                  indexOfSongsArtist
                                                ].artist_added_roles[roleIndex]
                                                  .present
                                              }
                                              onChange={(e) =>
                                                handleCheckbox(
                                                  e,
                                                  index,
                                                  role,
                                                  roleIndex,
                                                  indexOfSongsArtist,
                                                  item.name
                                                )
                                              }
                                            >
                                              {role?.artist_role?.name}
                                            </Checkbox>
                                          </Col>
                                        );
                                      }
                                    )}
                                  </Row>
                                </Col>
                                <Col
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  className='gutter-row d-flex align-items-center'
                                >
                                  <img
                                    src={CloseIcon}
                                    alt='closeIcon'
                                    className='cursor-pointer'
                                    onClick={() => {
                                      setDeleteArtistRecord({ item, index });
                                      setDeleteModalArtistVisible(true);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        );
                      })}

                    {/* Lyrics */}
                    <Row gutter={25} className='mb-2 mt-3'>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='gutter-row'
                      >
                        <h3 className='font-size-14 mb-0'>Lyrics</h3>
                      </Col>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className='gutter-row'
                      >
                        <TextArea
                          placeholder='Lyrics'
                          allowClear
                          onChange={(e) => handleLyrics(e, index)}
                          value={record.lyrics}
                          style={{ height: '120px' }}
                        />
                      </Col>
                    </Row>

                    {/* Downloadable */}
                    <Row gutter={25} className='mb-2 mt-3'>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='gutter-row'
                      >
                        <h3 className='font-size-14 mb-0'>Downloadable</h3>
                      </Col>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className='gutter-row'
                      >
                        <Switch
                          onChange={(e) => handleChangeDownloadable(e, index)}
                          checked={record.isdownloadable}
                        />
                      </Col>
                    </Row>

                    {/* CMS Path */}
                    <Row gutter={25} className='mb-2 mt-3 align-items-center'>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='gutter-row'
                      >
                        <h3 className='font-size-14 mb-0'>CMS Path</h3>
                      </Col>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className='gutter-row'
                      >
                        <Input
                          onChange={(e) => handleChangeCrm(e, index)}
                          value={record.cmsPath}
                          className='input-control'
                          placeholder='CMS Path'
                          allowClear
                        />
                      </Col>
                    </Row>

                    {/* Song Type */}
                    <Row gutter={25} className='mb-2 mt-3 align-items-center'>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='gutter-row'
                      >
                        <h3 className='font-size-14 mb-0'>Song Type</h3>
                      </Col>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className='gutter-row'
                      >
                        <SelectWithPagination
                          placeholder='Select Song Type'
                          loadOptions={loadOptionsOfSongType}
                          onChange={(e) => handleChangeChooseInterest(e, index)}
                          value={record.songType}
                          isClearable
                        />
                      </Col>
                    </Row>

                    {/* Mother album */}
                    <Row gutter={25} className='mb-2 mt-3 align-items-center'>
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className='gutter-row'
                      >
                        <h3 className='font-size-14 mb-0'>Mother Album</h3>
                      </Col>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className='gutter-row'
                      >
                        <SelectDropDown
                          placeholder='Select Mother Album'
                          value={record?.album}
                          isDisabled
                        />
                      </Col>
                    </Row>
                  </>
                ),
                rowExpandable: (record) =>
                  record.songTitle !== 'Not Expandable',
              }}
              expandIcon={({ expanded, onExpand, record }) => (
                <ButtonCom type='primary' onClick={(e) => onExpand(record, e)}>
                  Add
                  <img src={DownArrowIcon} alt='downArrowIcon' />
                </ButtonCom>
              )}
              dataSource={songs}
              rowKey='id'
            ></Table>

            {/* Button */}
            <div className='text-center bg-white py-3 width-1120 '>
              <ButtonCom className='mr-2 width-180' onClick={handleBack}>
                Back
              </ButtonCom>
              <ButtonCom
                type='primary'
                className='width-180'
                onClick={handleNext}
              >
                Next
              </ButtonCom>
            </div>
          </div>
        </Col>
      </Row>

      {/* Delete Model */}
      <DeleteModel
        isDeleteModalVisible={deleteModalSongVisible}
        deleteModel={DeleteSong}
        setIsDeleteModalVisible={setDeleteModalSongVisible}
      />

      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={DeleteMediaSound}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />

      <DeleteModel
        isDeleteModalVisible={deleteModalArtistVisible}
        deleteModel={handleRemoveArtist}
        setIsDeleteModalVisible={setDeleteModalArtistVisible}
      />
    </>
  );
};

export default AddSongs;
