import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';

export const handleFileName = (name, extension) => {
  const date = Date().split(' ');
  const dateStr = `${date[0]}-${date[1]}-${date[2]}-${date[3]}`;
  return `${name}-${dateStr}.${extension}`;
};

export const ExportExcel = (excelData, name) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const ws = XLSX.utils.json_to_sheet(excelData);
  ws['!cols'] = [
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
  ];
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, handleFileName(name, 'xlsx'));
};

export const ExportPdf = (col, row, name) => {
  const doc = new jsPDF();
  doc.autoTable(col, row, {
    startY: 20, styles: { overflow: 'linebreak' },
    tableWidth: 'auto',
  });
  doc.save(handleFileName(name, 'pdf'));
};
