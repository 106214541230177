import { useEffect, useState } from 'react';

import { Form, Input, Checkbox, Table } from 'antd';

import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { ADMIN_ROLES, PAGE_MODULES } from '../../service';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import ButtonCom from '../../components/Button/ButtonCom';

const AddEditAdminRoles = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [pageModules, setPageModules] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { id = null } = useParams();
  const operationName = id ? 'edit' : 'add';

  const getEditData = async (permissionsSyntax) => {
    try {
      const res = await ADMIN_ROLES.GET_BY_ID(id);
      if (res.status === 1) {
        if (res?.data) {
          setIsAdmin(res?.data?.isAdmin);

          res?.data?.permissions.forEach((permission) => {
            const obj = {
              ...permission,
              pageModuleId: permission?.page?.id,
            };
            delete obj?.page;
            delete obj?.id;
            permissionsSyntax.forEach((per, index) => {
              if (per?.pageModuleId === obj?.pageModuleId) {
                permissionsSyntax[index] = { ...obj };
              }
            });
            setPermissions([...permissionsSyntax]);
          });
        }
        form.setFieldsValue({ ...res?.data });
      }
    } catch (err) {
      toast.error(err.message || 'something wen wrong');
    }
  };

  //form submit
  const onFinish = async (values) => {
    let givenPermission = [];
    if (!isAdmin) {
      givenPermission = permissions.filter(
        (permission) =>
          permission?.isView ||
          permission?.isCreate ||
          permission?.isDelete ||
          permission?.isUpdate
      );
    }

    const payload = {
      ...values,
      permissions: givenPermission,
      isAdmin,
    };
    try {
      let res;
      if (operationName === 'edit') {
        res = await ADMIN_ROLES.EDIT(id, payload);
      }
      if (operationName === 'add') {
        res = await ADMIN_ROLES.ADD(payload);
      }
      if (res.status === 1 || res.status === '1') {
        toast.success(res?.message);
        navigate('/admin-users/roles');
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const fetchPageModules = async () => {
    try {
      const res = await PAGE_MODULES.GET();
      if (res.status === 1) {
        setPageModules(res?.data);

        const permissionsSyntax = res?.data.map((permission) => {
          let setPermission = {
            pageModuleId: permission?.id,
            isView: operationName === 'edit' ? false : true,
            isCreate: false,
            isUpdate: false,
            isDelete: false,
          };
          return setPermission;
        });
        setPermissions([...permissionsSyntax]);
        if (operationName === 'edit') {
          getEditData(permissionsSyntax);
        }
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchPageModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on checkbox permission change update the value of givenPermission
  const onPermissionChange = (value, name, id = null) => {
    let refArray = [...permissions];

    refArray.forEach((permission) => {
      if (id) {
        if (permission?.pageModuleId === id) {
          permission[name] = value;
          if (
            permission.isCreate ||
            permission.isUpdate ||
            permission.isDelete
          ) {
            permission.isView = true;
          }
        }
      } else {
        permission[name] = value;
      }
    });
    setPermissions([...refArray]);
  };

  const columns = [
    {
      title: 'Permissions',
      dataIndex: 'pageModuleId',
      key: 'pageModuleId',
      align: 'center',
      width: 120,
      render: (pageModuleId) => {
        const [page] = pageModules.filter((page) => page.id === pageModuleId);
        return page?.name;
      },
    },
    {
      title: () => {
        return (
          <span>
            View
            <Checkbox
              className='ml-1'
              onChange={(e) => onPermissionChange(e?.target?.checked, 'isView')}
            />
          </span>
        );
      },
      dataIndex: 'isView',
      key: 'isView',
      align: 'center',
      width: '70px',
      render: (value, record) => {
        const [val] = permissions.filter(
          (permission) => record?.pageModuleId === permission?.pageModuleId
        );
        const isCheck = val?.isView;
        return (
          <Checkbox
            checked={isCheck}
            onChange={(e) =>
              onPermissionChange(
                e?.target?.checked,
                'isView',
                record?.pageModuleId
              )
            }
          />
        );
      },
    },
    {
      title: () => {
        return (
          <span>
            Create
            <Checkbox
              className='ml-1'
              onChange={(e) =>
                onPermissionChange(e?.target?.checked, 'isCreate')
              }
            />
          </span>
        );
      },
      dataIndex: 'isCreate',
      key: 'isCreate',
      align: 'center',
      width: '70px',
      render: (value, record) => {
        const [val] = permissions.filter(
          (permission) => record?.pageModuleId === permission?.pageModuleId
        );
        const isCheck = val?.isCreate;
        return (
          <Checkbox
            checked={isCheck}
            onChange={(e) =>
              onPermissionChange(
                e?.target?.checked,
                'isCreate',
                record?.pageModuleId
              )
            }
          />
        );
      },
    },
    {
      title: () => {
        return (
          <span>
            Update
            <Checkbox
              className='ml-1'
              onChange={(e) =>
                onPermissionChange(e?.target?.checked, 'isUpdate')
              }
            />
          </span>
        );
      },
      dataIndex: 'isUpdate',
      key: 'usUpdate',
      align: 'center',
      width: '70px',
      render: (value, record) => {
        const [val] = permissions.filter(
          (permission) => record?.pageModuleId === permission?.pageModuleId
        );
        const isCheck = val?.isUpdate;
        return (
          <Checkbox
            checked={isCheck}
            onChange={(e) =>
              onPermissionChange(
                e?.target?.checked,
                'isUpdate',
                record?.pageModuleId
              )
            }
          />
        );
      },
    },
    {
      title: () => {
        return (
          <span>
            Delete
            <Checkbox
              className='ml-1'
              onChange={(e) =>
                onPermissionChange(e?.target?.checked, 'isDelete')
              }
            />
          </span>
        );
      },
      dataIndex: 'isDelete',
      key: 'isDelete',
      align: 'center',
      width: '70px',
      render: (value, record) => {
        const [val] = permissions.filter(
          (permission) => record?.pageModuleId === permission?.pageModuleId
        );
        const isCheck = val?.isDelete;
        return (
          <Checkbox
            checked={isCheck}
            onChange={(e) =>
              onPermissionChange(
                e?.target?.checked,
                'isDelete',
                record?.pageModuleId
              )
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <InsideHeader className='add-edit-form' style={{ width: '700px' }}>
        <h1 className='font-size-28'>
          {operationName === 'add' ? 'Create New Role' : 'Edit Role'}
        </h1>
        <Form
          form={form}
          labelWrap={true}
          name='control-hooks'
          onFinish={onFinish}
        >
          <Form.Item
            name='name'
            label='Role Name'
            rules={[{ required: true, message: 'Please fill Rolename' }]}
          >
            <Input
              className='input-control'
              size='large'
              placeholder='Role name'
              autoFocus
            />
          </Form.Item>
          <Form.Item
            name='desc'
            label='Role Description'
            rules={[{ required: true, message: 'Please fill Description' }]}
          >
            <Input.TextArea
              placeholder='Descriptions'
              showCount
              maxLength={100}
            />
          </Form.Item>
          <Form.Item name='isAdmin' label='Is Admin'>
            <Checkbox
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e?.target?.checked)}
            ></Checkbox>
          </Form.Item>

          {!isAdmin && (
            <Form.Item
              label='Section Permissions'
              className='form-permission-table'
              style={{ width: '100%' }}
            >
              <div className='mt-1 fix-table'>
                <Table
                  tableLayout='auto'
                  columns={columns}
                  dataSource={permissions}
                  pagination={false}
                  rowKey='pageModuleId'
                />
              </div>
            </Form.Item>
          )}

          <div className='form-btn-group'>
            <ButtonCom
              className='width-180 mr-2'
              onClick={() => navigate('/admin-users/roles')}
            >
              Back
            </ButtonCom>
            <ButtonCom htmlType='submit' type='primary' className='width-180'>
              {operationName === 'add' ? 'create' : 'Edit'}
            </ButtonCom>
          </div>
        </Form>
      </InsideHeader>
    </>
  );
};

export default AddEditAdminRoles;
