import React from 'react';

const AdvertiseIcon = () => {
  return (
    <>
      <span className='anticon anticon-dashboard ant-menu-item-icon'>
        <svg
          width='20'
          height='20'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19 2H5C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2ZM5 0C2.23858 0 0 2.23858 0 5V19C0 21.7614 2.23858 24 5 24H19C21.7614 24 24 21.7614 24 19V5C24 2.23858 21.7614 0 19 0H5Z'
            fill='#656565'
          ></path>
          <path
            d='M9.99016 14.7605H7.37016L6.95016 16.0005H5.16016L7.70016 8.98047H9.68016L12.2202 16.0005H10.4102L9.99016 14.7605ZM9.55016 13.4405L8.68016 10.8705L7.82016 13.4405H9.55016Z'
            fill='#656565'
          ></path>
          <path
            d='M15.6232 8.98047C16.3632 8.98047 17.0099 9.12714 17.5632 9.42047C18.1165 9.7138 18.5432 10.1271 18.8432 10.6605C19.1499 11.1871 19.3032 11.7971 19.3032 12.4905C19.3032 13.1771 19.1499 13.7871 18.8432 14.3205C18.5432 14.8538 18.1132 15.2671 17.5532 15.5605C16.9999 15.8538 16.3565 16.0005 15.6232 16.0005H12.9932V8.98047H15.6232ZM15.5132 14.5205C16.1599 14.5205 16.6632 14.3438 17.0232 13.9905C17.3832 13.6371 17.5632 13.1371 17.5632 12.4905C17.5632 11.8438 17.3832 11.3405 17.0232 10.9805C16.6632 10.6205 16.1599 10.4405 15.5132 10.4405H14.7032V14.5205H15.5132Z'
            fill='#656565'
          ></path>
        </svg>
      </span>
    </>
  );
};

export default AdvertiseIcon;
