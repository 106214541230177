import React from 'react';

import { Col } from 'antd';

import Chart from 'react-google-charts';

import EmptyScreen from '../EmptyScreen';

const TotalUser = ({ totalUser, optionsForTotalUser, totalPlatformUser }) => {
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={10} xl={10} className=' mt-3 gutter-row'>
        <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
          <h2 className='font-weight-700 mb-0'>Total Users - Platforms wise</h2>
          <h2 className='font-weight-700 mb-0'>{totalPlatformUser}</h2>
          <div
            className={
              totalPlatformUser > 0
                ? 'total-user'
                : 'total-user d-flex align-items-center justify-content-center'
            }
          >
            {totalPlatformUser > 0 ? (
              <Chart
                chartType='PieChart'
                width='100%'
                height='400px'
                data={totalUser}
                options={optionsForTotalUser}
              />
            ) : (
              <EmptyScreen />
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

export default TotalUser;
