import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Form, Input, Upload, Checkbox } from 'antd';
import { toast } from 'react-toastify';

import InsideHeader from '../../components/InsideHeader/InsideHeader';
import ButtonCom from '../../components/Button/ButtonCom';

import { ARTISTS, ARTIST_ROLE } from '../../service/index';
import { BASE_URL } from '../../utility/constant';

const AddEditArtists = () => {
  const [roles, setRoles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [mediaItemId, setMediaItemId] = useState(null);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { id = null } = useParams();
  const operationName = id ? 'edit' : 'add';

  const fetchRoles = async () => {
    try {
      const res = await ARTIST_ROLE.GET();
      if (res.status === 1 || res.status === '1') {
        const roleOptions = res?.data?.map((role) => {
          return { label: role?.name, value: role?.id };
        });
        setRoles(roleOptions);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  const addEditArtists = async (values) => {
    delete values?.coverImage;

    const payload = {
      ...values,
      mediaItemId: mediaItemId,
      value: ' ',
    };

    let res;
    try {
      if (operationName === 'edit') {
        res = await ARTISTS.EDIT(id, payload);
      }
      if (operationName === 'add') {
        res = await ARTISTS.ADD(payload);
      }
      if (res.status === 1 || res.status === '1') {
        toast.success(res?.message);
        navigate('/artists');
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    const getEditData = async () => {
      try {
        const res = await ARTISTS.GET_BY_ID(id);
        if (res.status === 1) {
          const defaultRoles = res?.data?.artist_added_roles?.map((role) => {
            return role?.artist_role?.id;
          });

          form.setFieldsValue({
            ...res?.data,
            artist_added_roles: defaultRoles || [],
          });

          if (res?.data?.media_item?.image) {
            setFileList([
              {
                uid: '-1',
                name: 'cover image',
                status: 'done',
                url: res?.data?.media_item?.image,
                thubmUrl: res?.data?.media_item?.image,
              },
            ]);
            setMediaItemId(res?.data?.media_item?.id);
          }
        }
      } catch (err) {
        toast.error(err.message || 'something wen wrong');
      }
    };
    if (operationName === 'edit') {
      getEditData();
    }
  }, [form, id, operationName]);

  const props = {
    name: 'file',
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL}/uploads-image`,
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload(file) {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn('File Already Uploaded! Please delete that.');
        return false;
      }
      const type =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg';
      if (!type) {
        toast.error('Only .jpeg, .png, .jpg files are allowed!');
      }
      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === 'done') {
        setMediaItemId(info.file.response.data.media.id);
      } else if (info.file.status === 'error') {
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove(info) {
      setFileList([]);
      setMediaItemId(null);
    },
  };

  return (
    <>
      <InsideHeader className='add-edit-form'>
        <h1 className='font-size-28'>
          {operationName === 'add' ? 'Create New Artists' : 'Edit Artists'}
        </h1>
        <Form form={form} name='control-hooks' onFinish={addEditArtists}>
          <Form.Item
            name='name'
            label='Artists Name'
            rules={[{ required: true, message: 'please fill artists name' }]}
          >
            <Input className='input-control' allowClear />
          </Form.Item>
          <Form.Item
            valuePropName='filelist'
            name='coverImage'
            label='Cover Image'
          >
            <Upload accept='.png, .jpg, .jpeg' listType='picture' {...props}>
              <ButtonCom type='primary' icon={<UploadOutlined />}>
                Click to Upload
              </ButtonCom>
            </Upload>
          </Form.Item>
          <Form.Item
            name='bio'
            label='Biography'
            rules={[
              { required: true, message: 'please write about biography' },
            ]}
          >
            <Input.TextArea showCount allowClear maxLength={100} />
          </Form.Item>
          <Form.Item
            name='artist_added_roles'
            label='Roles'
            rules={[{ required: true, message: 'please select any roles' }]}
          >
            <Checkbox.Group
              options={roles}
              className='checkbox-row'
              style={{ marginLeft: '-8px' }}
            ></Checkbox.Group>
          </Form.Item>
          <div className='form-btn-group'>
            <ButtonCom
              className='width-180 mr-2'
              onClick={() => navigate('/artists')}
            >
              Back
            </ButtonCom>
            <ButtonCom type='primary' htmlType='submit' className='width-180'>
              {operationName === 'add' ? 'Create' : 'Edit'}
            </ButtonCom>
          </div>
        </Form>
      </InsideHeader>
    </>
  );
};

export default AddEditArtists;
