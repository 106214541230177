import React from 'react';

import { Button } from 'antd';

const ButtonCom = ({ children, className, ...otherProps }) => {
  return (
    <Button className={`${className ? className : ''}`} {...otherProps}>
      {children}
    </Button>
  );
};

export default ButtonCom;
