import React, { useState } from 'react';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Table, Tooltip } from 'antd';

import { SECTION } from '../../service/index';
import ButtonCom from '../Button/ButtonCom';
import DeleteModel from '../DeleteModel';

const Section = ({ id, value, fetchSubTabs, subTabs, permissions }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();

  const deleteSection = async () => {
    try {
      const data = await SECTION.REMOVE_SECTION(deleteId);
      if (data.status === 1 || data.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        fetchSubTabs(id);
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something when wrong !');
    }
  };

  const columns = [
    {
      title: 'Section Name',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Position No.',
      dataIndex: 'positionNo',
      align: 'center',
      width: 150,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      width: 160,
      render: (_, record) => {
        return (
          <>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Section'>
                <EditOutlined
                  className='font-size-20 edit-btn-hover cursor-pointer mx-1'
                  onClick={() => navigate(`/sections/edit/${record.id}`)}
                />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Section'>
                <DeleteOutlined
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record.id);
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  if (permissions?.isUpdate === false && permissions?.isDelete === false) {
    delete columns[2];
  }

  return (
    <>
      {subTabs &&
        subTabs.length &&
        subTabs.map((subTab) => {
          return (
            <div className='mt-2' key={subTab.id}>
              <div className='d-flex  justify-content-between align-items-center mb-1'>
                <h1 className='font-weight-500'>{subTab.name}</h1>
                <ButtonCom
                  type='primary'
                  onClick={() =>
                    navigate(
                      `/sections/manage/${subTab.id}?tabId=${id}&tabValue=${value}`
                    )
                  }
                >
                  Manage Section
                </ButtonCom>
              </div>
              <div className='fix-table box-shadow-3 border-radius-5'>
                <Table
                  className='small-table'
                  dataSource={subTab.sections}
                  columns={columns}
                  pagination={false}
                  rowKey='id'
                />
              </div>
            </div>
          );
        })}

      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteSection}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </>
  );
};
export default Section;
