import React, { useState, useRef, useEffect } from 'react';

import { Row, Col, DatePicker, Checkbox, Input, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import { tableSortIconToggleDefault } from '../../../utility/tableSorting';
import PaginationCom from '../../../components/PaginationCom';
import Spinner from '../../../components/Spinner';
import { REPORTS } from '../../../service';
import {
  ExportExcel,
  ExportPdf,
  handleFileName,
} from '../../../utility/Export/Export';

const { RangePicker } = DatePicker;

const NotificationTab = () => {
  const [notifications, setNotifications] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState(null);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sortedInfo, setSortedInfo] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueOfRangePicker, setValueOfRangePicker] = useState(null);

  const [ios, setIos] = useState(null);
  const [android, setAndroid] = useState(null);

  const [spinnerValue, setSpinnerValue] = useState(false);

  const [csv, setCsv] = useState([]);

  const csvLink = useRef();

  const headers = [
    { label: 'Notification TItle', key: 'title' },
    { label: 'Publish Date', key: 'publish_date' },
    { label: 'Total Send', key: 'totalSend' },
    { label: 'Success', key: 'success' },
    { label: 'Failed', key: 'failed' },
    { label: 'Android', key: 'platformAndroid' },
    { label: 'iOS', key: 'platformIPhone' },
  ];

  const fetchNotifications = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    startDate = null,
    endDate = null
  ) => {
    setLoading(true);
    try {
      const res = await REPORTS.REPORTS_NOTIFICATION(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        startDate,
        endDate
      );
      if (res.status === 1 || res.status === '1') {
        setNotifications(res);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const handleSorting = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
      setAndroid(false);
      setIos(false);
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
      if (sorter?.field === 'platformAndroid') {
        setAndroid(true);
      }
      if (sorter?.field === 'platformIPhone') {
        setIos(true);
      }
    } else {
      setSort(null);
      if (sorter?.field === 'platformAndroid') {
        setAndroid(false);
      }
      if (sorter?.field === 'platformIPhone') {
        setIos(false);
      }
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const exportData = async () => {
    setSpinnerValue(true);
    try {
      const res = await REPORTS.REPORTS_NOTIFICATION(
        '-1',
        0,
        search,
        sort,
        startDate,
        endDate
      );
      if (res.status === 1 || res.status === '1') {
        return res;
      }
    } catch (error) {
      setSpinnerValue(false);
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setSpinnerValue(false);
    }
  };

  const generatePDF = async () => {
    let res = await exportData();
    const pdfTableColumn = [
      'Notification Title',
      'Publish Date',
      'Total Send',
      'Success',
      'Failed',
      'Android',
      'iOS',
    ];
    const pdfTableRows = [];
    res?.data.forEach((item) => {
      const notificationReportsListData = [
        item.title,
        item.publish_date,
        item.totalSend,
        item.success,
        item.failed,
        item.platformAndroid,
        item.platformIPhone,
      ];
      pdfTableRows.push(notificationReportsListData);
    });
    ExportPdf(pdfTableColumn, pdfTableRows, 'notificationReport');
  };

  const generateExcel = async () => {
    const res = await exportData();
    const temp = res?.data.map((item) => {
      return {
        'Notification Title': item.title,
        'Publish Date': item.publish_date,
        'Total Send': item.totalSend,
        Success: item.success,
        Failed: item.failed,
        Android: item.platformAndroid,
        iOS: item.platformIPhone,
      };
    });
    ExportExcel(temp, 'notificationReport');
  };

  const handleDownloadCsv = async () => {
    const res = await exportData();
    const temp = res?.data.map((item) => {
      return {
        title: item.title,
        publish_date: item.publish_date,
        totalSend: item.totalSend,
        success: item.success,
        failed: item.failed,
        platformAndroid: item.platformAndroid,
        platformIPhone: item.platformIPhone,
      };
    });
    setCsv([...temp]);
    csvLink.current.link.click();
  };

  const handleChangeOfSearch = (e) => {
    if (!e?.target?.value) {
      setSearch(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const enterSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const handleChangeOfAndroid = (e) => {
    setCurrentPage(1);
    setAndroid(e.target.checked);
    setIos(false);
    if (e.target.checked) {
      setSort({
        platformAndroid: -1,
      });
      setSortedInfo({
        order: 'descend',
        columnKey: 'platformAndroid',
      });
    } else {
      setSort(null);
      setSortedInfo({});
    }
  };

  const handleChangeOfIos = (e) => {
    setCurrentPage(1);
    setIos(e.target.checked);
    setAndroid(false);
    if (e.target.checked) {
      setSort({
        platformIPhone: -1,
      });
      setSortedInfo({
        order: 'descend',
        columnKey: 'platformIPhone',
      });
    } else {
      setSort(null);
      setSortedInfo({});
    }
  };

  const handleChangeOfRangePicker = (e) => {
    setCurrentPage(1);
    setValueOfRangePicker(e);
    if (e) {
      setStartDate(
        new Date(e[0]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
      setEndDate(
        new Date(e[1]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const clearAllFilter = () => {
    setCurrentPage(1);
    setPageSize(10);
    setSearch(null);
    setTempSearch(null);
    setSort(null);
    setStartDate(null);
    setEndDate(null);
    setValueOfRangePicker(null);
    setAndroid(null);
    setIos(null);
    setSortedInfo({});
  };

  useEffect(() => {
    fetchNotifications(currentPage, pageSize, search, sort, startDate, endDate);
  }, [currentPage, pageSize, search, sort, startDate, endDate]);

  const columns = [
    {
      title: (column) =>
        tableSortIconToggleDefault('Notification Title', 'title', column),
      dataIndex: 'title',
      key: 'title',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'title' ? sortedInfo.order : undefined,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggleDefault('Date', 'publish_date', column),
      dataIndex: 'publish_date',
      key: 'publish_date',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'publish_date' ? sortedInfo.order : undefined,
      render: (_, record) => {
        const date = record.publish_date;
        const formattedDate = moment(date).format('DD-MM-YYYY  h:mm:ss a');
        return formattedDate;
      },
      width: 210,
    },
    {
      title: (column) => tableSortIconToggleDefault('Total Sent', 'totalSend', column),
      key: 'totalSend',
      dataIndex: 'totalSend',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'totalSend' ? sortedInfo.order : undefined,
      width: '140px',
    },
    {
      title: (column) => tableSortIconToggleDefault('Success', 'success', column),
      key: 'success',
      dataIndex: 'success',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'success' ? sortedInfo.order : undefined,
      width: '120px',
    },
    {
      title: (column) => tableSortIconToggleDefault('Failed', 'failed', column),
      key: 'failed',
      dataIndex: 'failed',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'failed' ? sortedInfo.order : undefined,
      width: '120px',
    },
    {
      title: (column) => tableSortIconToggleDefault('iOS', 'platformIPhone', column),
      dataIndex: 'platformIPhone',
      key: 'platformIPhone',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'platformIPhone'
          ? sortedInfo.order
          : undefined,
      render: (value) => {
        return <Checkbox disabled checked={value} />;
      },
      width: '120px',
    },
    {
      title: (column) =>
        tableSortIconToggleDefault('Android', 'platformAndroid', column),
      dataIndex: 'platformAndroid',
      key: 'platformAndroid',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: notifications?.data.length > 1 ? true : false,
      sortOrder:
        sortedInfo.columnKey === 'platformAndroid'
          ? sortedInfo.order
          : undefined,
      render: (value) => {
        return <Checkbox disabled checked={value} />;
      },
      width: '120px',
    },
  ];

  return (
    <>
      {/* Inside Header */}
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <div className='bg-white box-shadow-3 border-radius-5 px-3 pt-3 pb-1'>
            <Row gutter={20} className='align-items-center'>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <Input
                  type='search'
                  placeholder='Start Typing to find Notification'
                  onChange={(e) => handleChangeOfSearch(e)}
                  onKeyPress={(e) => {
                    if (e?.key === 'Enter' && e.target.value) {
                      enterSearch();
                    }
                  }}
                  suffix={
                    <SearchOutlined
                      style={{ color: '#8f9bb3' }}
                      onClick={enterSearch}
                    />
                  }
                  value={tempSearch}
                  className='input-control'
                  allowClear
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <div className='d-flex align-items-center'>
                  <h3 className='font-size-16 mb-0 mr-1'>Android</h3>
                  <Checkbox
                    onChange={(e) => handleChangeOfAndroid(e)}
                    checked={android}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <div className='d-flex align-items-center'>
                  <h3 className='font-size-16 mb-0 mr-1'>iOS</h3>
                  <Checkbox
                    onChange={(e) => handleChangeOfIos(e)}
                    checked={ios}
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className='gutter-row mb-1'
              >
                <RangePicker
                  className='input-control w-100'
                  onChange={(e) => handleChangeOfRangePicker(e)}
                  value={valueOfRangePicker}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={16}
                xl={16}
                className='gutter-row'
              >
                <div className='d-flex flex-wrap align-items-center'>
                  <div className='mb-1 mr-2 bg-white font-weight-600'>
                    Export
                  </div>
                  <div>
                    <button
                      className='mb-1 bg-light-gray font-weight-600 border-0 border-radius-3 py-8px px-2 mr-1 cursor-pointer'
                      onClick={handleDownloadCsv}
                    >
                      CSV
                    </button>
                    <CSVLink
                      data={csv}
                      headers={headers}
                      filename={handleFileName('notificationReport', 'csv')}
                      ref={csvLink}
                      className='text-black font-weight-600'
                    ></CSVLink>
                  </div>
                  <button
                    className='mb-1 bg-light-gray font-weight-600 border-0 border-radius-3 py-8px px-2 mr-1 cursor-pointer'
                    onClick={generateExcel}
                  >
                    Excel
                  </button>
                  <button
                    onClick={() => generatePDF()}
                    className='mb-1 bg-light-gray font-weight-600 border-0 border-radius-3 py-8px px-2 mr-1 cursor-pointer'
                  >
                    Pdf
                  </button>
                  <h3 className='clear-all mt-1' onClick={clearAllFilter}>
                    Clear All
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* Table */}
      <div className='fix-table mt-3 border-radius-5 box-shadow-3'>
        <Table
          columns={columns}
          dataSource={notifications?.data}
          pagination={false}
          rowKey='id'
          loading={loading}
          onChange={handleSorting}
        />

        <div className='bg-white p-2 width-1120 d-flex justify-content-between align-items-center'>
          <p className='mb-0'>
            {notifications?.total || 0} - Total Notifications
          </p>
          {notifications?.total > 10 && (
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={notifications?.total}
            />
          )}
        </div>

        {spinnerValue && <Spinner />}
      </div>
    </>
  );
};

export default NotificationTab;
