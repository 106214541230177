import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';

import { HashRouter } from 'react-router-dom';

import { store } from './redux/store';
import { Provider } from 'react-redux';

import App from './App';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.variable.min.css';
import '../src/assets/css/style.scss';

import { ConfigProvider } from 'antd';

ConfigProvider.config({
  theme: {
    primaryColor: '#9F52F4',
    errorColor: 'red',
    // warningColor: '#9f52f4',
    // successColor: '#9F52F4',
    // infoColor: '#9F52F4',
  },
});

ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </HashRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
