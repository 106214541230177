import React from 'react';

import { Col } from 'antd';

import Chart from 'react-google-charts';

import EmptyScreen from '../EmptyScreen';

const TotalMedia = ({ totalMedia, media, optionsForMedia }) => {
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} className='mt-3 gutter-row'>
        <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
          <h2 className='font-weight-700 mb-0'>Total Media</h2>
          <h2 className='font-weight-700 mb-0'>{totalMedia}</h2>
          <div className='total-media d-flex align-items-center justify-content-center'>
            {totalMedia > 0 ? (
              <Chart
                chartType='PieChart'
                width='100%'
                height='400px'
                data={media}
                options={optionsForMedia}
              />
            ) : (
              <EmptyScreen />
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

export default TotalMedia;
