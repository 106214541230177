import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Select, DatePicker, Input, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ALBUMS, REPORTS } from '../../../service';
import PaginationCom from '../../../components/PaginationCom';
import { toast } from 'react-toastify';
import { tableSortIconToggleDefault } from '../../../utility/tableSorting';
import {
  ExportExcel,
  ExportPdf,
  handleFileName,
} from '../../../utility/Export/Export';
import { CSVLink } from 'react-csv';
import Spinner from '../../../components/Spinner';
const { Option } = Select;

const SubCategoryTab = () => {
  const [categroyPageSize, setCategroyPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [categoryValue, setCategoryValue] = useState(null);
  const [subCategory, setSubCategory] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState();
  const [sort, setSort] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueOfStartDate, setValueOfStartDate] = useState(null);
  const [valueOfEndDate, setValueOfEndDate] = useState(null);
  const [categoryList, setCategoryList] = useState({});
  const [categoryId, setCategoryId] = useState(null);
  const [spinnerValue, setSpinnerValue] = useState(false);

  const [csv, setCsv] = useState([]);
  const csvLink = useRef();
  const headers = [
    { label: 'Sub-Category Name', key: 'subCategoryName' },
    { label: 'Album Count', key: 'album_count' },
  ];

  const getSubCategory = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    categoryId = null,
    startDate = null,
    endDate = null
  ) => {
    setLoading(true);
    try {
      const res = await REPORTS.REPORTS_SUBCATEGORY(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        categoryId,
        startDate,
        endDate
      );
      console.log('SubCategory Report Response ', res);
      if (res.status === 1 || res.status === '1') {
        setSubCategory(res);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const getCategory = async (pageSize = 10, search = null) => {
    try {
      const res = await ALBUMS.GET_CATEGORY_OF_ALBUMS(
        pageSize,
        (1 - 1) * pageSize,
        search
      );
      if (res.status === 1 || res.status === '1') {
        setCategoryList(res);
      }
    } catch (err) {
      toast.error(err.message || 'Something went wrong!');
    }
  };

  //sorting
  function onChange(sorter) {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  }

  //pagination
  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    getSubCategory(page, pageSize, search, sort, startDate, endDate);
  };

  useEffect(() => {
    getSubCategory(
      currentPage,
      pageSize,
      search,
      sort,
      categoryId,
      startDate,
      endDate
    );
  }, [currentPage, pageSize, search, sort, categoryId, startDate, endDate]);

  useEffect(() => {
    getCategory();
  }, []);

  const exportData = async () => {
    try {
      const res = await REPORTS.REPORTS_SUBCATEGORY(
        '-1',
        0,
        null,
        sort,
        categoryId,
        startDate,
        endDate
      );
      console.log('Report Sub Category Response ', res);
      if (res.status === 1 || res.status === '1') {
        return res;
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const generatePDF = async () => {
    setSpinnerValue(true);
    let res = await exportData();
    const pdftableColumn = ['Sub-Category Name', 'Album Count'];
    const pdftableRows = [];
    res?.data.forEach((item) => {
      const subCategoryReportsListData = [
        item.subCategoryName,
        item.album_count,
      ];
      pdftableRows.push(subCategoryReportsListData);
    });
    ExportPdf(pdftableColumn, pdftableRows, 'subCategoryReport');
    setSpinnerValue(false);
  };

  const generateExcel = async () => {
    setSpinnerValue(true);
    const res = await exportData();
    const temp = res?.data.map((item) => {
      return {
        'Sub-Category Name': item.subCategoryName,
        'Album Count': item.album_count,
      };
    });
    ExportExcel(temp, 'subCategoryReport');
    setSpinnerValue(false);
  };

  const handleDownloadCsv = async () => {
    setSpinnerValue(true);
    const res = await exportData();
    const temp = res?.data.map((item) => {
      return {
        subCategoryName: item.subCategoryName,
        album_count: item.album_count,
      };
    });
    setCsv([...temp]);
    csvLink.current.link.click();
    setSpinnerValue(false);
  };

  const columns = [
    {
      title: (column) =>
        tableSortIconToggleDefault('Sub-Category', 'subCategoryName', column),
      key: 'subCategoryName',
      dataIndex: 'subCategoryName',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.subCategoryName.localeCompare(b.subCategoryName),
      width: '160px',
    },
    {
      title: ' Albums Count',
      key: 'album_count',
      dataIndex: 'album_count',
      align: 'center',
      width: '160px',
    },
    {
      title: ' Song',
      key: ' ',
      dataIndex: ' ',
      align: 'center',
      width: '160px',
    },
    {
      title: 'Played Count',
      key: ' ',
      dataIndex: ' ',
      align: 'center',
      width: '160px',
    },
  ];

  return (
    <>
      {/* Inside Header */}
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <div className='bg-white box-shadow-3 border-radius-5 p-3 '>
            <Row gutter={20}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className='gutter-row mb-1'
              >
                <DatePicker
                  className='input-control w-100'
                  placeholder='Start Date'
                  onChange={(e) => {
                    let tempStartDate;
                    if (e) {
                      tempStartDate = new Date(e?._d).toLocaleString('en-IN', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      });
                    }
                    setStartDate(tempStartDate);
                    setValueOfStartDate(e);
                  }}
                  value={valueOfStartDate}
                />
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={6}
                className='gutter-row mb-1'
              >
                <Select
                  maxTagCount='responsive'
                  style={{ width: '100%' }}
                  showSearch
                  onSearch={(e) => {
                    let tempCategoryPageSize = categroyPageSize;
                    tempCategoryPageSize = 20;
                    setCategroyPageSize(tempCategoryPageSize);
                    getCategory(10, e);
                  }}
                  onChange={(e) => {
                    setCategoryValue(e);
                    const findId = categoryList?.data.filter(
                      (item) => item.categoryName === e
                    )[0]?.id;
                    setCategoryId(null);
                    getSubCategory(findId);
                    setCategoryId(findId);
                  }}
                  placeholder='Select Category'
                  size='large'
                  onPopupScroll={(e) => {
                    if (
                      e.target.scrollTop + e.target.offsetHeight ===
                      e.target.scrollHeight
                    ) {
                      let t = Math.ceil(categoryList.total / 10);
                      t = `${t}0`;
                      if (categroyPageSize <= t) {
                        setCategroyPageSize(categroyPageSize + 10);
                        getCategory(categroyPageSize);
                      }
                    }
                  }}
                  value={categoryValue}
                  allowClear
                >
                  {categoryList &&
                    categoryList?.data?.length > 0 &&
                    categoryList?.data?.map((item) => {
                      return (
                        <Option
                          key={item.id}
                          value={item.categoryName}
                          className='font-size-13'
                        >
                          {item.categoryName}
                        </Option>
                      );
                    })}
                </Select>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={6}
                className='gutter-row mb-1'
              >
                <Input
                  placeholder='Start Typing Search Sub-Category'
                  onChange={(e) => {
                    if (!e?.target?.value) {
                      setSearch(null);
                      getSubCategory(
                        1,
                        pageSize,
                        search,
                        sort,
                        categoryId,
                        startDate,
                        endDate
                      );
                    } else {
                      setSearch(e?.target?.value);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e?.key === 'Enter' && e.target.value) {
                      setCurrentPage(1);
                      getSubCategory(
                        1,
                        pageSize,
                        search,
                        sort,
                        categoryId,
                        startDate,
                        endDate
                      );
                    }
                  }}
                  suffix={
                    <SearchOutlined
                      style={{ color: '#8f9bb3' }}
                      onClick={() => {
                        if (search) {
                          setCurrentPage(1);
                          getSubCategory(
                            1,
                            pageSize,
                            search,
                            sort,
                            categoryId,
                            startDate,
                            endDate
                          );
                        }
                      }}
                    />
                  }
                  value={search}
                  className='input-control mb-1'
                />
              </Col>
            </Row>

            <Row gutter={25}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className='gutter-row mb-1'
              >
                <DatePicker
                  className='input-control w-100'
                  placeholder='End Date'
                  onChange={(e) => {
                    let tempEndDate;
                    if (e) {
                      tempEndDate = new Date(e._d).toLocaleString('en-IN', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      });
                    }
                    setEndDate(tempEndDate);
                    setValueOfEndDate(e);
                  }}
                  value={valueOfEndDate}
                />
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className='gutter-row mb-1'
              >
                <div className='d-flex flex-wrap'>
                  <div className='mb-1 mr-2 mt-1 bg-white font-weight-600'>
                    Export
                  </div>
                  <div>
                    {spinnerValue ? <Spinner /> : ''}
                    <button
                      className='mb-1 bg-light-gray font-weight-600 border-0 border-radius-3 py-8px px-2 mr-1 cursor-pointer'
                      onClick={handleDownloadCsv}
                    >
                      CSV
                    </button>
                    <CSVLink
                      data={csv}
                      headers={headers}
                      filename={handleFileName('subCategoryReport', 'csv')}
                      ref={csvLink}
                      className='text-black font-weight-600'
                    ></CSVLink>
                  </div>
                  <button
                    className='mb-1 bg-light-gray font-weight-600 border-0 border-radius-3 py-8px px-2 mr-1 cursor-pointer'
                    onClick={generateExcel}
                  >
                    Excel
                  </button>
                  <button
                    className='mb-1 bg-light-gray font-weight-600 border-0 border-radius-3 py-8px px-2 mr-1 cursor-pointer'
                    onClick={() => generatePDF()}
                  >
                    Pdf
                  </button>
                  <h3
                    className='text-blue text-decoration-underline cursor-pointer ml-2 mt-1'
                    onClick={() => {
                      setPageSize(10);
                      setCurrentPage(1);
                      setSearch(null);
                      setSort(null);
                      setValueOfStartDate(null);
                      setStartDate(null);
                      setValueOfEndDate(null);
                      setEndDate(null);
                      setCategoryValue(null);
                      setCategoryId(null);
                    }}
                  >
                    Clear All
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* Tables Data */}
      <div className='fix-table mt-3 box-shadow-3 border-radius-5'>
        <Table
          columns={columns}
          loading={loading}
          dataSource={subCategory?.data}
          pagination={false}
          rowKey='id'
          onChange={onChange} //sorting
        />
        {/* Pagination  */}
        {subCategory?.total > 10 && (
          <div className='text-center bg-white p-3 width-1120'>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={subCategory?.total}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SubCategoryTab;
