import { methods, serviceMaker } from '../service';

const GET_ALBUMS = (
  limit,
  skip,
  search = null,
  sort = null,
  categoryId = null,
  subcategoryId = null,
  isdownloadable = null,
  startDate = null,
  endDate = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (categoryId && !subcategoryId) {
    query['params']['categoryId'] = categoryId;
  }
  if (subcategoryId) {
    query['params']['subcategoryId'] = subcategoryId;
  }
  if (isdownloadable) {
    query['params']['isdownloadable'] = isdownloadable;
  }
  if (isdownloadable === false) {
    query['params']['isdownloadable'] = isdownloadable;
  }
  if (startDate) {
    query['params']['startDate'] = startDate;
  }
  if (endDate) {
    query['params']['endDate'] = endDate;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`albums`, methods.GET, query);
};

const REMOVE_ALBUMS = (id) => serviceMaker(`albums/${id}`, methods.DELETE);

const MULTIPLE_DELETE_ALBUMS = (payload) => {
  const query = {
    params: {
      'id[$in]': payload,
    },
  };
  return serviceMaker(`albums`, methods.DELETE, query);
};

const CREATE_ALBUMS = (payload) =>
  serviceMaker(`albums`, methods.POST, null, payload);

const SINGLE_ALBUMS = (id) => serviceMaker(`albums/${id}`, methods.GET);

const PATCH_ALBUMS = (id, payload) =>
  serviceMaker(`albums/${id}`, methods.PATCH, null, payload);

const ALL_ALBUMS = (limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`albums`, methods.GET, query);
};

const GET_ARTISTS = (limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`artists`, methods.GET, query);
};

const GET_SINGLE_ARTIST = (id) => serviceMaker(`artists/${id}`, methods.GET);

const GET_CATEGORY_OF_ALBUMS = (limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`category`, methods.GET, query);
};

const GET_SINGLE_CATEGORY = (id) => serviceMaker(`category/${id}`, methods.GET);

const GET_SUB_CATEGORY_OF_ALBUMS = (id, limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
      categoryId: id,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`subcategory`, methods.GET, query);
};

const GET_SINGLE_SUB_CATEGORY = (id) =>
  serviceMaker(`subcategory/${id}`, methods.GET);

const REMOVE_MEDIA_SOUND = (id, payload) =>
  serviceMaker(`media-sound/${id}`, methods.PATCH, null, payload);

export const ALBUMS = {
  GET_ALBUMS,
  REMOVE_ALBUMS,
  ALL_ALBUMS,
  CREATE_ALBUMS,
  SINGLE_ALBUMS,
  PATCH_ALBUMS,
  GET_ARTISTS,
  GET_CATEGORY_OF_ALBUMS,
  GET_SUB_CATEGORY_OF_ALBUMS,
  REMOVE_MEDIA_SOUND,
  GET_SINGLE_ARTIST,
  GET_SINGLE_CATEGORY,
  GET_SINGLE_SUB_CATEGORY,
  MULTIPLE_DELETE_ALBUMS,
};
