import React, { useEffect, useState } from 'react';

import { Row, Col, Input, Table, Checkbox, DatePicker } from 'antd';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { toast } from 'react-toastify';

import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import PaginationCom from '../../../../components/PaginationCom';
import ButtonCom from '../../../../components/Button/ButtonCom';
import SelectWithPagination from '../../../../components/SelectWithPagination';
import TableImage from '../../../../components/Image/TableImage';
import { tableSortIconToggle } from '../../../../utility/tableSorting';
import { ALBUMS } from '../../../../service';

const { RangePicker } = DatePicker;

const SelectAlbum = ({ setCurrent }) => {
  const [albumsData, setAlbumsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [sort, setSort] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueOfRangePicker, setValueOfRangePicker] = useState(null);

  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [isdownloadable, setIsdownloadable] = useState(null);

  const [tempSearch, setTempSearch] = useState(null);

  const [isShowFilter, setIsShowFilter] = useState(false);

  const [rowSelect, setRowSelect] = useState([]);
  const [rowSelectRecord, setRowSelectRecord] = useState([]);

  const getListOfAlbums = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    categoryId = null,
    subcategoryId = null,
    isdownloadable = null,
    startDate = null,
    endDate = null
  ) => {
    setLoading(true);
    try {
      const res = await ALBUMS.GET_ALBUMS(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        categoryId,
        subcategoryId,
        isdownloadable,
        startDate,
        endDate
      );
      if (res.status === 1 || res.status === '1') {
        setAlbumsData(res);
      }
    } catch (err) {
      toast.error(err.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const handleChangeOfRangePicker = (e) => {
    setCurrentPage(1);
    setValueOfRangePicker(e);
    if (e) {
      setStartDate(
        new Date(e[0]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
      setEndDate(
        new Date(e[1]._d).toLocaleString('en-IN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const loadOptionsOfCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.GET_CATEGORY_OF_ALBUMS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.categoryName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const loadOptionsOfSubCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.GET_SUB_CATEGORY_OF_ALBUMS(
      category?.value,
      10,
      skip,
      search
    );
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.subCategoryName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleChangeOfDownloadable = (e) => {
    setCurrentPage(1);
    setIsdownloadable(e.target.checked);
  };

  const clearAllFilter = () => {
    setCurrentPage(1);
    setPageSize(10);
    setValueOfRangePicker(null);
    setStartDate(null);
    setEndDate(null);
    setCategory(null);
    setSubCategory(null);
    setIsdownloadable(null);
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const enterSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: rowSelect,
    onChange: (selectedRowKeys, selectRowRecord) => {
      setRowSelect(selectedRowKeys);
      setRowSelectRecord(selectRowRecord);
    },
  };

  const handleBack = () => {
    setCurrent(0);
  };

  const handleNext = () => {
    if (rowSelectRecord.length <= 0) {
      toast.warn('Select album is requried !');
    } else {
      setCurrent(2);
      localStorage.setItem('add-album-2', JSON.stringify(rowSelect));
      localStorage.setItem('album-record', JSON.stringify(rowSelectRecord));
    }
  };

  useEffect(() => {
    const value = JSON.parse(localStorage.getItem('add-album-2')) || [];
    const album = JSON.parse(localStorage.getItem('album-record')) || [];
    setRowSelect(value);
    setRowSelectRecord(album);
  }, []);

  useEffect(() => {
    getListOfAlbums(
      currentPage,
      pageSize,
      search,
      sort,
      category?.value,
      subCategory?.value,
      isdownloadable,
      startDate,
      endDate
    );
  }, [
    category?.value,
    currentPage,
    endDate,
    isdownloadable,
    pageSize,
    search,
    sort,
    startDate,
    subCategory?.value,
  ]);

  const columns = [
    {
      title: 'Cover Image',
      dataIndex: 'media_item',
      key: 'media_item',
      align: 'center',
      render: (media_item) => <TableImage image={media_item?.image} />,
      width: 130,
    },
    {
      title: (column) =>
        tableSortIconToggle('Albums Title', 'albumName', column),
      dataIndex: 'albumName',
      key: 'albumName',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumsData?.data?.length > 1 ? true : false,
    },
    {
      title: (column) => tableSortIconToggle('Subtitle', 'subtitle', column),
      dataIndex: 'subtitle',
      key: 'Subtitle',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumsData?.data?.length > 1 ? true : false,
    },
    {
      title: (column) =>
        tableSortIconToggle('Downloadable', 'isdownloadable', column),
      dataIndex: 'isdownloadable',
      key: 'isdownloadable',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumsData?.data?.length > 1 ? true : false,
      render: (isdownloadable) => {
        return (
          <span>
            <Checkbox checked={isdownloadable} disabled />
          </span>
        );
      },
      width: 170,
    },
    {
      title: (column) =>
        tableSortIconToggle('Release Date', 'releasedate', column),
      key: 'releasedate',
      dataIndex: 'releasedate',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: albumsData?.data?.length > 1 ? true : false,
      width: 160,
      render: (releasedate) => {
        return (
          <span>
            {new Date(releasedate).toLocaleString('en-US', {
              dateStyle: 'medium',
            })}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Row justify='center' gutter={0} className='mt-4'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <InsideHeader>
            <h3 className='mb-0 font-size-25'>
              Select Album(s) For Adding Songs
            </h3>
            <Row gutter={0} className='align-items-center mt-1'>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={13}
                xl={13}
                className='gutter-row mb-1'
              >
                <Input
                  placeholder='Start Typing Search Albums'
                  suffix={
                    <SearchOutlined
                      style={{ color: '#8f9bb3' }}
                      onClick={enterSearch}
                    />
                  }
                  onChange={(e) => handleChangeOfSearch(e)}
                  onKeyPress={(e) => {
                    if (e?.key === 'Enter' && e.target.value) {
                      enterSearch();
                    }
                  }}
                  className='input-control'
                  value={tempSearch}
                  allowClear
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={11}
                xl={11}
                className='gutter-row text-end text-xs-left mb-1 '
              >
                <ButtonCom
                  type='primary'
                  onClick={() => setIsShowFilter(!isShowFilter)}
                >
                  <div className='d-flex align-items-center'>
                    Album Filters
                    <FilterFilled className='ml-1' />
                  </div>
                </ButtonCom>
              </Col>
            </Row>
          </InsideHeader>
        </Col>
      </Row>

      {isShowFilter && (
        <InsideHeader className='mt-3 pt-2 pb-1'>
          <Row gutter={20} className='align-items-center'>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <RangePicker
                className='input-control w-100'
                onChange={(e) => handleChangeOfRangePicker(e)}
                value={valueOfRangePicker}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <SelectWithPagination
                placeholder='Select Category'
                loadOptions={loadOptionsOfCategory}
                onChange={(e) => {
                  setCurrentPage(1);
                  setCategory(e);
                  setSubCategory(null);
                }}
                additional={{
                  skip: 0,
                }}
                value={category}
                isClearable
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <SelectWithPagination
                key={category?.value}
                placeholder='Select Sub-Category'
                loadOptions={loadOptionsOfSubCategory}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSubCategory(e);
                }}
                additional={{
                  skip: 0,
                  categoryId: category?.value,
                }}
                value={subCategory}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <div className='d-flex align-items-center'>
                <h3 className='font-size-16 mb-0 mr-1'>Downloadable</h3>
                <Checkbox
                  className='h-25px'
                  onChange={(e) => handleChangeOfDownloadable(e)}
                  checked={isdownloadable}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <h3 className='clear-all' onClick={clearAllFilter}>
                Clear All
              </h3>
            </Col>
          </Row>
        </InsideHeader>
      )}

      {/* Multiple Select */}
      {rowSelect.length > 0 && (
        <div className='mt-3'>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <h3 className='mb-0'>{rowSelect.length} - Selected Albums</h3>
            </div>
            <div>
              <ButtonCom
                type='primary'
                className='mr-1'
                onClick={() => {
                  setRowSelect([]);
                  setRowSelectRecord([]);
                  localStorage.removeItem('add-album-2');
                  localStorage.removeItem('album-record');
                }}
              >
                Deselect Selected Albums
              </ButtonCom>
              <ButtonCom type='primary' onClick={handleNext}>
                Review
              </ButtonCom>
            </div>
          </div>
        </div>
      )}

      <Row justify='center' gutter={0}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <div className='fix-table mt-3 box-shadow-3'>
            <Table
              rowSelection={{
                type: <Checkbox />,
                ...rowSelection,
              }}
              dataSource={albumsData?.data}
              columns={columns}
              pagination={false}
              rowKey='id'
              onChange={onChange}
              loading={loading}
            />

            <div className='text-center bg-white p-2 width-1120 d-flex justify-content-between align-items-center'>
              <p className='mb-0'>{albumsData?.total || 0} - Total Albums</p>
              <ButtonCom className='width-180' onClick={handleBack}>
                Back
              </ButtonCom>
              {albumsData?.total > 10 && (
                <PaginationCom
                  onChange={onChangePage}
                  showSizeChanger={true}
                  current={currentPage}
                  showLessItems={true}
                  total={albumsData?.total}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SelectAlbum;
