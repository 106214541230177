import React, { useEffect, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import {
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Upload,
} from 'antd';

import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import SelectWithPagination from '../../../../components/SelectWithPagination';
import ButtonCom from '../../../../components/Button/ButtonCom';
import { ALBUMS, SECTION } from '../../../../service/index';
import SelectDropDown from '../../../../components/Select';
import { BASE_URL } from '../../../../utility/constant';

const CreateNewItem = () => {
  const [tab, setTab] = useState([]);

  const [subTab, setSubTab] = useState([]);

  const [itemType, setItemType] = useState(null);
  const [fileList, setFileList] = useState([]);

  const { id, add } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();


  const [form] = Form.useForm();

  const loadOptionsOfSection = async (search, prevOptions, { skip }) => {
    const res = await SECTION.GET_SECTION(10, skip, search);
    const option = res?.data.map((item) => {
      return { value: item?.id, label: item?.name };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const loadOptionsOfAlbum = async (search, prevOptions, { skip }) => {
    const res = await SECTION.GET_ALBUMS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.albumName,
        media_item: item?.media_item,
        albumName: item?.albumName,
        subtitle: item?.subtitle,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleChangeOfAlbum = (e) => {
    form.setFieldsValue({ title: e?.albumName });
    form.setFieldsValue({ subtitle: e?.subtitle });
    form.setFieldsValue({ media_item: e?.media_item });
    if (e?.media_item?.id) {
      setFileList([
        {
          id: e?.media_item?.id,
          name: 'Profile Image',
          status: 'done',
          url: e?.media_item?.image,
          thubmUrl: e?.media_item?.image,
        },
      ]);
    }
  };

  const loadOptionsOfCategory = async (search, prevOptions, { skip }) => {
    const res = await SECTION.GET_CATEGORY(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.categoryName,
        media_item: item?.media_item,
        categoryName: item?.categoryName,
        subtitle: item?.subtitle,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleChangeOfCategory = (e) => {
    form.setFieldsValue({ title: e?.categoryName });
    form.setFieldsValue({ subtitle: e?.subtitle });
    form.setFieldsValue({ media_item: e?.media_item });
    if (e?.media_item?.id) {
      setFileList([
        {
          id: e?.media_item?.id,
          name: 'Profile Image',
          status: 'done',
          url: e?.media_item?.image,
          thubmUrl: e?.media_item?.image,
        },
      ]);
    }
  };

  const loadOptionsOfSong = async (search, prevOptions, { skip }) => {
    const res = await SECTION.GET_SONG(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.songTitle,
        media_item: item?.album?.media_item,
        songTitle: item?.songTitle,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleChangeOfSong = (e) => {
    form.setFieldsValue({ title: e?.songTitle });
    form.setFieldsValue({ media_item: e?.media_item });
    if (e?.media_item?.id) {
      setFileList([
        {
          id: e?.media_item?.id,
          name: 'Profile Image',
          status: 'done',
          url: e?.media_item?.image,
          thubmUrl: e?.media_item?.image,
        },
      ]);
    }
  };

  const loadOptionsOfSubCategory = async (search, prevOptions, { skip }) => {
    const res = await SECTION.GET_SUB_CATEGORY(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.subCategoryName,
        media_item: item?.media_item,
        subCategoryName: item?.subCategoryName,
        subtitle: item?.subtitle,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleChangeOfSubCategory = (e) => {
    form.setFieldsValue({ title: e?.subCategoryName });
    form.setFieldsValue({ subtitle: e?.subtitle });
    form.setFieldsValue({ media_item: e?.media_item });
    if (e?.media_item?.id) {
      setFileList([
        {
          id: e?.media_item?.id,
          name: 'Profile Image',
          status: 'done',
          url: e?.media_item?.image,
          thubmUrl: e?.media_item?.image,
        },
      ]);
    }
  };

  const getTab = async () => {
    try {
      const res = await SECTION.TAB();
      if (res.status === 1) {
        const data = res?.data?.map((item) => {
          const subTab = item?.sub_tabs?.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          return {
            label: item.name,
            value: item.id,
            subTab,
          };
        });
        setTab(data);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const handleBack = () => {
    if (searchParams.get('newTab') === 'true') {
      window.close()
    } else { 
      navigate(-1);
    }
  };

  const onFinish = async (value) => {
    const payload = {
      idofItem: value?.url ? '' : value?.idofItem?.value.toString(),
      mediaItemId:
        value?.media_item?.file?.response?.data?.media?.id ||
        value?.media_item?.id,
      positionNo: value?.positionNo || 0,
      sectionId: value?.sectionId?.value,
      subtitle: value?.subtitle,
      title: value?.title,
      itemType: value?.itemType,
      url: value?.url,
      isShowHome: value?.isShowHome,
    };
    try {
      let res;
      if (id) {
        res = await SECTION.PATCH_SECTION_ITEM(id, payload);
      } else {
        res = await SECTION.POST_SECTION_ITEM(payload);
      }
      if (res.status === 1 || res.status === '1') {
        if (searchParams.get('newTab') === 'true') {
        window.close()
        } else { 
          navigate(-1);
        }
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL}/uploads-image`,
    headers: {
      authorization: localStorage.getItem('jalso-token'),
    },
    beforeUpload() {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn('File Already Uploaded! Please delete that.');
        return false;
      }
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === 'error') {
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove(info) {
      setFileList([]);
    },
  };

  const singleAlbum = async (id) => {
    try {
      const res = await ALBUMS.SINGLE_ALBUMS(id);
      if (res.status === 1 || res.status === '1') {
        return { label: res?.data?.albumName, value: res?.data?.id };
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const singleCategory = async (id) => {
    try {
      const res = await SECTION.GET_SINGLE_CATEGORY(id);
      if (res.status === 1 || res.status === '1') {
        return {
          label: res?.data?.categoryName,
          value: res?.data?.id,
        };
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const singleSong = async (id) => {
    try {
      const res = await SECTION.GET_SINGLE_SONG(id);
      if (res.status === 1 || res.status === '1') {
        return { label: res?.data?.songTitle, value: res?.data?.id };
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const singleSubCategory = async (id) => {
    try {
      const res = await SECTION.GET_SINGLE_SUB_CATEGORY(id);
      if (res.status === 1 || res.status === '1') {
        return { label: res?.data?.subCategoryName, value: res?.data?.id };
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    getTab();
  }, []);

  useEffect(() => {
    if (id) {
      const getSingleItem = async () => {
        const tempFileList = [...fileList];
        try {
          const res = await SECTION.SINGLE_SECTION_ITEM(id);
          if (res.status === 1 || res.status === '1') {
            setSubTab([
              {
                label: res?.data?.section?.sub_tab?.name,
                value: res?.data?.section?.sub_tab?.id,
              },
            ]);
            let name;
            if (res?.data?.itemType === 'album') {
              name = await singleAlbum(res?.data?.idofItem);
            }
            if (res?.data?.itemType === 'category') {
              name = await singleCategory(res?.data?.idofItem);
            }
            if (res?.data?.itemType === 'song') {
              name = await singleSong(res?.data?.idofItem);
            }
            if (res?.data?.itemType === 'sub-category') {
              name = await singleSubCategory(res?.data?.idofItem);
            }
            form.setFieldsValue({
              ...res?.data,
              idofItem: name,
              sectionId: {
                label: res?.data?.section?.name,
                value: res?.data?.section?.id,
              },
              tab: {
                label: res?.data?.section?.sub_tab?.tab?.name,
                value: res?.data?.section?.sub_tab?.tab?.id,
              },
              subTab: {
                label: res?.data?.section?.sub_tab?.name,
                value: res?.data?.section?.sub_tab?.id,
              },
            });
            setItemType(res?.data?.itemType);
            if (res?.data?.media_item) {
              tempFileList.push({
                uid: '-1',
                name: 'cover image',
                status: 'done',
                url: res?.data?.media_item?.image,
                thubmUrl: res?.data?.media_item?.image,
              });
            }
            setFileList(tempFileList);
          }
        } catch (error) {
          toast.error(error.message || 'Something went wrong!');
        }
      };
      getSingleItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id]);

  useEffect(() => {
    if (add) {
      const getSingleSection = async () => {
        try {
          const res = await SECTION.SINGLE_SECTION(add);
          if (res.status === 1 || res.status === '1') {
            setSubTab([
              { label: res?.data?.sub_tab.name, value: res?.data?.sub_tab.id },
            ]);
            form.setFieldsValue({
              sectionId: {
                label: res?.data?.name,
                value: res?.data?.id,
              },
              tab: {
                label: res?.data?.sub_tab?.tab?.name,
                value: res?.data?.sub_tab?.tab?.id,
              },
              subTab: {
                label: res?.data?.sub_tab.name,
                value: res?.data?.sub_tab.id,
              },
            });
          }
        } catch (error) {
          toast.error(error.message || 'Something went wrong!');
        }
      };
      getSingleSection();
    }
  }, [add, form]);

  return (
    <>
      <Row justify='center' gutter={0} className='mt-4'>
        <Col xs={24} sm={24} md={20} lg={15} xl={15} className='gutter-row'>
          <Form
            form={form}
            name='control-hooks'
            onFinish={onFinish}
            onValuesChange={(e) => {
              if (e?.itemType) {
                form.setFieldsValue({ idofItem: null });
                setItemType(e.itemType);
              }
            }}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 8 },
              md: { span: 8 },
              lg: { span: 8 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 16 },
              md: { span: 16 },
              lg: { span: 16 },
            }}
            className='form-style'
          >
            <InsideHeader className='p-3'>
              <h3 className='font-size-25 mb-2'>
                Add New Item in the Sub-Section
              </h3>
              <Form.Item name='tab' label='Select Tab' labelAlign='left'>
                <SelectDropDown
                  placeholder='Select Tab'
                  options={tab}
                  isDisabled
                />
              </Form.Item>
              <Form.Item name='subTab' label='Select Sub-Tab' labelAlign='left'>
                <SelectDropDown
                  placeholder='Select Sub-Tab'
                  options={subTab}
                  isDisabled
                />
              </Form.Item>
              <Form.Item
                name='sectionId'
                label='Select Section'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Select section is required !',
                  },
                ]}
              >
                <SelectWithPagination
                  placeholder='Select Section'
                  loadOptions={loadOptionsOfSection}
                  additional={{
                    skip: 0,
                  }}
                  isClearable
                />
              </Form.Item>
              <Form.Item
                name='itemType'
                label='Item Type'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'ItemType is required !',
                  },
                ]}
              >
                <Radio.Group name='itemType'>
                  <Radio className='font-size-18' value='album'>
                    Album
                  </Radio>
                  <Radio className='font-size-18' value='category'>
                    Category
                  </Radio>
                  <Radio className='font-size-18' value='url'>
                    Url
                  </Radio>
                  <Radio className='font-size-18' value='song'>
                    Song
                  </Radio>
                  <Radio className='font-size-18' value='sub-category'>
                    Sub-Category
                  </Radio>
                </Radio.Group>
              </Form.Item>
              {itemType === 'album' && (
                <Form.Item
                  name='idofItem'
                  label='Album'
                  labelAlign='left'
                  rules={[
                    {
                      required: true,
                      message: 'Select album is required !',
                    },
                  ]}
                >
                  <SelectWithPagination
                    placeholder='Select Album'
                    loadOptions={loadOptionsOfAlbum}
                    onChange={(e) => handleChangeOfAlbum(e)}
                    additional={{
                      skip: 0,
                    }}
                    isClearable
                  />
                </Form.Item>
              )}
              {itemType === 'url' && (
                <Form.Item
                  name='url'
                  label='Url'
                  labelAlign='left'
                  rules={[
                    {
                      required: true,
                      message: 'Url is required !',
                    },
                  ]}
                >
                  <Input
                    className='input-control'
                    placeholder='Url'
                    name='url'
                  />
                </Form.Item>
              )}
              {itemType === 'category' && (
                <Form.Item
                  name='idofItem'
                  label='Category'
                  labelAlign='left'
                  rules={[
                    {
                      required: true,
                      message: 'Select category is required !',
                    },
                  ]}
                >
                  <SelectWithPagination
                    placeholder='Select Category'
                    loadOptions={loadOptionsOfCategory}
                    onChange={(e) => handleChangeOfCategory(e)}
                    additional={{
                      skip: 0,
                    }}
                    isClearable
                  />
                </Form.Item>
              )}
              {itemType === 'song' && (
                <Form.Item
                  name='idofItem'
                  label='Song'
                  labelAlign='left'
                  rules={[
                    {
                      required: true,
                      message: 'Select song is required !',
                    },
                  ]}
                >
                  <SelectWithPagination
                    placeholder='Select Song'
                    loadOptions={loadOptionsOfSong}
                    onChange={(e) => handleChangeOfSong(e)}
                    additional={{
                      skip: 0,
                    }}
                    isClearable
                  />
                </Form.Item>
              )}
              {itemType === 'sub-category' && (
                <Form.Item
                  name='idofItem'
                  label='Sub-Category'
                  labelAlign='left'
                  rules={[
                    {
                      required: true,
                      message: 'Select sub-category is required !',
                    },
                  ]}
                >
                  <SelectWithPagination
                    placeholder='Select Sub-Category'
                    loadOptions={loadOptionsOfSubCategory}
                    onChange={(e) => handleChangeOfSubCategory(e)}
                    additional={{
                      skip: 0,
                    }}
                    isClearable
                  />
                </Form.Item>
              )}
              <Form.Item
                name='title'
                label='Title'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Title is required !',
                  },
                ]}
              >
                <Input
                  className='input-control'
                  name='title'
                  placeholder='Title'
                />
              </Form.Item>
              <Form.Item
                name='subtitle'
                label='Sub-Title'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Sub-title is required !',
                  },
                ]}
              >
                <Input
                  className='input-control'
                  name='subtitle'
                  placeholder='Sub-Title'
                />
              </Form.Item>
              <Form.Item
                name='isShowHome'
                valuePropName='checked'
                label='Show to Home'
                labelAlign='left'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name='positionNo'
                label='Position No.'
                labelAlign='left'
                extra={id ? '' : 'Leave blank for auto increment.'}
              >
                <InputNumber
                  min={0}
                  placeholder='Position No.'
                  className='input-control-number'
                />
              </Form.Item>
              <Form.Item
                name='media_item'
                valuePropName='filelist'
                label='Profile Image'
                labelAlign='left'
              >
                <Upload
                  {...props}
                  listType='picture'
                  className='file-upload'
                  accept='.png, .jpg, .jpeg'
                >
                  <ButtonCom type='primary' icon={<UploadOutlined />}>
                    Click to Upload
                  </ButtonCom>
                </Upload>
              </Form.Item>
              <div className='text-center mt-3'>
                <ButtonCom className='mr-2 width-180' onClick={handleBack}>
                  Back
                </ButtonCom>
                {id ? (
                  <ButtonCom
                    htmlType='submit'
                    type='primary'
                    className='width-180'
                  >
                    Update
                  </ButtonCom>
                ) : (
                  <ButtonCom
                    htmlType='submit'
                    type='primary'
                    className='width-180'
                  >
                    Add
                  </ButtonCom>
                )}
              </div>
            </InsideHeader>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default CreateNewItem;
