import { useState, useEffect } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Radio, Form, DatePicker } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';

import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import ButtonCom from '../../../../components/Button/ButtonCom';
import { SECTION } from '../../../../service';

const Publish = ({ setCurrent }) => {
  const [isScheduled, setIsScheduled] = useState(false);
  const [publishDate, setPublishDate] = useState(null);
  const [searchParams] = useSearchParams();


  const [form] = Form.useForm();

  const { id } = useParams();
  const navigate = useNavigate();

  const onFinish = async (value) => {
    if (value?.date) {
      value.date = moment(value?.date).format();
    } else {
      value.date = new Date();
    }
    const stepOne = JSON.parse(localStorage.getItem('section-step-1'));
    if (!stepOne.positionNo) {
      stepOne.positionNo = 0;
    }
    const payload = {
      ...stepOne,
      subTabId: stepOne?.subTabId?.value,
      tabId: stepOne?.tabId?.value,
      sizeType: stepOne?.sizeType?.value,
      ...value,
    };
    try {
      let res;
      if (id) {
        res = await SECTION.PATCH_SECTION(id, payload);
      } else {
        res = await SECTION.POST_SECTION(payload);
      }
      if (res.status === 1 || res.status === '1') {
        localStorage.removeItem('section-step-1');
        if (searchParams.get('newTab') === 'true') {
          window.close()
        } else {
          navigate(-1);
        }
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something Went Wrong !');
    }
  };

  const disabledDate = (current) => {
    let customDate = moment().format('YYYY-MM-DD ');
    return current && current < moment(customDate, 'YYYY-MM-DD');
  };
  const handleDisabledHours = () => {
    var hours = [];
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const disabledMinutes = () => {
    var minutes = [];
    for (var i = 0; i < moment().minute(); i++) {
      minutes.push(i);
    }
    return minutes;
  };

  const disabledSecond = () => {
    var second = [];
    for (var i = 0; i < moment().seconds(); i++) {
      second.push(i);
    }
    return second;
  };

  useEffect(() => {
    if (id) {
      const stepOne = JSON.parse(localStorage.getItem('section-step-1'));
      if (stepOne.date) {
        form.setFieldsValue({
          date: moment(stepOne?.date),
        });
      }
    }
  }, [form, id]);

  return (
    <>
      <InsideHeader className='add-edit-form mt-5' style={{ width: '650px' }}>
        <h1 className='font-size-28'>Schedule or Publish</h1>
        <Form
          initialValues={{
            is_scheduled: false,
          }}
          form={form}
          labelWrap={true}
          name='control-hooks'
          onFinish={onFinish}
        >
          <Form.Item style={{ textAlign: 'center' }}>
            <Radio.Group
              onChange={(e) => {
                setIsScheduled(e.target.value);
                e.target.value === false &&
                  form.setFieldsValue({
                    publish_date: null,
                  });
              }}
              value={isScheduled}
            >
              <Radio value={false}>Publish Now</Radio>
              <Radio value={true}>Schedule</Radio>
            </Radio.Group>
          </Form.Item>

          {isScheduled === true && (
            <>
              <Form.Item
                name='date'
                label='Select Date'
                rules={[{ required: true, message: 'Please select date' }]}
              >
                <DatePicker
                  className='input-control w-100'
                  use12Hours
                  format={'YYYY-MM-DD h:mm:ss'}
                  showTime={{
                    disabledHours: () =>
                      moment(publishDate).date() <= moment().date()
                        ? handleDisabledHours()
                        : null,
                    disabledMinutes: () =>
                      moment(publishDate).date() <= moment().date()
                        ? disabledMinutes()
                        : null,
                    disabledSeconds: () =>
                      moment(publishDate).date() <= moment().date()
                        ? disabledSecond()
                        : null,
                  }}
                  disabledDate={(current) => disabledDate(current)}
                  onSelect={(e) => setPublishDate(e)}
                  allowClear
                />
              </Form.Item>
            </>
          )}

          <div className='form-btn-group'>
            <ButtonCom className='mr-2 width-180' onClick={() => setCurrent(0)}>
              Back
            </ButtonCom>
            <ButtonCom htmlType='submit' type='primary' className='width-180'>
              {isScheduled ? 'Scheduled' : 'Publish Now'}
            </ButtonCom>
          </div>
        </Form>
      </InsideHeader>
    </>
  );
};

export default Publish;
