import { methods, serviceMaker } from '../service';

const GET_ALBUM_SUB_CATEGORY = (limit, skip, sort, subcategoryId, search) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (subcategoryId) {
    query['params']['subcategoryId'] = subcategoryId;
  }
  if (search) {
    query['params']['search'] = search;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`subcategory-albums`, methods.GET, query);
};

const DELETE_ALBUM_SUB_CATEGORY = (id) =>
  serviceMaker(`subcategory-albums/${id}`, methods.DELETE);

const CREATE_ALBUM_SUB_CATEGORY = (payload) =>
  serviceMaker(`subcategory-albums/`, methods.POST, null, payload);

const UPDATE_ALBUM_SUB_CATEGORY = (id, payload) =>
  serviceMaker(`subcategory-albums/${id}`, methods.PATCH, null, payload);

const SINGLE_ALBUM_SUB_CATEGORY = (id) =>
  serviceMaker(`subcategory-albums/${id}`, methods.GET);

const GET_SUB_CATEGORY = (limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`subcategory`, methods.GET, query);
};

const SINGLE_SUB_CATEGORY = (id) =>
  serviceMaker(`subcategory/${id}`, methods.GET);

const GET_ALBUMS = (limit, skip, search = null) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`albums`, methods.GET, query);
};

const GET_SINGLE_ALBUMS = (id) => serviceMaker(`albums/${id}`, methods.GET);

export const ALBUM_SUB_CATEGORY = {
  GET_ALBUM_SUB_CATEGORY,
  DELETE_ALBUM_SUB_CATEGORY,
  CREATE_ALBUM_SUB_CATEGORY,
  GET_SUB_CATEGORY,
  SINGLE_ALBUM_SUB_CATEGORY,
  GET_ALBUMS,
  SINGLE_SUB_CATEGORY,
  GET_SINGLE_ALBUMS,
  UPDATE_ALBUM_SUB_CATEGORY,
};
