import React, { useEffect, useState } from 'react';

import { Row, Col, Radio, DatePicker, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import ButtonCom from '../../../../components/Button/ButtonCom';
import {
  ALBUMS,
  ALBUM_CATEGORY,
  ALBUM_SUB_CATEGORY,
  SONGS,
} from '../../../../service/index';

const AlbumPublish = ({ setCurrent, scope }) => {
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState(1);
  const [publishDate, setPublishDate] = useState(null);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const tableData = JSON.parse(localStorage.getItem('step2')) || [];
  const albumData = JSON.parse(localStorage.getItem('step1')) || {};
  const categoryData = JSON.parse(localStorage.getItem('category')) || {};
  const navigate = useNavigate();
  const { id } = useParams();

  const handleBack = () => {
    if (scope === 'edit-album') {
      setCurrent(0);
    }
    if (scope === 'add-album') {
      setCurrent(3);
    }
  };

  const onFinish = (val) => {
    if (scope === 'add-album') {
      handlePublishForAdd(val);
    }
    if (scope === 'edit-album') {
      handlePublishForEdit(val);
    }
  };

  const handlePublishForAdd = async (val) => {
    const create = tableData.filter((item) => item.file === true);
    const update = tableData.filter((item) => item?.file !== true);
    try {
      setDisabledBtn(true);
      const payload = {
        albumName: albumData?.albumName,
        subtitle: albumData?.subtitle,
        isdownloadable: albumData?.isdownloadable,
        mediaItemId: albumData?.media_item?.id || null,
        categoryId: Number(albumData?.categoryId),
        subcategoryId: Number(albumData?.subcategoryId),
        releasedate: albumData.releasedate,
        expireDate: albumData.expireDate,
        publishDate: new Date(),
      };

      if (radioValue === 2) {
        payload.publishDate = val?.publishDate?._d;
      } else {
        payload.publishDate = new Date();
      }

      let resOfCreateAlbum = await ALBUMS.CREATE_ALBUMS(payload);

      if (categoryData?.categoryId) {
        const payload = {
          positionNo: categoryData?.CategoryPositionNo,
          categoryId: categoryData?.categoryId?.value,
          albumId: resOfCreateAlbum?.data?.id,
        };
        await ALBUM_CATEGORY.CREATE_ALBUM_CATEGORY(payload);
      }

      if (categoryData?.subcategoryId) {
        const payload = {
          positionNo: categoryData?.SubCategoryPositionNo,
          subcategoryId: categoryData?.subcategoryId?.value,
          albumId: resOfCreateAlbum?.data?.id,
        };
        await ALBUM_SUB_CATEGORY.CREATE_ALBUM_SUB_CATEGORY(payload);
      }

      if (create.length > 0) {
        const songPayload = create.map((item) => {
          return {
            songTitle: item.songTitle,
            duration: item.duration,
            lyrics: item.lyrics,
            details: item.details,
            isdownloadable: item.isdownloadable,
            isPodcast: item.isPodcast,
            songType: item.songType?.value,
            mediaSoundId: item?.media_sound?.id,
            albumId: resOfCreateAlbum?.data?.id,
            cmsPath: item.cmsPath,
          };
        });
        const resOfSongs = await SONGS.CREATE_SONGS(songPayload);

        const artistPayload = [];
        create.forEach((item, index) => {
          item.song_artists.forEach(async (role) => {
            role.artist_added_roles.forEach(async (element) => {
              if (element.present) {
                artistPayload.push({
                  artistId: role.id,
                  artistRoleId: element.artist_role.id,
                  songId: resOfSongs.data[index].id,
                });
              }
            });
          });
        });
        if (artistPayload.length > 0) {
          await SONGS.CREATE_SONG_ARTIST(artistPayload);
        }

        if (resOfSongs.status === 1 || resOfSongs.status === '1') {
          const albumSongPayload = resOfSongs.data.map((item) => {
            return {
              albumId: resOfCreateAlbum?.data?.id,
              songId: item.id,
            };
          });
          await SONGS.CREATE_ALBUMS_SONG(albumSongPayload);
        }
      }
      if (update.length > 0) {
        // ====== update the songs =====
        const songPayload = update.map((item) => {
          return {
            id: item.id,
            songTitle: item.songTitle,
            duration: item.duration,
            lyrics: item.lyrics,
            details: item.details,
            isdownloadable: item.isdownloadable,
            isPodcast: item.isPodcast,
            songType: item.songType?.value,
            mediaSoundId: item?.media_sound?.id,
            albumId: item.albumId || resOfCreateAlbum?.data?.id,
            cmsPath: item.cmsPath,
          };
        });
        const resOfUpdateSongs = await SONGS.UPDATE_SONGS(songPayload);

        // ====== update the album song for add new song on search =====
        let tempPayload = [];
        update.forEach((item) => {
          if (item?.addSongFromSearch === true) {
            tempPayload.push({
              albumId: resOfCreateAlbum?.data?.id,
              songId: item.id,
            });
          }
        });
        if (tempPayload.length > 0) {
          await SONGS.CREATE_ALBUMS_SONG(tempPayload);
        }

        // ====== create new artist on old songs =====
        const songArtistPayload = [];
        update.forEach((item, i) => {
          const filter = item.song_artists.filter(
            (songArtist) => songArtist.newArtist === true
          );
          filter.forEach((role) => {
            role.artist_added_roles.forEach(async (element) => {
              if (element.present) {
                songArtistPayload.push({
                  artistId: role?.id,
                  artistRoleId: element?.artist_role?.id,
                  songId: resOfUpdateSongs?.data[i].id,
                });
              }
            });
          });
        });
        if (songArtistPayload.length > 0) {
          await SONGS.CREATE_SONG_ARTIST(songArtistPayload);
        }

        // ====== delete the song artist if checkbox is unchecked =====
        const deleteArtistPayload = [];

        update.forEach((item) => {
          item.song_artists.forEach((role) => {
            role.artist_added_roles.forEach(async (element) => {
              if (element.present === false && element?.songArtistId) {
                deleteArtistPayload.push({
                  id: element?.songArtistId,
                  deletedAt: new Date(),
                });
              }
            });
          });
        });
        if (deleteArtistPayload.length > 0) {
          await SONGS.REMOVE_SONG_ARTIST(deleteArtistPayload);
        }
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    } finally {
      if (id) {
        toast.success('Record update successfully.!');
      } else {
        toast.success('Record create successfully.');
      }
      setDisabledBtn(false);
      localStorage.removeItem('step1');
      localStorage.removeItem('step2');
      localStorage.removeItem('file');
      localStorage.removeItem('category');
      navigate('/albums');
    }
  };

  const handlePublishForEdit = async (val) => {
    try {
      setDisabledBtn(true);
      const payload = {
        albumName: albumData?.albumName,
        subtitle: albumData?.subtitle,
        isdownloadable: albumData?.isdownloadable,
        mediaItemId: albumData?.media_item?.id || null,
        categoryId: Number(albumData?.categoryId),
        subcategoryId: Number(albumData?.subcategoryId),
        releasedate: albumData.releasedate,
        expireDate: albumData.expireDate,
        publishDate: new Date(),
      };

      if (radioValue === 2) {
        payload.publishDate = val?.publishDate?._d;
      } else {
        payload.publishDate = new Date();
      }

      const res = await ALBUMS.PATCH_ALBUMS(id, payload);
      if (res.status === 1 || res === '1') {
        setDisabledBtn(false);
        localStorage.removeItem('step1');
        localStorage.removeItem('step2');
        localStorage.removeItem('file');
        navigate(-1);
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const handleRadio = (e) => {
    setRadioValue(e.target.value);
  };

  const disabledDate = (current) => {
    let customDate = moment().format('YYYY-MM-DD ');
    return current && current < moment(customDate, 'YYYY-MM-DD');
  };

  const handleDisabledHours = () => {
    var hours = [];
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const disabledMinutes = () => {
    var minutes = [];
    for (var i = 0; i < moment().minute(); i++) {
      minutes.push(i);
    }
    return minutes;
  };

  const disabledSecond = () => {
    var second = [];
    for (var i = 0; i < moment().seconds(); i++) {
      second.push(i);
    }
    return second;
  };

  useEffect(() => {
    if (id) {
      setRadioValue(2)
      form.setFieldsValue({
        publishDate: moment(albumData?.publishDate),
      });
    }
  }, [albumData?.publishDate, form, id]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
          md: { span: 8 },
          lg: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
          md: { span: 16 },
          lg: { span: 16 },
        }}
        labelAlign='left'
        name='control-hooks'
        className='form-style'
      >
        <Row justify='center' gutter={25} className='mt-2'>
          <Col
            xs={24}
            sm={24}
            md={20}
            lg={16}
            xl={16}
            className=' mt-3 gutter-row'
          >
            <div className='bg-white box-shadow-3 border-radius-5 p-3 '>
              <h3 className='font-size-25 mb-2'>Schedule or Publish</h3>
              <div className='text-center mb-3'>
                <Radio.Group
                  size='large'
                  onChange={handleRadio}
                  value={radioValue}
                >
                  <Radio value={1}>Publish Now</Radio>
                  <Radio value={2}>Schedule</Radio>
                </Radio.Group>
              </div>
              {radioValue === 2 && (
                <>
                  <Form.Item
                    name='publishDate'
                    label='Select Date'
                    rules={[
                      {
                        required: true,
                        message: 'Date is requried !',
                      },
                    ]}
                  >
                    <DatePicker
                      className='input-control w-100'
                      use12Hours
                      format={'YYYY-MM-DD h:mm:ss'}
                      showTime={{
                        disabledHours: () =>
                          moment(publishDate).date() <= moment().date()
                            ? handleDisabledHours()
                            : null,
                        disabledMinutes: () =>
                          moment(publishDate).date() <= moment().date()
                            ? disabledMinutes()
                            : null,
                        disabledSeconds: () =>
                          moment(publishDate).date() <= moment().date()
                            ? disabledSecond()
                            : null,
                      }}
                      disabledDate={(current) => disabledDate(current)}
                      onSelect={(e) => setPublishDate(e)}
                      allowClear
                    />
                  </Form.Item>
                </>
              )}
              <div className='text-center mt-4'>
                <ButtonCom className='mr-2 width-180' onClick={handleBack}>
                  Back
                </ButtonCom>
                {disabledBtn ? (
                  <ButtonCom type='primary' className='width-180' disabled>
                    {radioValue === 1 ? 'Publish Now' : 'Schedule'}
                  </ButtonCom>
                ) : (
                  <ButtonCom
                    type='primary'
                    className='width-180'
                    htmlType='submit'
                  >
                    {radioValue === 1 ? 'Publish Now' : 'Schedule'}
                  </ButtonCom>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AlbumPublish;
