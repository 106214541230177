import React, { useState } from 'react';

import { Row, Col, Table, Checkbox } from 'antd';

import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { SONGS } from '../service';

import ButtonCom from './Button/ButtonCom';

const ReviewData = ({ setCurrent, scope }) => {
  const [disabledBtn, setDisabledBtn] = useState(false);
  const tableData = JSON.parse(localStorage.getItem('step2')) || '';
  const albumData = JSON.parse(localStorage.getItem('step1')) || '';
  const navigate = useNavigate();
  const { id } = useParams();

  const handleCreate = async () => {
    if (scope === 'album') {
      setCurrent(4);
    } else {
      const update = tableData.filter((item) => item?.file !== true);
      const create = tableData.filter((item) => item.file === true);
      try {
        setDisabledBtn(true);
        if (create.length > 0) {
          const songPayload = create.map((item) => {
            return {
              songTitle: item.songTitle,
              duration: item.duration,
              lyrics: item.lyrics,
              details: item.details,
              isdownloadable: item.isdownloadable,
              cmsPath: item.cmsPath,
              songType: item.songType?.value,
              mediaSoundId: item?.media_sound?.id,
              albumId: item?.albumId,
            };
          });
          const resOfSongs = await SONGS.CREATE_SONGS(songPayload);

          const artistPayload = [];
          create.forEach((item, index) => {
            item.song_artists.forEach(async (role) => {
              role.artist_added_roles.forEach(async (element) => {
                if (element.present) {
                  artistPayload.push({
                    artistId: role.id,
                    artistRoleId: element.artist_role.id,
                    songId: resOfSongs?.data[index]?.id,
                  });
                }
              });
            });
          });
          if (artistPayload.length > 0) {
            await SONGS.CREATE_SONG_ARTIST(artistPayload);
          }

          if (resOfSongs.status === 1 || resOfSongs.status === '1') {
            const albumSongPayload = resOfSongs.data.map((item) => {
              return {
                albumId: albumData.id,
                songId: item.id,
              };
            });
            await SONGS.CREATE_ALBUMS_SONG(albumSongPayload);
          }
        }

        if (update.length > 0) {
          // ====== update the songs =====
          const songPayload = update.map((item) => {
            return {
              id: item.id,
              songTitle: item.songTitle,
              duration: item.duration,
              lyrics: item.lyrics,
              details: item.details,
              isdownloadable: item.isdownloadable,
              cmsPath: item.cmsPath,
              songType: item.songType?.value,
              mediaSoundId: item?.media_sound?.id,
              albumId: item?.albumId,
            };
          });
          const resOfUpdateSongs = await SONGS.UPDATE_SONGS(songPayload);

          // ====== update the album song for add new song on search =====
          let tempPayload = [];
          update.forEach((item) => {
            if (item?.addSongFromSearch === true) {
              tempPayload.push({
                albumId: albumData.id,
                songId: item.id,
              });
            }
          });
          if (tempPayload.length > 0) {
            await SONGS.CREATE_ALBUMS_SONG(tempPayload);
          }

          // ====== create new artist on old songs =====
          const songArtistPayload = [];
          update.forEach((item, i) => {
            const filter = item.song_artists.filter(
              (songArtist) => songArtist.newArtist === true
            );
            filter.forEach((role) => {
              role.artist_added_roles.forEach(async (element) => {
                if (element.present) {
                  songArtistPayload.push({
                    artistId: role?.id,
                    artistRoleId: element?.artist_role?.id,
                    songId: resOfUpdateSongs?.data[i].id,
                  });
                }
              });
            });
          });
          if (songArtistPayload.length > 0) {
            await SONGS.CREATE_SONG_ARTIST(songArtistPayload);
          }

          // ====== delete the song artist if checkbox is unchecked =====
          const deleteArtistPayload = [];

          update.forEach((item) => {
            item.song_artists.forEach((role) => {
              role.artist_added_roles.forEach(async (element) => {
                if (element.present === false && element?.songArtistId) {
                  deleteArtistPayload.push({
                    id: element?.songArtistId,
                    deletedAt: new Date(),
                  });
                }
              });
            });
          });
          if (deleteArtistPayload.length > 0) {
            await SONGS.REMOVE_SONG_ARTIST(deleteArtistPayload);
          }
        }
      } catch (err) {
        toast.error(err.message || 'Something went wrong!');
      } finally {
        setDisabledBtn(false);
        if (id) {
          toast.success('Record update successfully.!');
        } else {
          toast.success('Record create successfully !');
        }
        localStorage.removeItem('step1');
        localStorage.removeItem('step2');
        localStorage.removeItem('file');
        navigate('/songs');
      }
    }
  };

  const handleBack = () => {
    if (scope === 'album') {
      setCurrent(2);
    } else {
      setCurrent(1);
    }
  };

  const reviewColumns = [
    {
      title: 'Song Title',
      dataIndex: 'songTitle',
      key: 'songTitle',
      align: 'center',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      align: 'center',
    },
    {
      title: '32 bits',
      dataIndex: 'bits_32',
      key: 'bits_32',
      align: 'center',
      render: (record, item) => {
        return (
          <>
            <Checkbox
              disabled
              checked={item?.media_sound?.bitFile32}
            ></Checkbox>
          </>
        );
      },
    },
    {
      title: '64 bits',
      dataIndex: 'bits_64',
      key: 'bits_64',
      align: 'center',
      render: (record, item) => {
        return (
          <>
            <Checkbox
              disabled
              checked={item?.media_sound?.bitFile64}
            ></Checkbox>
          </>
        );
      },
    },
    {
      title: '128 bits',
      dataIndex: 'bits_128',
      key: 'bits_128',
      align: 'center',
      render: (record, item) => {
        return (
          <>
            <Checkbox
              disabled
              checked={item?.media_sound?.bitFile128}
            ></Checkbox>
          </>
        );
      },
    },
    {
      title: '256 bits',
      dataIndex: 'bits_256',
      key: 'bits_256',
      align: 'center',
      render: (record, item) => {
        return (
          <>
            <Checkbox
              disabled
              checked={item?.media_sound?.bitFile256}
            ></Checkbox>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row justify='center' gutter={30} className='mt-2'>
        <Col
          xs={24}
          sm={24}
          md={20}
          lg={20}
          xl={20}
          className=' mt-3 gutter-row'
        >
          <div className='bg-white box-shadow-3 border-radius-5 px-2 py-2'>
            <h3 className='font-size-25'>Review album data</h3>
            <Row gutter={20}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                className='gutter-row mb-3'
              >
                <img
                  src={albumData?.media_item?.image || ' '}
                  width='200px'
                  height='200px'
                  className='border-radius-10'
                  alt=''
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={16}
                xl={16}
                className='gutter-row'
              >
                <Row gutter={0}>
                  <Col
                    xs={24}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={6}
                    className='gutter-row'
                  >
                    <h3 className='font-weight-600 font-size-18 mb-0'>
                      Album Name
                    </h3>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className='gutter-row'
                  >
                    -
                  </Col>
                  <Col
                    xs={22}
                    sm={13}
                    md={13}
                    lg={13}
                    xl={16}
                    className='gutter-row'
                  >
                    <h3 className='font-size-18 mb-0'>{albumData.albumName}</h3>
                  </Col>
                </Row>
                <Row gutter={0}>
                  <Col
                    xs={24}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={6}
                    className='gutter-row'
                  >
                    <h3 className='font-weight-600 font-size-18 mb-0'>
                      Album Subtitle
                    </h3>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className='gutter-row'
                  >
                    -
                  </Col>
                  <Col
                    xs={22}
                    sm={13}
                    md={13}
                    lg={13}
                    xl={16}
                    className='gutter-row'
                  >
                    <h3 className='font-size-18 mb-0'>{albumData?.subtitle}</h3>
                  </Col>
                </Row>
                <Row gutter={0}>
                  <Col
                    xs={24}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={6}
                    className='gutter-row'
                  >
                    <h3 className='font-weight-600 font-size-18 mb-0'>
                      Release Date
                    </h3>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className='gutter-row'
                  >
                    -
                  </Col>
                  <Col
                    xs={22}
                    sm={13}
                    md={13}
                    lg={13}
                    xl={16}
                    className='gutter-row'
                  >
                    <h3 className='font-size-18 mb-0'>
                      {new Date(albumData?.releasedate).toLocaleString(
                        'en-US',
                        {
                          dateStyle: 'medium',
                        }
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row gutter={0}>
                  <Col
                    xs={24}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={6}
                    className='gutter-row'
                  >
                    <h3 className='font-weight-600 font-size-18 mb-0'>
                      Downloadable
                    </h3>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className='gutter-row'
                  >
                    -
                  </Col>
                  <Col
                    xs={22}
                    sm={13}
                    md={13}
                    lg={13}
                    xl={16}
                    className='gutter-row'
                  >
                    <h3 className='font-size-18 mb-0'>
                      {albumData?.isdownloadable ? 'Yes' : 'No'}
                    </h3>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={20}
          lg={20}
          xl={20}
          className='gutter-row mt-3'
        >
          <div className='fix-table box-shadow-3 border-radius-5'>
            <Table
              pagination={false}
              dataSource={tableData}
              columns={reviewColumns}
              rowKey='id'
            />
            <div className='text-center bg-white py-3 width-1120 '>
              <ButtonCom className='mr-2 width-180' onClick={handleBack}>
                Back
              </ButtonCom>
              {disabledBtn ? (
                <ButtonCom type='primary' className='width-180' disabled>
                  Create
                </ButtonCom>
              ) : (
                <ButtonCom
                  type='primary'
                  className='width-180'
                  onClick={handleCreate}
                >
                  Create
                </ButtonCom>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ReviewData;
