import React from 'react';

import { Col } from 'antd';

import EmptyScreen from '../EmptyScreen';

const TrendingSearch = ({ trendingSearch }) => {
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} className=' mt-3 gutter-row'>
        <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
          <h2 className='font-weight-700'>Trending Searches</h2>
          <div
            className={
              trendingSearch?.length > 0
                ? 'trending-search mt-2'
                : 'trending-search d-flex align-items-center justify-content-center mt-2'
            }
          >
            {trendingSearch?.length > 0 ? (
              <div className='d-flex flex-wrap'>
                {trendingSearch?.map((item, i) => {
                  return (
                    <button key={i} className='trending-search-btn'>
                      {item?.text}
                    </button>
                  );
                })}
              </div>
            ) : (
              <EmptyScreen />
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

export default TrendingSearch;
