import { methods, serviceMaker } from '../service';

const GET_DASHBOARD = (search) => {
  const query = {
    params: {},
  };
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`dashboard/home`, methods.GET, query);
};

export const DASHBOARD = {
  GET_DASHBOARD,
};
