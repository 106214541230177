import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, InputNumber, Row } from 'antd';
import { toast } from 'react-toastify';

import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import SelectWithPagination from '../../../../components/SelectWithPagination';
import ButtonCom from '../../../../components/Button/ButtonCom';
import { ALBUM_SUB_CATEGORY } from '../../../../service';

const AddEditAlbumCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [form] = Form.useForm();

  const onFinish = async (value) => {
    const payload = {
      ...value,
      positionNo: value?.positionNo || 0,
      subcategoryId: value?.subcategoryId?.value,
      albumId: value?.albumId?.value,
    };

    try {
      let res;
      if (id) {
        res = await ALBUM_SUB_CATEGORY.UPDATE_ALBUM_SUB_CATEGORY(id, payload);
      } else {
        res = await ALBUM_SUB_CATEGORY.CREATE_ALBUM_SUB_CATEGORY(payload);
      }
      if (res.status === 1 || res.status === '1') {
        navigate(-1);
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };

  const loadOptionsOfSubCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUM_SUB_CATEGORY.GET_SUB_CATEGORY(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.subCategoryName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const loadOptionsOfAlbum = async (search, prevOptions, { skip }) => {
    const res = await ALBUM_SUB_CATEGORY.GET_ALBUMS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.albumName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  useEffect(() => {
    const getEditSubCategory = async (id) => {
      try {
        const res = await ALBUM_SUB_CATEGORY.SINGLE_ALBUM_SUB_CATEGORY(id);
        if (res.status === 1 || res.status === '1') {
          form.setFieldsValue({
            positionNo: res?.data?.positionNo,
            albumId: {
              label: res?.data?.album?.albumName,
              value: res?.data?.album?.id,
            },
            subcategoryId: {
              label: res?.data?.subcategory?.subCategoryName,
              value: res?.data?.subcategory?.id,
            },
          });
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong!');
      }
    };
    if (id) {
      getEditSubCategory(id);
    }
  }, [form, id]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 12 },
          md: { span: 12 },
          lg: { span: 12 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 12 },
          md: { span: 12 },
          lg: { span: 12 },
        }}
        labelAlign='left'
        name='control-hooks'
        className='form-style'
      >
        <Row justify='center' gutter={0} className='mt-4'>
          <Col xs={24} sm={24} md={20} lg={15} xl={15} className='gutter-row'>
            <InsideHeader className='p-3'>
              <h3 className='font-size-25 mb-2'>
                {id ? 'Edit Album Category' : 'Create Album Category'}
              </h3>
              <Form.Item
                name='subcategoryId'
                label='Select Sub-Category'
                rules={[
                  {
                    required: true,
                    message: 'Sub-Category is required !',
                  },
                ]}
              >
                <SelectWithPagination
                  placeholder='Select Sub-Category'
                  loadOptions={loadOptionsOfSubCategory}
                  additional={{
                    skip: 0,
                  }}
                  isClearable
                />
              </Form.Item>
              <Form.Item
                name='albumId'
                label='Select Album'
                rules={[
                  {
                    required: true,
                    message: 'Category is required !',
                  },
                ]}
              >
                <SelectWithPagination
                  placeholder='Select Album'
                  loadOptions={loadOptionsOfAlbum}
                  additional={{
                    skip: 0,
                  }}
                  isClearable
                />
              </Form.Item>
              <Form.Item
                name='positionNo'
                label='Position No.'
                labelAlign='left'
                extra={id ? '' : 'Leave blank for auto increment.'}
              >
                <InputNumber
                  min={0}
                  placeholder='Position No.'
                  className='input-control-number'
                />
              </Form.Item>
              <div className='text-center mt-3'>
                <ButtonCom
                  className='width-180 mr-2'
                  onClick={() => navigate(-1)}
                >
                  Back
                </ButtonCom>
                {id ? (
                  <ButtonCom
                    htmlType='submit'
                    type='primary'
                    className='width-180'
                  >
                    Update
                  </ButtonCom>
                ) : (
                  <ButtonCom
                    htmlType='submit'
                    type='primary'
                    className='width-180'
                  >
                    Create
                  </ButtonCom>
                )}
              </div>
            </InsideHeader>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddEditAlbumCategory;
