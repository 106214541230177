import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form, Input, Select } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { ADMIN_ROLES, ADMIN_USERS } from '../../service';

import InsideHeader from '../../components/InsideHeader/InsideHeader';

import { toast } from 'react-toastify';
import ButtonCom from '../../components/Button/ButtonCom';

const AddEditAdminUser = () => {
  const [roles, setRoles] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { Option } = Select;

  const { id = null } = useParams();
  const operationName = id ? 'edit' : 'add';

  const getEditData = async () => {
    try {
      const res = await ADMIN_USERS.GET_BY_ID(id);
      if (res.status === 1) {
        form.setFieldsValue({ ...res?.data, roleId: res?.data?.role?.id });
      }
    } catch (err) {
      toast.error(err.message || 'something wen wrong');
    }
  };

  const addEditUser = async (values) => {
    let res;
    try {
      const payload = {
        ...values,
        fullname: `${values?.firstname} ${values?.lastname}`,
      };
      if (operationName === 'edit') {
        res = await ADMIN_USERS.EDIT(id, payload);
      }
      if (operationName === 'add') {
        res = await ADMIN_USERS.ADD(payload);
      }
      if (res.status === 1 || res.status === '1') {
        toast.success(res?.message);
        navigate('/admin-users');
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Something went wrong!');
    }
  };

  const fetchRoles = async () => {
    try {
      const res = await ADMIN_ROLES.GET();
      if (res.status === 1 || res.status === '1') {
        setRoles(res?.data);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchRoles();
    if (operationName === 'edit') {
      getEditData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <InsideHeader className='add-edit-form'>
        <h1 className='font-size-28'>
          {operationName === 'add' ? 'Create New User' : 'Edit User'}
        </h1>
        <Form form={form} name='control-hooks' onFinish={addEditUser}>
          <Form.Item
            name='firstname'
            label='First Name'
            rules={[{ required: true, message: 'Please fill First Name' }]}
          >
            <Input
              size='large'
              className='input-control'
              placeholder='first name'
              allowClear
            />
          </Form.Item>
          <Form.Item
            name='lastname'
            label='Last Name'
            rules={[{ required: true, message: 'Please fill Last Name' }]}
          >
            <Input
              className='input-control'
              size='large'
              placeholder='last name'
              allowClear
            />
          </Form.Item>
          <Form.Item
            name='email'
            label='Email'
            rules={[{ required: true, message: 'Please fill Email Id' }]}
          >
            <Input
              className='input-control'
              size='large'
              placeholder='xyz@gmail.com'
              allowClear
            />
          </Form.Item>
          <Form.Item
            name='roleId'
            label='Role'
            rules={[{ required: true, message: 'Please select Role' }]}
          >
            <Select placeholder='Select Role' allowClear>
              {roles &&
                roles.map((role, index) => {
                  return (
                    <Option
                      key={index}
                      value={role?.id}
                      className='font-size-15'
                    >
                      {role?.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name='username'
            label='Username'
            rules={[{ required: true, message: 'Please fill Username' }]}
          >
            <Input
              className='input-control'
              size='large'
              placeholder='username'
              allowClear
            />
          </Form.Item>
          <Form.Item
            name='password'
            label='Password'
            extra={
              operationName === 'edit'
                ? 'Leave blank to keep the same password'
                : ''
            }
            rules={[
              {
                required: operationName === 'edit' ? false : true,
                message: 'Please fill Password',
              },
            ]}
          >
            <Input.Password
              className='input-control'
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              allowClear
            />
          </Form.Item>

          <div className='form-btn-group'>
            <ButtonCom
              className='mr-2 width-180'
              onClick={() => navigate('/admin-users')}
            >
              Back
            </ButtonCom>
            <ButtonCom htmlType='submit' type='primary' className='width-180'>
              {operationName === 'add' ? 'Create' : 'Edit'}
            </ButtonCom>
          </div>
        </Form>
      </InsideHeader>
    </>
  );
};

export default AddEditAdminUser;
