import React, { useEffect, useState } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Row, Col } from "antd";

import InsideHeader from "../../components/InsideHeader/InsideHeader";
import ButtonCom from "../../components/Button/ButtonCom";
import EmptyScreen from "../../components/EmptyScreen";
import Section from "../../components/Section/Section";
import SelectDropDown from "../../components/Select";
import { SECTION } from "../../service/index";

const Sections = ({ permissions }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(
    Number(searchParams.get("tabId")) && {
      label: searchParams.get("tabValue"),
      value: Number(searchParams.get("tabId")),
    }
  );
  const [searchValue, setSearchValue] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [subTabs, setSubTabs] = useState([]);

  const fetchSections = async () => {
    try {
      const res = await SECTION.TAB();
      if (res.status === 1 || res.status === "1") {
        const data = res?.data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setTabs(data);
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong!");
    }
  };

  const fetchSubTabs = async (id) => {
    try {
      const res = await SECTION.SINGLE_TAB(id);
      if (res.status === 1 || res.status === "1") {
        setSubTabs(res?.data?.sub_tabs);
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong !");
    }
  };

  const setQueryParams = (obj) => {
    const tempObj = {};
    for (const key in obj) {
      if (obj[key]) {
        tempObj[key] = obj[key];
      }
    }
    setSearchParams(tempObj);
  };

  const handleSearch = () => {
    setTempSearch(searchValue);
    setQueryParams({
      search: searchValue,
      tabId: activeTab.value,
      tabValue: activeTab.label,
    });
  };

  const handleChangeOfSearch = (e) => {
    if (!e.target.value) {
      setQueryParams({
        search: null,
        tabId: activeTab.value,
        tabValue: activeTab.label,
      });
      fetchSubTabs(activeTab?.value);
    }
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    if (activeTab) {
      fetchSubTabs(activeTab?.value);
    }
  }, [activeTab]);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(tabs[0]);
    }
  }, [activeTab, tabs]);

  useEffect(() => {
    if (searchParams.get("search") !== "null") {
      setSearchValue(searchParams.get("search"));
      setTempSearch(searchParams.get("search"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (tempSearch && subTabs.length > 0) {
      const filter = subTabs.map((map) => {
        return {
          ...map,
          sections: map.sections.filter((item) =>
            item.name.toLowerCase().includes(tempSearch.toLowerCase())
          ),
        };
      });
      setSubTabs(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempSearch, subTabs.length]);

  const syncJsonMappingData = async () => {
    try {
      const payload = {
        sync: true,
      };
      const res = await SECTION.SYNC_JSON_MAPPING(payload);
      if (res.status === 1 || res.status === "1") {
        toast.success("Sync Successfully!");
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong!");
    }
  };

  return (
    <>
      <InsideHeader className="mb-3 pb-1">
        <Row gutter={20} className="align-items-center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            className="gutter-row mb-1"
          >
            <Input
              type="search"
              placeholder="Start Typing Search Section"
              className="input-control"
              value={searchValue}
              allowClear
              onChange={(e) => handleChangeOfSearch(e)}
              suffix={
                <SearchOutlined
                  style={{ color: "#8f9bb3" }}
                  onClick={handleSearch}
                />
              }
              onKeyPress={(e) => {
                if (e?.key === "Enter" && e.target.value) {
                  handleSearch();
                }
              }}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            className="gutter-row mb-1"
          >
            <div className="d-flex flex-wrap align-items-center  justify-content-md-left justify-content-end">
              {(permissions?.isCreate ||
                permissions?.isCreate === undefined) && (
                <ButtonCom
                  type="primary"
                  onClick={() => {
                    localStorage.removeItem("subTabId");
                    localStorage.removeItem("tabId");
                    localStorage.removeItem("section-step-1");
                    navigate("/sections/add");
                  }}
                >
                  Create New Section
                </ButtonCom>
              )}
            </div>
          </Col>
        </Row>
      </InsideHeader>

      <InsideHeader>
        <Row gutter={20} className="align-items-center">
          <Col xs={24} sm={24} md={8} lg={12} xl={12} className="gutter-row">
            <SelectDropDown
              placeholder="Select Tab"
              options={tabs}
              onChange={(e) => {
                setActiveTab(e);
                setQueryParams({
                  search: searchValue,
                  tabId: e.value,
                  tabValue: e.label,
                });
              }}
              value={activeTab}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            className="gutter-row mb-1"
          >
            <div className="d-flex flex-wrap align-items-center justify-content-md-left justify-content-end">
              <ButtonCom
                type="primary"
                onClick={() => {
                  syncJsonMappingData();
                }}
              >
                Sync
              </ButtonCom>
            </div>
          </Col>
        </Row>
      </InsideHeader>

      {subTabs && subTabs.length > 0 && (
        <div>
          <Section
            id={activeTab?.value}
            value={activeTab?.label}
            fetchSubTabs={fetchSubTabs}
            subTabs={subTabs}
            permissions={permissions}
          />
        </div>
      )}

      {subTabs.length <= 0 && (
        <div className="section-empty-screen">
          <EmptyScreen />
        </div>
      )}
    </>
  );
};

export default Sections;
