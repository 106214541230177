import React, { useState } from 'react';

import { Checkbox, Col, Row, Tooltip } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import DeleteModel from '../../DeleteModel';
import InfoDrawer from '../InfoDrawer/InfoDrawer';
import ButtonCom from '../../Button/ButtonCom';
import { SECTION } from '../../../service/index';
import Logo from '../../../assets/image/jalso.jpg';

const ManageCard = ({
  singleSubTab,
  tabId,
  subTabId,
  getManageData,
  permissions,
  subTabValue,
}) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSectionDeleteModal, setIsSectionDeleteModal] = useState(false);

  const [visible, setVisible] = useState(false);
  const [infoData, setInfoData] = useState({});

  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();

  const deleteCard = async () => {
    try {
      const res = await SECTION.REMOVE_SECTION_ITEM(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        getManageData(subTabValue?.value);
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong !');
    }
  };

  const deleteSection = async () => {
    try {
      const res = await SECTION.REMOVE_SECTION(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsSectionDeleteModal(false);
        getManageData(subTabValue?.value);
        toast.success(res.message);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong !');
    }
  };

  const handleInfo = (item) => {
    setVisible(true);
    setInfoData(item);
  };

  return (
    <>
      {singleSubTab.map((single) => {
        return (
          <div key={single.id}>
            <div className='d-flex justify-content-between mt-3 mb-1 flex-wrap align-items-center'>
              <h1 className='mb-0 font-size-28'>{single.name}</h1>
              <div>
                {(permissions?.isUpdate ||
                  permissions?.isUpdate === undefined) && (
                    <Tooltip title='Edit Section'>
                      <ButtonCom
                        type='primary'
                        className='mr-1'
                        onClick={() => {
                          localStorage.removeItem('tabId');
                          localStorage.removeItem('subTabId');
                          localStorage.removeItem('section-step-1');
                          navigate(`/sections/edit/${single.id}`);
                        }}
                      >
                        <EditOutlined className='font-size-16 edit-btn-hover cursor-pointer' />
                      </ButtonCom>
                    </Tooltip>
                  )}
                {(permissions?.isDelete ||
                  permissions?.isDelete === undefined) && (
                    <Tooltip title='Delete Section'>
                      <ButtonCom
                        type='primary'
                        className='mr-1'
                        onClick={() => {
                          setDeleteId(single.id);
                          setIsSectionDeleteModal(true);
                        }}
                      >
                        <DeleteOutlined className='font-size-16 delete-btn-hover cursor-pointer' />
                      </ButtonCom>
                    </Tooltip>
                  )}
                {(permissions?.isCreate ||
                  permissions?.isCreate === undefined) && (
                    <Link to={`/sections/manage/add/${single.id}?newTab=true`} target="_blank">
                      <ButtonCom
                        type='primary'
                        className='mr-1'
                      >
                        Add New Item
                      </ButtonCom>
                    </Link>
                  )}
                <ButtonCom
                  type='primary'
                  onClick={() =>
                    navigate(`/sections/item/${single.id}`, {
                      state: { subTabId, tabId, sectionName: single.name },
                    })
                  }
                >
                  See all
                </ButtonCom>
              </div>
            </div>

            <Row gutter={20} className='align-items-center'>
              {single.section_items.map((item) => {
                return (
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={4}
                    className='gutter-row mb-2'
                    key={item.id}
                  >
                    <div className='bg-white box-shadow-3 border-radius-5 p-2 h-min-212'>
                      <Row gutter={20} className='align-items-center'>
                        <Col
                          xs={18}
                          sm={18}
                          md={18}
                          lg={20}
                          xl={20}
                          className='gutter-row'
                        >
                          {item?.media_item?.image ? (
                            <img
                              src={item?.media_item?.image}
                              alt=''
                              width='80px'
                              height='80px'
                              className='border-radius-5'
                            />
                          ) : (
                            <img
                              src={Logo}
                              alt=''
                              width='80px'
                              height='80px'
                              className='border-radius-5'
                            />
                          )}
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={4}
                          xl={4}
                          className='gutter-row'
                        >
                          <div className='d-flex flex-direction-column align-items-end'>
                            {(permissions?.isUpdate ||
                              permissions?.isUpdate === undefined) && (
                                <Tooltip title='Edit Info'>
                                  <EditOutlined
                                    className='font-size-16 edit-btn-hover cursor-pointer'
                                    onClick={() =>
                                      navigate(
                                        `/sections/manage/edit/${item.id}`,
                                        {
                                          state: { subTabId, tabId, item },
                                        }
                                      )
                                    }
                                  />
                                </Tooltip>
                              )}
                            <Tooltip title='View Info'>
                              <InfoCircleOutlined
                                className='font-size-16 edit-btn-hover info-btn-hover cursor-pointer my-1'
                                onClick={() => handleInfo(item)}
                              />
                            </Tooltip>
                            {(permissions?.isDelete ||
                              permissions?.isDelete === undefined) && (
                                <Tooltip title='Delete Info'>
                                  <DeleteOutlined
                                    className='font-size-16 delete-btn-hover cursor-pointer '
                                    onClick={() => {
                                      setIsDeleteModalVisible(true);
                                      setDeleteId(item.id);
                                    }}
                                  />
                                </Tooltip>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <h4 className='mt-1 mb-0'>
                        {item.title.substring(0, 10)}
                        {item.title.length >= 10 && '...'}
                      </h4>
                      <h5 className='text-light-gray'>
                        {item.subtitle.substring(0, 15)}
                        {item.subtitle.length >= 15 && '...'}
                      </h5>
                      <div className='d-flex justify-content-between'>
                        <h3 className='font-size-14'>Show on Home</h3>
                        <Checkbox disabled checked={item.isShowHome} />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}

      {/* Drawer */}
      <InfoDrawer
        setVisible={setVisible}
        visible={visible}
        infoData={infoData}
      />

      {/* Delete Model */}
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteCard}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />

      <DeleteModel
        isDeleteModalVisible={isSectionDeleteModal}
        deleteModel={deleteSection}
        setIsDeleteModalVisible={setIsSectionDeleteModal}
      />
    </>
  );
};

export default ManageCard;
