import React, { useEffect, useState } from 'react';

import { Row, Col, Input, Checkbox, Table, Tooltip } from 'antd';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import PaginationCom from '../../../../components/PaginationCom';
import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import SelectWithPagination from '../../../../components/SelectWithPagination';
import ButtonCom from '../../../../components/Button/ButtonCom';
import { ALBUMS, SONGS } from '../../../../service';
import { tableSortIconToggle } from '../../../../utility/tableSorting';

const SelectSong = ({ setCurrent }) => {
  const [songs, setSongs] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);

  const [rowSelect, setRowSelect] = useState([]);
  const [rowSelectRecord, setRowSelectRecord] = useState([]);

  const [isshowFilter, setIsShowFilter] = useState(false);

  const [songType, setSongType] = useState(null);

  const [artist, setArtist] = useState(null);

  const [album, setAlbum] = useState(null);

  const [downloadable, setDownloadable] = useState(null);

  const navigate = useNavigate();

  const fetchSongs = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    songType = null,
    isdownloadable = null,
    artistId = null,
    albumId = null
  ) => {
    setLoading(true);
    try {
      const res = await SONGS.GET_SONG(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        songType,
        isdownloadable,
        artistId,
        albumId
      );
      if (res.status === 1 || res.status === '1') {
        setSongs(res);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const loadOptionsOfSongType = async (search, prevOptions) => {
    const res = await SONGS.GET_CHOOSE_INTEREST();
    const option = res?.data.map((item) => {
      return { value: item?.id, label: item?.name };
    });
    return {
      options: option,
    };
  };

  const loadOptionsOfArtist = async (search, prevOptions, { skip }) => {
    const res = await SONGS.SEARCH_ARTIST(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const loadOptionsOfAlbum = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.ALL_ALBUMS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.albumName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSorting = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: rowSelect,
    onChange: (selectedRowKeys, selectRowRecord) => {
      setRowSelect(selectedRowKeys);
      setRowSelectRecord(selectRowRecord);
    },
  };

  const handleNext = async () => {
    if (rowSelect.length <= 0) {
      toast.warn('Select song is required !');
    } else {
      localStorage.setItem('add-album-1', JSON.stringify(rowSelect));
      localStorage.setItem('song-record', JSON.stringify(rowSelectRecord));
      setCurrent(1);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const value = JSON.parse(localStorage.getItem('add-album-1')) || [];
    const song = JSON.parse(localStorage.getItem('song-record')) || [];
    setRowSelectRecord(song);
    setRowSelect(value);
  }, []);

  useEffect(() => {
    fetchSongs(
      currentPage,
      pageSize,
      search,
      sort,
      songType?.label,
      downloadable,
      artist?.value,
      album?.value
    );
  }, [
    album?.value,
    artist?.value,
    currentPage,
    downloadable,
    pageSize,
    search,
    songType?.label,
    sort,
  ]);

  const columns = [
    {
      title: (column) => tableSortIconToggle('Song Title', 'songTitle', column),
      dataIndex: 'songTitle',
      key: 'songTitle',
      sortDirections: ['ascend', 'descend'],
      align: 'left',
      sorter: songs?.data?.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: (column) => tableSortIconToggle('CMS Path', 'cmsPath', column),
      dataIndex: 'cmsPath',
      key: 'cmsPath',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songs?.data?.length > 1 ? true : false,
      ellipsis: true,
    },
    {
      title: 'Mother Album',
      dataIndex: 'album',
      key: 'album',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      ellipsis: true,
      render: (album) => <span>{album?.albumName || '-'}</span>,
    },
    {
      title: (column) => tableSortIconToggle('Song Type', 'songType', column),
      dataIndex: 'songType',
      key: 'songType',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songs?.data?.length > 1 ? true : false,
      width: 150,
    },
    {
      title: (column) => tableSortIconToggle('Duration', 'duration', column),
      dataIndex: 'duration',
      key: 'duration',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songs?.data?.length > 1 ? true : false,
      width: 120,
    },
    {
      title: (column) =>
        tableSortIconToggle('Downloadable', 'isdownloadable', column),
      dataIndex: 'isdownloadable',
      key: 'isdownloadable',
      sortDirections: ['ascend', 'descend'],
      align: 'center',
      sorter: songs?.data?.length > 1 ? true : false,
      render: (_, record) => (
        <Checkbox disabled checked={record?.isdownloadable}></Checkbox>
      ),
      width: 170,
    },
    {
      title: 'Bits',
      align: 'center',
      width: 130,
      render: (_, record) => {
        return (
          <>
            <Row gutter={10}>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
                <Tooltip title='32 Bits File'>
                  <Checkbox
                    disabled
                    checked={record?.media_sound?.bitFile32}
                  ></Checkbox>
                </Tooltip>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
                <Tooltip title='64 Bits File'>
                  <Checkbox
                    disabled
                    checked={record?.media_sound?.bitFile64}
                  ></Checkbox>
                </Tooltip>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
                <Tooltip title='128 Bits File'>
                  <Checkbox
                    disabled
                    checked={record?.media_sound?.bitFile128}
                  ></Checkbox>
                </Tooltip>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className='gutter-row'>
                <Tooltip title='256 Bits File'>
                  <Checkbox
                    disabled
                    checked={record?.media_sound?.bitFile256}
                  ></Checkbox>
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row justify='center' gutter={0} className='mt-4'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <InsideHeader className='pt-2 pb-1 px-2'>
            <h3 className='mb-0 font-size-25'>
              Select Songs For Adding in Album(s)
            </h3>
            <Row gutter={0} className='align-items-center mt-1'>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={13}
                xl={13}
                className='gutter-row mb-1'
              >
                <Input
                  placeholder='Start Typing Search Song'
                  suffix={
                    <SearchOutlined
                      style={{ color: '#8f9bb3' }}
                      onClick={() => setSearch(tempSearch)}
                    />
                  }
                  onChange={(e) => {
                    if (!e.target.value) {
                      setCurrentPage(1);
                      setSearch(null);
                      setTempSearch(null);
                    } else {
                      setTempSearch(e.target.value);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e?.key === 'Enter' && e.target.value) {
                      setCurrentPage(1);
                      setSearch(tempSearch);
                    }
                  }}
                  className='input-control'
                  value={tempSearch}
                  allowClear
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={11}
                xl={11}
                className='gutter-row text-end text-xs-left mb-1'
              >
                <ButtonCom
                  type='primary'
                  onClick={() => setIsShowFilter(!isshowFilter)}
                >
                  <div className='d-flex align-items-center'>
                    Song Filters <FilterFilled className='ml-1' />
                  </div>
                </ButtonCom>
              </Col>
            </Row>
          </InsideHeader>
        </Col>
      </Row>
      {isshowFilter && (
        <InsideHeader className='pt-2 pb-1 mt-3'>
          <Row gutter={20} className='align-items-center'>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <SelectWithPagination
                placeholder='Select Song Type'
                loadOptions={loadOptionsOfSongType}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSongType(e);
                }}
                value={songType}
                isClearable
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <SelectWithPagination
                placeholder='Select Artist'
                loadOptions={loadOptionsOfArtist}
                onChange={(e) => {
                  setCurrentPage(1);
                  setArtist(e);
                }}
                additional={{
                  skip: 0,
                }}
                value={artist}
                isClearable
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <SelectWithPagination
                placeholder='Select Album'
                loadOptions={loadOptionsOfAlbum}
                onChange={(e) => {
                  setCurrentPage(1);
                  setAlbum(e);
                }}
                additional={{
                  skip: 0,
                }}
                value={album}
                isClearable
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='gutter-row mb-1'
            >
              <div className='d-flex'>
                <h2 className='font-size-16 mb-0 mr-1'>Downloadable</h2>
                <Checkbox
                  checked={downloadable}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setDownloadable(e.target.checked);
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} className='gutter-row'>
              <h3
                className='clear-all'
                onClick={() => {
                  setCurrentPage(1);
                  setPageSize(10);
                  setSongType(null);
                  setArtist(null);
                  setAlbum(null);
                  setDownloadable(null);
                }}
              >
                Clear All
              </h3>
            </Col>
          </Row>
        </InsideHeader>
      )}

      {/* Multiple Select */}
      {rowSelect.length > 0 && (
        <div className='mt-3'>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <h3 className='mb-0'>{rowSelect.length} - Selected Songs</h3>
            </div>
            <div>
              <ButtonCom
                type='primary'
                className='mr-1'
                onClick={() => {
                  setRowSelect([]);
                  setRowSelectRecord([]);
                  localStorage.removeItem('add-album-1');
                  localStorage.removeItem('song-record');
                }}
              >
                Deselect Selected Songs
              </ButtonCom>
              <ButtonCom type='primary' onClick={handleNext}>
                Select Albums
              </ButtonCom>
            </div>
          </div>
        </div>
      )}
      <Row justify='center' gutter={0}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <div className='fix-table box-shadow-3 border-radius-5 mt-3'>
            <Table
              rowSelection={{
                type: <Checkbox />,
                ...rowSelection,
              }}
              dataSource={songs.data}
              columns={columns}
              pagination={false}
              rowKey='id'
              loading={loading}
              onChange={handleSorting}
            />
            <div className='text-center bg-white p-2 width-1120 d-flex justify-content-between align-items-center'>
              <p className='mb-0'>{songs?.total || 0} - Total Songs</p>
              <ButtonCom className='width-180' onClick={handleBack}>
                Back
              </ButtonCom>
              {songs?.total > 10 && (
                <PaginationCom
                  onChange={onChangePage}
                  showSizeChanger={true}
                  current={currentPage}
                  showLessItems={true}
                  total={songs?.total}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SelectSong;
