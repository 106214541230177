import React from 'react';

import { AsyncPaginate } from 'react-select-async-paginate';

const SelectWithPagination = (props) => {
  return (
    <AsyncPaginate
      menuPlacement='auto'
      maxMenuHeight='200px'
      minMenuHeight='200px'
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#9F52F4',
          primary25: '#faf0ff',
          primary50: '#faf0ff',
        },
      })}
      {...props}
    />
  );
};

export default SelectWithPagination;
