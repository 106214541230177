import axios from 'axios';

const options = {
  headers: {
    accept: 'application/json',
    authorization:
      'Basic amFsc28tcmVhY3QuZDYzNWI5Lm1wLXNlcnZpY2UtYWNjb3VudDpGNmJ2T0xVSFBzZW1qQWNza3ZWUXpaMzVjejRhOXFlVQ==',
  },
};

export const platFormUserApi = (time) => {
  return axios.post(
    `https://mixpanel.com/api/2.0/insights?workspace_id=3298741&project_id=2763385`,
    {
      bookmark: {
        sections: {
          show: [
            {
              dataset: '$mixpanel',
              value: {
                name: '$ae_first_open',
                resourceType: 'events',
                renamed: 'iOS App',
              },
              resourceType: 'events',
              profileType: null,
              search: '',
              dataGroupId: null,
              filter: [
                {
                  dataset: '$mixpanel',
                  value: '$os',
                  resourceType: 'events',
                  profileType: null,
                  search: '',
                  dataGroupId: null,
                  filterType: 'string',
                  defaultType: 'string',
                  filterOperator: 'equals',
                  filterValue: ['iOS', 'iPadOS'],
                  propertyObjectKey: null,
                  determiner: 'all',
                },
              ],
              math: 'total',
              perUserAggregation: null,
              property: null,
              filterDeterminer: 'all',
              labelPrefix: 'A. ',
            },
            {
              dataset: '$mixpanel',
              value: {
                name: '$ae_first_open',
                resourceType: 'events',
                renamed: 'Android App',
              },
              resourceType: 'events',
              profileType: null,
              search: '',
              dataGroupId: null,
              filter: [
                {
                  dataset: '$mixpanel',
                  value: '$os',
                  resourceType: 'events',
                  profileType: null,
                  search: '',
                  dataGroupId: null,
                  filterType: 'string',
                  defaultType: 'string',
                  filterOperator: 'equals',
                  filterValue: ['Android'],
                  propertyObjectKey: null,
                  determiner: 'all',
                },
              ],
              math: 'total',
              perUserAggregation: null,
              property: null,
              filterDeterminer: 'all',
              labelPrefix: 'B. ',
            },
          ],
          cohorts: [],
          group: [],
          filter: [],
          formula: [],
          time,
        },
        columnWidths: { bar: {} },
        displayOptions: {
          chartType: 'line',
          plotStyle: 'standard',
          analysis: 'linear',
          value: 'absolute',
        },
        sorting: {
          bar: {
            sortBy: 'column',
            colSortAttrs: [{ sortBy: 'value', sortOrder: 'desc' }],
          },
          line: {
            sortBy: 'value',
            sortOrder: 'desc',
            valueField: 'averageValue',
            colSortAttrs: [],
          },
          table: {
            sortBy: 'column',
            colSortAttrs: [{ sortBy: 'label', sortOrder: 'asc' }],
          },
          'insights-metric': {
            sortBy: 'value',
            sortOrder: 'desc',
            valueField: 'totalValue',
            colSortAttrs: [],
          },
          pie: {
            sortBy: 'value',
            sortOrder: 'desc',
            valueField: 'totalValue',
            colSortAttrs: [],
          },
        },
      },
      dataset_name: '$mixpanel',
      use_query_cache: true,
      queryLimits: { limit: 3000 },
      tracking_props: {
        dashboard_id: 3559943,
        dashboard_query_origin: 'dashboard',
        is_main_query_for_report: true,
        bookmark_id: 33684228,
        queried_from_dashboards: true,
        report_name: 'insights',
      },
    },
    options
  );
};

export const monthlyActiveUserApi = (time) => {
  return axios.post(
    `https://mixpanel.com/api/2.0/insights?workspace_id=3298741&project_id=2763385`,
    {
      tracking_props: {
        is_main_query_for_report: true,
        report_name: 'insights',
        bookmark_id: 34158089,
        has_unsaved_changes: true,
        query_reason: 'qb_other_update',
      },
      bookmark: {
        sections: {
          show: [
            {
              dataset: '$mixpanel',
              value: { name: '$session_start', session_event: 'start' },
              resourceType: 'events',
              profileType: null,
              search: '',
              dataGroupId: null,
              filter: [
                {
                  dataset: '$mixpanel',
                  value: '$os',
                  resourceType: 'events',
                  profileType: null,
                  search: '',
                  dataGroupId: null,
                  filterType: 'string',
                  defaultType: 'string',
                  filterOperator: 'equals',
                  filterValue: ['iOS', 'iPadOS'],
                  propertyObjectKey: null,
                  determiner: 'all',
                },
              ],
              math: 'dau',
              perUserAggregation: null,
              property: null,
              filterDeterminer: 'all',
              labelPrefix: 'A. ',
            },
            {
              dataset: '$mixpanel',
              value: { name: '$session_start', session_event: 'start' },
              resourceType: 'events',
              profileType: null,
              search: '',
              dataGroupId: null,
              filter: [
                {
                  dataset: '$mixpanel',
                  value: '$android_os',
                  resourceType: 'people',
                  profileType: null,
                  search: '',
                  dataGroupId: null,
                  filterType: 'string',
                  defaultType: 'string',
                  filterOperator: 'equals',
                  filterValue: ['Android'],
                  propertyObjectKey: null,
                  determiner: 'all',
                },
              ],
              math: 'dau',
              perUserAggregation: null,
              property: null,
              filterDeterminer: 'all',
              labelPrefix: 'B. ',
            },
          ],
          cohorts: [],
          group: [],
          filter: [],
          formula: [],
          time,
        },
        columnWidths: { bar: {} },
        displayOptions: {
          chartType: 'line',
          plotStyle: 'standard',
          analysis: 'linear',
          value: 'absolute',
        },
        sorting: {
          bar: {
            sortBy: 'column',
            colSortAttrs: [{ sortBy: 'value', sortOrder: 'desc' }],
          },
          line: {
            sortBy: 'value',
            sortOrder: 'desc',
            valueField: 'averageValue',
            colSortAttrs: [],
          },
          table: {
            sortBy: 'column',
            colSortAttrs: [{ sortBy: 'label', sortOrder: 'asc' }],
          },
          'insights-metric': {
            sortBy: 'value',
            sortOrder: 'desc',
            valueField: 'totalValue',
            colSortAttrs: [],
          },
          pie: {
            sortBy: 'value',
            sortOrder: 'desc',
            valueField: 'totalValue',
            colSortAttrs: [],
          },
        },
      },
      queryLimits: { limit: 3000 },
      use_query_cache: true,
      use_query_sampling: false,
    },
    options
  );
};
