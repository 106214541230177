import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'antd';

import SelectWithPagination from '../../../../components/SelectWithPagination';
import ButtonCom from '../../../../components/Button/ButtonCom';
import { ALBUMS } from '../../../../service';

const SelectedAlbum = ({ setCurrent }) => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const loadOptionsOfAlbum = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.ALL_ALBUMS(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        ...item,
        value: item?.id,
        label: item?.albumName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const handleBack = () => {
    localStorage.removeItem('step1');
    localStorage.removeItem('step2');
    localStorage.removeItem('file');
    navigate('/songs');
  };

  const onFinish = (value) => {
    localStorage.setItem('step1', JSON.stringify(value?.albumName));
    setCurrent(1);
  };

  useEffect(() => {
    const value = JSON.parse(localStorage.getItem('step1')) || '';
    form.setFieldsValue({ albumName: value });
  }, [form]);

  return (
    <>
      <Form
        name='basic'
        form={form}
        onFinish={onFinish}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
          md: { span: 8 },
          lg: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
          md: { span: 16 },
          lg: { span: 16 },
        }}
        className='form-style'
      >
        <Row justify='center' gutter={25} className='mt-2'>
          <Col
            xs={24}
            sm={24}
            md={20}
            lg={16}
            xl={16}
            className=' mt-3 gutter-row'
          >
            <div className='bg-white box-shadow-3 border-radius-5 p-3 '>
              <h3 className='mb-0 font-size-25'>Select album</h3>

              <Form.Item
                name='albumName'
                label='Album Name'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Album name is required !',
                  },
                ]}
              >
                <SelectWithPagination
                  placeholder='Select Album'
                  loadOptions={loadOptionsOfAlbum}
                  additional={{
                    skip: 0,
                  }}
                  isClearable
                />
              </Form.Item>
              <div className='mt-4 text-center'>
                <ButtonCom className='mr-2 width-180' onClick={handleBack}>
                  Back
                </ButtonCom>
                <ButtonCom
                  type='primary'
                  className='width-180'
                  htmlType='submit'
                >
                  Add Songs
                </ButtonCom>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default SelectedAlbum;
