import React, { useState } from 'react';

import AddSong from '../../../components/AddSong';
import ReviewData from '../../../components/ReviewData';

import CreateAlbum from './Steps/CreateAlbum';
import Publish from './Steps/AlbumPublish';

import { Row, Col, Steps } from 'antd';
import Category from './Steps/Category';

const { Step } = Steps;

const NewAlbum = () => {
  const [current, setCurrent] = useState(0);

  return (
    <>
      {/* Step */}
      <Row justify='center' gutter={25} className='mt-2'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
          <Steps current={current}>
            <Step title='Create New Album' />
            <Step title='Category' />
            <Step title='Add Songs' />
            <Step title='Review Data' />
            <Step title='Publish' />
          </Steps>
        </Col>
      </Row>

      {/* Create Album */}
      {current === 0 && (
        <CreateAlbum setCurrent={setCurrent} scope='add-album' />
      )}

      {current === 1 && <Category setCurrent={setCurrent} />}

      {/* Add songs */}
      {current === 2 && <AddSong setCurrent={setCurrent} scope='album' />}

      {/* Review Data */}
      {current === 3 && <ReviewData setCurrent={setCurrent} scope='album' />}

      {/* Publish  */}
      {current === 4 && <Publish setCurrent={setCurrent} scope='add-album' />}
    </>
  );
};

export default NewAlbum;
