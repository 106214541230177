import React from 'react';

import Routes from './router/router';
import Toast from './components/Toast';

const App = () => {
  return (
    <>
      <Routes />
      <Toast />
    </>
  );
};

export default App;
