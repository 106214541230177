import React from 'react';

const Song = () => {
  return (
    <>
      <span className='anticon anticon-dashboard ant-menu-item-icon'>
        <svg
          width='20px'
          height='20px'
          viewBox='0 0 24 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M9 18.5615V5.56152L21 3.56152V16.5615'
            stroke='#656565'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M6 21.5615C7.65685 21.5615 9 20.2184 9 18.5615C9 16.9047 7.65685 15.5615 6 15.5615C4.34315 15.5615 3 16.9047 3 18.5615C3 20.2184 4.34315 21.5615 6 21.5615Z'
            stroke='#656565'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>{' '}
          <path
            d='M18 19.5615C19.6569 19.5615 21 18.2184 21 16.5615C21 14.9047 19.6569 13.5615 18 13.5615C16.3431 13.5615 15 14.9047 15 16.5615C15 18.2184 16.3431 19.5615 18 19.5615Z'
            stroke='#656565'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
        </svg>
      </span>
    </>
  );
};

export default Song;
