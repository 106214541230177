import { methods, serviceMaker } from '../service';

const GET_APP_USERS = (
  limit,
  skip,
  search = null,
  sort = null,
  gender = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  if (search) {
    query['params']['search'] = search;
  }
  if (gender) {
    query['params']['gender'] = gender;
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  return serviceMaker(`appusers`, methods.GET, query);
};

const DELETE_APP_USERS = (id) => serviceMaker(`appusers/${id}`, methods.DELETE);

const BLOCK_APP_USERS = (id, payload) =>
  serviceMaker(`appusers/${id}`, methods.PATCH, null, payload);

const APP_USERS = {
  GET: GET_APP_USERS,
  DELETE: DELETE_APP_USERS,
  BLOCK: BLOCK_APP_USERS,
};
export { APP_USERS };
