import React, { useEffect, useState } from 'react';

import { Col, Form, InputNumber, Radio, Row } from 'antd';

import ButtonCom from '../../../../components/Button/ButtonCom';
import InsideHeader from '../../../../components/InsideHeader/InsideHeader';
import SelectWithPagination from '../../../../components/SelectWithPagination';

import { ALBUMS, ALBUM_CATEGORY } from '../../../../service';

const Category = ({ setCurrent }) => {
  const [category, setCategory] = useState(null);
  const [form] = Form.useForm();

  const loadOptionsOfCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUM_CATEGORY.GET_CATEGORY(10, skip, search);
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.categoryName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const loadOptionsOfSubCategory = async (search, prevOptions, { skip }) => {
    const res = await ALBUMS.GET_SUB_CATEGORY_OF_ALBUMS(
      category?.value,
      10,
      skip,
      search
    );
    const option = res?.data.map((item) => {
      return {
        value: item?.id,
        label: item?.subCategoryName,
      };
    });
    return {
      options: option,
      hasMore: skip < res?.total,
      additional: {
        skip: skip + 10,
      },
    };
  };

  const onFinish = (value) => {
    const payload = {
      ...value,
      CategoryPositionNo: value?.CategoryPositionNo || 0,
      SubCategoryPositionNo: value?.SubCategoryPositionNo || 0,
    };
    localStorage.setItem('category', JSON.stringify(payload));
    setCurrent(2);
  };

  useEffect(() => {
    const stepOne = JSON.parse(localStorage.getItem('step1'));
    const category = JSON.parse(localStorage.getItem('category')) || undefined;
    if (category) {
      form.setFieldsValue(category);
    } else {
      form.setFieldsValue({
        radio: 1,
        albumId: {
          label: stepOne?.albumName,
          value: stepOne?.albumName,
        },
      });
    }
  }, [form]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 10 },
          md: { span: 10 },
          lg: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 14 },
          md: { span: 14 },
          lg: { span: 16 },
        }}
        labelAlign='left'
        name='control-hooks'
        className='form-style'
      >
        <Row justify='center' gutter={0} className='mt-4'>
          <Col xs={24} sm={24} md={20} lg={15} xl={15} className='gutter-row'>
            <InsideHeader className='p-3'>
              <h3 className='font-size-25 mb-2'>Category</h3>
              <Form.Item name='albumId' label='Album'>
                <SelectWithPagination
                  placeholder='Album'
                  additional={{
                    skip: 0,
                  }}
                  isDisabled
                  isClearable
                />
              </Form.Item>

              <h3 className='font-size-20 mb-2'>Album Category</h3>

              <Form.Item name='categoryId' label='Select Category'>
                <SelectWithPagination
                  placeholder='Select Category'
                  loadOptions={loadOptionsOfCategory}
                  additional={{
                    skip: 0,
                  }}
                  isClearable
                />
              </Form.Item>
              <Form.Item
                name='CategoryPositionNo'
                label='Position No.'
                labelAlign='left'
                extra={'Leave blank for auto increment.'}
              >
                <InputNumber
                  min={0}
                  placeholder='Position No.'
                  className='input-control-number'
                />
              </Form.Item>

              <h3 className='font-size-20 mb-2'>Album Sub-Category</h3>

              <Form.Item name='subCategoryCategoryId' label='Select Category'>
                <SelectWithPagination
                  placeholder='Select Category'
                  loadOptions={loadOptionsOfCategory}
                  additional={{
                    skip: 0,
                  }}
                  onChange={(e) => setCategory(e)}
                  isClearable
                />
              </Form.Item>

              <Form.Item name='subcategoryId' label='Select Sub-Category'>
                <SelectWithPagination
                  key={category?.value}
                  placeholder='Select Sub-Category'
                  loadOptions={loadOptionsOfSubCategory}
                  additional={{
                    skip: 0,
                  }}
                  isClearable
                />
              </Form.Item>
              <Form.Item
                name='SubCategoryPositionNo'
                label='Position No.'
                labelAlign='left'
                extra={'Leave blank for auto increment.'}
              >
                <InputNumber
                  min={0}
                  placeholder='Position No.'
                  className='input-control-number'
                />
              </Form.Item>
              <div className='text-center mt-3'>
                <ButtonCom
                  className='width-180 mr-2'
                  onClick={() => setCurrent(0)}
                >
                  Back
                </ButtonCom>
                <ButtonCom
                  type='primary'
                  htmlType='submit'
                  className='width-180'
                >
                  Add Song
                </ButtonCom>
              </div>
            </InsideHeader>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Category;
