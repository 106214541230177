import React, { useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment/moment';

import ThreeBox from '../../components/Dashboard/ThreeBox';
import Favorites from '../../components/Dashboard/Favorites';
import TrendingMonthItem from '../../components/Dashboard/TrendingMonthItem';
import TotalMedia from '../../components/Dashboard/TotalMedia';
import TrendingSearch from '../../components/Dashboard/TrendingSearch';
import TotalUser from '../../components/Dashboard/TotalUser';
import UserDownload from '../../components/Dashboard/UserDownload';
import SelectDropDown from '../../components/Select';
import UserPlayLists from '../../components/Dashboard/UserPlayLists';
import { DASHBOARD } from '../../service';
import {
  monthlyActiveUserApi,
  platFormUserApi,
} from '../../service/MixPanel/MixPanel';

const Dashboard = () => {
  const [dashboard, setDashboard] = useState({});
  const [media, setMedia] = useState([]);
  const [totalMedia, setTotalMedia] = useState(0);
  const [filter, setFilter] = useState({ label: 'Life Time', value: null });
  const [totalUser, setTotalUser] = useState([]);
  const [totalPlatformUser, setTotalPlatformUser] = useState(0);
  const [userDownloads, setUserDownloads] = useState([]);
  const [monthlyActiveUser, setMonthlyActiveUser] = useState([]);

  const optionsForMedia = {
    pieHole: 0.55,
    is3D: false,
    legend: { position: 'bottom' },
    pieSliceText: 'value',
    slices: {
      0: { color: '#72C1E2' },
      1: { color: '#FFA41D' },
      2: { color: '#DB4CB2' },
      3: { color: '#FF5B26' },
      4: { color: '#9F52F4' },
    },
  };

  const optionsForTotalUser = {
    pieHole: 0.55,
    is3D: false,
    legend: { position: 'bottom' },
    pieSliceText: 'value',
    slices: {
      0: { color: '#9F52F4' },
      1: { color: '#FFA41D' },
    },
  };

  const optionForUserDownload = {
    legend: { position: 'left' },
    intervals: { style: 'bars' },
    series: {
      0: { color: '#9F52F4' },
      1: { color: '#FF5B26' },
    },
  };

  const dropdownOption = [
    {
      label: 'Life Time',
      value: null,
    },
    {
      label: 'Today',
      value: 'today',
    },
    {
      label: 'Yesterday',
      value: 'yesterday',
    },
    {
      label: 'This Week',
      value: 'this_week',
    },
    {
      label: 'Previous Week',
      value: 'previous_week',
    },
    {
      label: 'This Month',
      value: 'this_month',
    },
    {
      label: 'Previous Month',
      value: 'previous_month',
    },
    {
      label: 'This Year',
      value: 'this_year',
    },
    {
      label: 'Previous Year',
      value: 'previous_year',
    },
  ];

  const handleChangeOfFilter = (e) => {
    setDashboard([]);
    setMedia([]);
    setTotalMedia(0);
    setFilter(e);
  };

  const increasePercentage = (previous, current) => {
    if (previous === '0' && current === '0') {
      return 0;
    }
    if (previous || current) {
      const increase = Number(current) - Number(previous);
      const increasePercentage = (Number(increase) / Number(current)) * 100;
      return Math.round(increasePercentage);
    }
    return 0;
  };

  // dashboard data from backend
  useEffect(() => {
    const fetch = async (search = null) => {
      try {
        setDashboard({});
        const res = await DASHBOARD.GET_DASHBOARD(search);
        if (res?.status === 1 || res?.status === '1') {
          setTotalUser([
            ['Android', 'IOS'],
            ['Android', Number(res?.data?.platform_user?.android?.android)],
            ['IOS', Number(res?.data?.platform_user?.ios?.ios)],
          ]);
          setTotalPlatformUser(
            Number(res?.data?.platform_user?.android?.android) +
              Number(res?.data?.platform_user?.ios?.ios)
          );
          setDashboard({
            ...res?.data,
          });

          let tempTotalMedia = 0;
          const map = res?.data?.media?.map((item) => {
            const tempArr = [];
            if (item?.album_total) {
              tempArr.push(['Album', +item?.album_total]);
              tempTotalMedia += +item?.album_total;
            }
            if (item?.artist_total) {
              tempArr.push(['Artist', +item?.artist_total]);
              tempTotalMedia += +item?.artist_total;
            }
            if (item?.podcast_total) {
              tempArr.push(['Podcast', +item?.podcast_total]);
              tempTotalMedia += +item?.podcast_total;
            }
            if (item?.song_total) {
              tempArr.push(['Song', +item?.song_total]);
              tempTotalMedia += +item?.song_total;
            }
            if (item?.video_total) {
              tempArr.push(['Video', +item?.video_total]);
              tempTotalMedia += +item?.video_total;
            }
            return tempArr;
          })[0];
          const tempMedia = [['Media', 'Category'], ...map];
          setMedia(tempMedia);
          setTotalMedia(tempTotalMedia);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong!');
      }
    };

    fetch(filter?.value);
  }, [filter?.value]);

  // dashboard data from mixpanel for user downloads
  useEffect(() => {
    const labelBaseOnDate = (date) => {
      if (filter.label === 'Life Time') {
        return moment(date).format('YYYY');
      }
      if (filter.label === 'Today' || filter.label === 'Yesterday') {
        return moment(date).format('MMM DD,hha');
      }
      if (filter.label === 'This Week' || filter.label === 'Previous Week') {
        return moment(date).format('MMM DD');
      }
      if (filter.label === 'This Month' || filter.label === 'Previous Month') {
        return moment(date).format('MMM DD');
      }
      if (filter.label === 'This Year' || filter.label === 'Previous Year') {
        return moment(date).format('MMM YYYY');
      }
    };

    const userDownloadFetch = async () => {
      try {
        let res;
        setUserDownloads([]);
        if (filter.label === 'Life Time') {
          res = await platFormUserApi([
            {
              dateRangeType: 'between',
              unit: 'quarter',
              value: ['2020-01-01', moment().format('YYYY-MM-DD')],
            },
          ]);
        }
        if (filter.label === 'Today') {
          res = await platFormUserApi([
            {
              dateRangeType: 'since',
              unit: 'hour',
              value: '$start_of_current_day',
            },
          ]);
        }
        if (filter.label === 'Yesterday') {
          res = await platFormUserApi([
            {
              dateRangeType: 'between',
              unit: 'hour',
              value: ['$start_of_previous_day', '$end_of_previous_day'],
            },
          ]);
        }
        if (filter.label === 'This Week') {
          res = await platFormUserApi([
            {
              dateRangeType: 'between',
              unit: 'day',
              value: [
                moment().startOf('week').format('YYYY-MM-DD'),
                moment().endOf('week').format('YYYY-MM-DD'),
              ],
            },
          ]);
        }
        if (filter.label === 'Previous Week') {
          res = await platFormUserApi([
            {
              dateRangeType: 'between',
              unit: 'day',
              value: [
                moment()
                  .subtract(1, 'week')
                  .startOf('week')
                  .format('YYYY-MM-DD'),
                moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
              ],
            },
          ]);
        }
        if (filter.label === 'This Month') {
          res = await platFormUserApi([
            {
              dateRangeType: 'between',
              unit: 'day',
              value: [
                moment().startOf('months').format('YYYY-MM-DD'),
                moment().endOf('months').format('YYYY-MM-DD'),
              ],
            },
          ]);
        }
        if (filter.label === 'Previous Month') {
          res = await platFormUserApi([
            {
              dateRangeType: 'between',
              unit: 'day',
              value: [
                moment()
                  .subtract(1, 'months')
                  .startOf('months')
                  .format('YYYY-MM-DD'),
                moment()
                  .subtract(1, 'months')
                  .endOf('months')
                  .format('YYYY-MM-DD'),
              ],
            },
          ]);
        }
        if (filter.label === 'This Year') {
          res = await platFormUserApi([
            {
              dateRangeType: 'between',
              unit: 'month',
              value: [
                moment().startOf('year').format('YYYY-MM-DD'),
                moment().endOf('year').format('YYYY-MM-DD'),
              ],
            },
          ]);
        }
        if (filter.label === 'Previous Year') {
          res = await platFormUserApi([
            {
              dateRangeType: 'between',
              unit: 'month',
              value: [
                moment()
                  .subtract(1, 'year')
                  .startOf('year')
                  .format('YYYY-MM-DD'),
                moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
              ],
            },
          ]);
        }
        if (res?.data?.series['Android App'] || res?.data?.series['iOS App']) {
          const tempData = [[filter.label, 'Android', 'IOS']];
          if (filter.label === 'Life Time') {
            let android = {};
            for (const key in res?.data?.series['Android App']) {
              const year = moment(key).format('YYYY');
              if (!android[year]) {
                android[year] = res?.data?.series['Android App'][key];
              } else {
                android[year] += res?.data?.series['Android App'][key];
              }
            }

            let ios = {};
            for (const key in res?.data?.series['iOS App']) {
              const year = moment(key).format('YYYY');
              if (!ios[year]) {
                ios[year] = res?.data?.series['iOS App'][key];
              } else {
                ios[year] += res?.data?.series['iOS App'][key];
              }
            }

            for (const key in android) {
              tempData.push([key, android[key], ios[key]]);
            }
          } else {
            for (const key in res?.data?.series['Android App']) {
              tempData.push([
                labelBaseOnDate(key),
                res?.data?.series['Android App'][key],
                res?.data?.series['iOS App'][key],
              ]);
            }
          }

          setUserDownloads(tempData);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong!');
      }
    };

    userDownloadFetch();
  }, [filter.label]);

  // dashboard data from mixpanel for monthly active user
  useEffect(() => {
    const labelBaseOnDate = (date) => {
      if (filter.label === 'Life Time') {
        return moment(date).format('YYYY');
      }
      if (filter.label === 'Today' || filter.label === 'Yesterday') {
        return moment(date).format('MMM DD,hha');
      }
      if (filter.label === 'This Week' || filter.label === 'Previous Week') {
        return moment(date).format('MMM DD');
      }
      if (filter.label === 'This Month' || filter.label === 'Previous Month') {
        return moment(date).format('MMM DD');
      }
      if (filter.label === 'This Year' || filter.label === 'Previous Year') {
        return moment(date).format('MMM YYYY');
      }
    };

    const fetch = async () => {
      let res;
      if (filter.label === 'Life Time') {
        res = await monthlyActiveUserApi([
          {
            dateRangeType: 'between',
            unit: 'quarter',
            value: ['2020-01-01', moment().format('YYYY-MM-DD')],
          },
        ]);
      }
      if (filter.label === 'Today') {
        res = await monthlyActiveUserApi([
          {
            dateRangeType: 'since',
            unit: 'hour',
            value: '$start_of_current_day',
          },
        ]);
      }
      if (filter.label === 'Yesterday') {
        res = await monthlyActiveUserApi([
          {
            dateRangeType: 'between',
            unit: 'hour',
            value: ['$start_of_previous_day', '$end_of_previous_day'],
          },
        ]);
      }
      if (filter.label === 'This Week') {
        res = await monthlyActiveUserApi([
          {
            dateRangeType: 'between',
            unit: 'day',
            value: [
              moment().startOf('week').format('YYYY-MM-DD'),
              moment().endOf('week').format('YYYY-MM-DD'),
            ],
          },
        ]);
      }
      if (filter.label === 'Previous Week') {
        res = await monthlyActiveUserApi([
          {
            dateRangeType: 'between',
            unit: 'day',
            value: [
              moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
              moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
            ],
          },
        ]);
      }
      if (filter.label === 'This Month') {
        res = await monthlyActiveUserApi([
          {
            dateRangeType: 'between',
            unit: 'day',
            value: [
              moment().startOf('months').format('YYYY-MM-DD'),
              moment().endOf('months').format('YYYY-MM-DD'),
            ],
          },
        ]);
      }
      if (filter.label === 'Previous Month') {
        res = await monthlyActiveUserApi([
          {
            dateRangeType: 'between',
            unit: 'day',
            value: [
              moment()
                .subtract(1, 'months')
                .startOf('months')
                .format('YYYY-MM-DD'),
              moment()
                .subtract(1, 'months')
                .endOf('months')
                .format('YYYY-MM-DD'),
            ],
          },
        ]);
      }
      if (filter.label === 'This Year') {
        res = await monthlyActiveUserApi([
          {
            dateRangeType: 'between',
            unit: 'month',
            value: [
              moment().startOf('year').format('YYYY-MM-DD'),
              moment().endOf('year').format('YYYY-MM-DD'),
            ],
          },
        ]);
      }
      if (filter.label === 'Previous Year') {
        res = await monthlyActiveUserApi([
          {
            dateRangeType: 'between',
            unit: 'month',
            value: [
              moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
              moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
            ],
          },
        ]);
      }
      if (
        res?.data?.series['A. Session Start - DAU'] ||
        res?.data?.series['B. Session Start - DAU']
      ) {
        const tempData = [[filter.label, 'Android', 'IOS']];
        if (filter.label === 'Life Time') {
          let android = {};
          for (const key in res?.data?.series['A. Session Start - DAU']) {
            const year = moment(key).format('YYYY');
            if (!android[year]) {
              android[year] = res?.data?.series['A. Session Start - DAU'][key];
            } else {
              android[year] += res?.data?.series['A. Session Start - DAU'][key];
            }
          }

          let ios = {};
          for (const key in res?.data?.series['B. Session Start - DAU']) {
            const year = moment(key).format('YYYY');
            if (!ios[year]) {
              ios[year] = res?.data?.series['B. Session Start - DAU'][key];
            } else {
              ios[year] += res?.data?.series['B. Session Start - DAU'][key];
            }
          }

          for (const key in android) {
            tempData.push([key, android[key], ios[key]]);
          }
        } else {
          for (const key in res?.data?.series['A. Session Start - DAU']) {
            tempData.push([
              labelBaseOnDate(key),
              res?.data?.series['A. Session Start - DAU'][key],
              res?.data?.series['B. Session Start - DAU'][key],
            ]);
          }
        }

        setMonthlyActiveUser(tempData);
      }
    };
    fetch();
  }, [filter.label]);

  return (
    <>
      {/* Header */}
      <Row>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <SelectDropDown
            options={dropdownOption}
            onChange={handleChangeOfFilter}
            value={filter}
          />
        </Col>
      </Row>

      {/* Total media & Trending Searches  */}
      <Row justify='space-between' gutter={25}>
        <TotalMedia
          totalMedia={totalMedia}
          media={media}
          optionsForMedia={optionsForMedia}
        />
        <TrendingSearch trendingSearch={dashboard?.trending_search} />
      </Row>

      {/* Total Users & Users Download  */}
      <Row justify='space-between' gutter={25}>
        <TotalUser
          totalUser={totalUser}
          totalPlatformUser={totalPlatformUser}
          optionsForTotalUser={optionsForTotalUser}
        />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
          className=' mt-3 gutter-row'
        >
          <UserDownload
            title='Users Download'
            data={userDownloads}
            optionForUserDownload={optionForUserDownload}
          />
        </Col>
      </Row>

      {/* This Month */}
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className='bg-white mt-3 p-2 box-shadow-3 border-radius-10'
        >
          <h2 className='font-weight-700 mb-0'>{filter?.label}</h2>
          <Row justify='space-between' gutter={25}>
            <TrendingMonthItem
              name='Trending Tracks'
              data={dashboard?.trendings?.songs}
            />
            <TrendingMonthItem
              name='Trending Albums'
              data={dashboard?.trendings?.albums}
            />
            <TrendingMonthItem
              name='Trending Artists'
              data={dashboard?.trendings?.artists}
            />
          </Row>
        </Col>
      </Row>

      <Row justify='space-between' gutter={25}>
        {/* Ads Shown   */}
        <ThreeBox
          title='Ads Shown'
          boxOneTitle='Total Ads'
          boxOneNum={dashboard?.ads_shown?.total?.count}
          boxOnePer={increasePercentage(
            dashboard?.ads_shown?.previousTotal?.count,
            dashboard?.ads_shown?.total?.count
          )}
          boxTwoTitle='Android'
          boxTwoNum={dashboard?.ads_shown?.android?.count}
          boxTwoPer={increasePercentage(
            dashboard?.ads_shown?.previousAndroid?.count,
            dashboard?.ads_shown?.android?.count
          )}
          boxThreeTitle='iOS'
          boxThreeNum={dashboard?.ads_shown?.ios?.count}
          boxThreePer={increasePercentage(
            dashboard?.ads_shown?.previousIos?.count,
            dashboard?.ads_shown?.ios?.count
          )}
        />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
          className=' mt-3 gutter-row'
        >
          {/* Top 10 Favorite Tracks */}
          <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
            <Favorites
              title='Top 10 Favorite Tracks'
              nameTitle='Name'
              countTitle='Favorite Count'
              progress={dashboard?.most_favorites?.songs}
            />
          </div>
        </Col>
      </Row>

      <Row justify='space-between' gutter={25}>
        {/* Ads Clicks   */}
        <ThreeBox
          title='Ads Clicks'
          boxOneTitle='Total Ads'
          boxOneNum={dashboard?.ads_click?.total?.count}
          boxOnePer={increasePercentage(
            dashboard?.ads_click?.previousTotal?.count,
            dashboard?.ads_click?.total?.count
          )}
          boxTwoTitle='Android'
          boxTwoNum={dashboard?.ads_click?.android?.count}
          boxTwoPer={increasePercentage(
            dashboard?.ads_click?.previousAndroid?.count,
            dashboard?.ads_click?.android?.count
          )}
          boxThreeTitle='iOS'
          boxThreeNum={dashboard?.ads_click?.ios?.count}
          boxThreePer={increasePercentage(
            dashboard?.ads_click?.previousIos?.count,
            dashboard?.ads_click?.ios?.count
          )}
        />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
          className=' mt-3 gutter-row'
        >
          {/*  Top 10 Favorite Albums */}
          <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
            <Favorites
              title='Top 10 Favorite Albums'
              nameTitle='Name'
              countTitle='Favorite Count'
              progress={dashboard?.most_favorites?.albums}
            />
          </div>
        </Col>
      </Row>

      <Row justify='space-between' gutter={25}>
        {/* Top Ads */}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={10}
          xl={10}
          className=' mt-3 gutter-row'
        >
          <Row justify='space-between' gutter={25}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='gutter-row'>
              <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
                <h2 className='font-weight-700 mb-0'>Top Ads</h2>
                <ul className='pl-2 mt-2'>
                  {dashboard?.top_ads?.map((item) => {
                    return (
                      <li
                        key={item.id}
                        className='list-style-auto font-weight-500 mb-1'
                      >
                        {item?.title}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>

            {/* User Playlists */}
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='mt-3 gutter-row'
            >
              <UserPlayLists dashboard={dashboard} />
            </Col>
          </Row>
        </Col>

        {/* Top 10 Favorite Artist */}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
          className=' mt-3 gutter-row'
        >
          <div className='bg-white box-shadow-3 border-radius-10 p-2 '>
            <Favorites
              title='Top 10 Favorite Artist'
              nameTitle='Name'
              countTitle='Favorite Count'
              progress={dashboard?.most_favorites?.artists}
            />
          </div>
        </Col>
      </Row>

      {/* Total Time Consumed & Monthly Active Users */}
      <Row justify='space-between' gutter={25}>
        <ThreeBox
          title='Total Time Consumed'
          boxOneTitle='Jalso Wide Total Consumed'
          boxOneNum='3.4k'
          boxOnePer='23.5'
          boxTwoTitle='Per Artist'
          boxTwoNum='3.4k'
          boxTwoPer='23.5'
          boxThreeTitle='Per Users'
          boxThreeNum='3.4k'
          boxThreePer='23.5'
        />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
          className=' mt-3 gutter-row'
        >
          <UserDownload
            title={`${filter.label} Active Users`}
            data={monthlyActiveUser}
            optionForUserDownload={optionForUserDownload}
          />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
