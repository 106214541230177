import { Modal, Upload } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';

import { useState } from 'react';
import { toast } from 'react-toastify';

import { BASE_URL } from '../../../utility/constant';
import MusicIcon from '../../../assets/image/music.svg';

const ResetFileModel = ({ isReset, setIsReset, onOk }) => {
  const [uploadFile, setUploadFile] = useState([]);
  const [fileData, setFileData] = useState({});

  const file = {
    name: 'file',
    multiple: true,
    fileList: uploadFile,
    showUploadList: {
      showRemoveIcon: false,
    },
    // action: `${BASE_URL}/uploads-audio`,
    beforeUpload(file) {
      const audio = file.type === 'audio/mpeg';
      if (!audio) {
        toast.error('Only audio files are allowed!');
      }
      return audio || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (info.fileList.length === 1) {
        setUploadFile([...info.fileList]);
      }
      if (status === 'done') {
        // setFileData(info?.file?.response?.data?.media);
      } else if (status === 'error') {
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <Modal
      centered='true'
      okText='Yes'
      cancelText='No'
      title='Reset File'
      visible={isReset}
      onOk={() => onOk(uploadFile)}
      onCancel={() => setIsReset(false)}
      width='1000px'
    >
      <Dragger accept='.mp4, .mp3' {...file}>
        <p className='ant-upload-drag-icon'></p>
        <div className='d-flex align-items-center justify-content-center flex-wrap p-2'>
          <div>
            <img src={MusicIcon} alt='musicIcon' />
          </div>
          <div>
            <p className='ant-upload-text pl-1'>
              Drag And Drop Or Select Songs (256bits)
            </p>
          </div>
        </div>
      </Dragger>
    </Modal>
  );
};

export default ResetFileModel;
